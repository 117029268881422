import { stores } from '@kurtosys/app-start';
import { requests } from '@kurtosys/ksys-api-client';
import { makeObservable } from 'mobx';

import { TStoreContext } from '../../../models/app/TStoreContext';
import { IComponentStyles, IConfiguration } from '../../../models/index.js';

type TGetApplicationAppConfig = requests.root.GetApplicationAppConfig;
import { IManifest } from '@kurtosys/types/appsManager/index.js';

const KurtosysApiStoreBase = stores.KurtosysApiStore<IConfiguration, IComponentStyles>;
const GetApplicationAppConfig = requests.root.GetApplicationAppConfig;
export class KurtosysApiStore extends KurtosysApiStoreBase {
	constructor(storeContext: TStoreContext, manifest: IManifest) {
		super(storeContext, manifest);
		makeObservable(this);
	}

	// This is to override the get app config request to include the ksys country header as part of the request
	// Can not add computed here as it is already on the base class
	get getAppConfig(): TGetApplicationAppConfig {
		return this.createEndpoint(GetApplicationAppConfig, { optionsOverride: { fetchUserCountry: true } });
	}
}
