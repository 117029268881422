import { stores } from '@kurtosys/app-start';
import { IManifest } from '@kurtosys/types/appsManager/index.js';
import { action, computed, makeObservable } from 'mobx';

import { IComponentStyles } from '../../../models/app/IComponentStyles.js';
import { IConfiguration } from '../../../models/app/IConfiguration.js';
import { TStoreContext } from '../../../models/app/TStoreContext.js';
import { AcceptanceStore } from '../../Acceptance/stores/AcceptanceStore.js';
import { AppStore } from '../../App/stores/AppStore.js';
import { ICallToActionConfiguration } from '../models';
import { TCallToActionOrder } from '../models/TCallToActionOrder.js';

export class CallToActionStore extends stores.base.StoreBase<IConfiguration, IComponentStyles> {
	static componentKey = 'callToAction' as const;

	constructor(storeContext: TStoreContext, manifest: IManifest) {
		super(storeContext, manifest);
		makeObservable(this);
	}

	@computed
	get appStore(): AppStore {
		return this.storeContext.get<AppStore>('appStore');
	}

	@computed
	get acceptanceStore(): AcceptanceStore {
		return this.storeContext.get<AcceptanceStore>('acceptanceStore');
	}

	@action
	async initialize(): Promise<void> {
		return;
	}

	@computed
	get configuration(): ICallToActionConfiguration | undefined {
		if (this.appStore) {
			return this.appStore.getComponentConfiguration(CallToActionStore.componentKey);
		}
	}
	@computed
	get disabled(): boolean {
		if (this.acceptanceStore) {
			const { accepted, mode } = this.acceptanceStore;
			return mode !== 'IMPLICIT' && mode !== 'STORAGE' && !accepted;
		}
		return true;
	}
	@computed
	get text(): string {
		return (this.configuration && this.configuration.text) || 'Accept';
	}

	@computed
	get rejectText(): string | undefined {
		return this.configuration && this.configuration.rejectText;
	}

	@computed
	get order(): TCallToActionOrder {
		return (this.configuration && this.configuration.order) || 'acceptThenReject';
	}

	@computed
	get hint(): string | undefined {
		return this.configuration && this.configuration.hint;
	}

	@action
	handleClick = () => {
		if (this.appStore) {
			this.appStore.setAttestationAndRedirect();
		}
	};

	@action
	handleRejectClick = () => {
		if (this.appStore) {
			this.appStore.rejectAndRedirect();
		}
	};
}
