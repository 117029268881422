import React, { Component } from 'react';
import { inject } from 'mobx-react';

import { InjectedStyledComponent } from '../shared/InjectedStyledComponent.js';

import FocusTrap from './components/FocusTrap/FocusTrap.js';
import { IModalProps, IModalState } from './models';

const Backdrop = InjectedStyledComponent('div', 'modal');
const Dialog = InjectedStyledComponent('div', 'modal', ['dialog']);

class RawModal extends Component<IModalProps, IModalState> {
	static configurationKey = 'modal' as const;
	static styleKey = 'modal' as const;
	constructor(props: IModalProps) {
		super(props);
	}
	render() {
		const { children, open, className } = this.props;

		if (open) {
			return (
				<Backdrop className={className}>
					<FocusTrap open={open}>
						<Dialog role="dialog" aria-modal="true" aria-labelledby="ksys-attestation-title">
							{children}
						</Dialog>
					</FocusTrap>
				</Backdrop>
			);
		}
		return null;
	}
}

export const Modal = inject('appStore', 'modalStore')(RawModal);
export default Modal;
