export const datePickerStyle = {
    overrides: [
        {
            selector: '.vhidden',
            style: {
                border: {
                    _value: '0',
                },
                clip: 'rect(1px, 1px, 1px, 1px)',
                height: '1px',
                overflow: {
                    _value: 'hidden',
                },
                padding: {
                    _value: '0',
                },
                position: 'absolute',
                top: '0',
                width: '1px',
            },
        },
    ],
    children: {
        buttonDay: {
            appearance: 'none',
            background: {
                color: 'transparent',
            },
            border: {
                _value: '0',
                radius: '50%',
            },
            color: '#333',
            cursor: 'pointer',
            display: 'inline-block',
            font: {
                family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
                size: '0.875rem',
                weight: '400',
            },
            height: '36px',
            lineHeight: '1.25',
            padding: {
                _value: '0 0 1px',
            },
            position: 'relative',
            text: {
                align: 'center',
            },
            verticalAlign: 'middle',
            width: '36px',
            zIndex: '1',
            selectors: {
                '.is-today': {
                    box: {
                        shadow: '0 0 0 1px #005fcc',
                    },
                    position: 'relative',
                    zIndex: '200',
                },
                ':hover::before': {
                    background: {
                        color: '#005fcc',
                    },
                    border: {
                        radius: '50%',
                    },
                    bottom: '0',
                    content: '""',
                    left: '0',
                    opacity: '0.06',
                    position: 'absolute',
                    right: '0',
                    top: '0',
                },
                '.is-today::before': {
                    background: {
                        color: '#005fcc',
                    },
                    border: {
                        radius: '50%',
                    },
                    bottom: '0',
                    content: '""',
                    left: '0',
                    opacity: '0.06',
                    position: 'absolute',
                    right: '0',
                    top: '0',
                },
                '[aria-pressed="true"]': {
                    background: {
                        color: '#005fcc',
                    },
                    box: {
                        shadow: 'none',
                    },
                    color: '#fff',
                    outline: {
                        _value: '0',
                    },
                },
                ':focus': {
                    background: {
                        color: '#005fcc',
                    },
                    box: {
                        shadow: '0 0 5px #005fcc',
                    },
                    color: '#fff',
                    outline: {
                        _value: '0',
                    },
                    zIndex: '200',
                },
                ':active': {
                    background: {
                        color: '#005fcc',
                    },
                    box: {
                        shadow: '0 0 5px #005fcc',
                    },
                    color: '#fff',
                    outline: {
                        _value: '0',
                    },
                    zIndex: '200',
                },
                ':not(.is-month)': {
                    box: {
                        shadow: 'none',
                    },
                    background: {
                        color: 'transparent',
                    },
                    color: '#333',
                    cursor: 'default',
                    opacity: '0.5',
                },
                '[aria-disabled="true"]': {
                    background: {
                        color: 'transparent',
                    },
                    color: '#333',
                    cursor: 'default',
                    opacity: '0.5',
                },
                '[aria-disabled="true"].is-today': {
                    box: {
                        shadow: '0 0 0 1px #005fcc',
                    },
                },
                '[aria-disabled="true"]:focus': {
                    box: {
                        shadow: '0 0 5px #005fcc',
                    },
                    background: {
                        color: '#005fcc',
                    },
                    color: '#fff',
                },
                '[aria-disabled="true"]:not(.is-today)::before': {
                    display: 'none',
                },
                '.is-outside': {
                    background: {
                        color: '#f5f5f5',
                    },
                    box: {
                        shadow: 'none',
                    },
                    color: '#333',
                    cursor: 'default',
                    opacity: '0.6',
                    pointerEvents: 'none',
                },
                '.is-outside::before': {
                    display: 'none',
                },
            },
        },
        buttonToggle: {
            align: {
                items: 'center',
            },
            appearance: 'none',
            background: {
                color: '#f5f5f5',
            },
            border: {
                width: '0',
                radius: '0',
                top: {
                    rightRadius: '4px',
                },
                bottom: {
                    rightRadius: '4px',
                },
            },
            box: {
                shadow: 'inset 1px 0 0 rgba(0, 0, 0, 0.1)',
            },
            color: '#333',
            cursor: 'pointer',
            display: 'flex',
            height: 'calc(100% - 2px)',
            justifyContent: 'center',
            padding: {
                _value: '0',
            },
            position: 'absolute',
            right: '1px',
            top: '1px',
            userSelect: 'none',
            width: '48px',
            zIndex: '2',
            selectors: {
                ':focus': {
                    box: {
                        shadow: '0 0 0 2px #005fcc',
                    },
                    outline: {
                        _value: '0',
                    },
                },
            },
        },
        input: {
            appearance: 'none',
            background: {
                color: '#fff',
            },
            border: {
                radius: '4px',
                width: '1px',
                style: 'solid',
                color: '#333',
            },
            color: '#333',
            float: 'none',
            font: {
                family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
                size: '100%',
            },
            lineHeight: 'normal',
            padding: {
                _value: '14px 60px 14px 14px',
            },
            width: '100%',
            selectors: {
                ':focus': {
                    border: {
                        color: '#005fcc',
                    },
                    box: {
                        shadow: '0 0 0 1px #005fcc',
                    },
                    outline: {
                        _value: '0',
                    },
                },
                '::-webkit-input-placeholder': {
                    color: '#666',
                    opacity: '1',
                },
                ':-moz-placeholder': {
                    color: '#666',
                    opacity: '1',
                },
                ':-ms-input-placeholder': {
                    color: '#666',
                },
            },
        },
        inputWrapper: {
            position: 'relative',
            width: '100%',
        },
        toggleIcon: {
            display: 'flex',
            flex: {
                basis: '100%',
            },
            justifyContent: 'center',
            align: {
                items: 'center',
            },
        },
        table: {
            border: {
                collapse: 'collapse',
                spacing: '0',
            },
            color: '#333',
            font: {
                size: '1rem',
                weight: '400',
            },
            lineHeight: ' 1.25',
            text: {
                align: 'center',
            },
            width: '100%',
        },
        tableBody: {},
        tableCell: {
            text: {
                align: 'center',
            },
        },
        tableHead: {},
        tableHeadCell: {
            font: {
                size: '0.75rem',
                weight: '600',
            },
            letterSpacing: '1px',
            lineHeight: '1.25',
            padding: {
                bottom: '8px',
            },
            text: {
                decoration: 'none',
                transform: 'uppercase',
            },
        },
        tableRow: {},
        buttonClose: {
            align: {
                items: 'center',
            },
            appearance: 'none',
            background: {
                color: '#f5f5f5',
            },
            border: {
                width: '0',
                radius: '50%',
            },
            color: '#333',
            cursor: 'pointer',
            display: 'flex',
            height: '24px',
            justifyContent: 'center',
            padding: {
                _value: '0',
            },
            width: '24px',
            breakpoints: [
                {
                    min: '36em',
                    style: {
                        opacity: '0',
                        selectors: {
                            ':focus': {
                                opacity: '1',
                            },
                        },
                    },
                },
            ],
            selectors: {
                ':focus': {
                    box: {
                        shadow: '0 0 0 2px #005fcc',
                    },
                    outline: {
                        style: 'none',
                    },
                },
                ' svg': {
                    margin: {
                        _value: '0 auto',
                    },
                },
            },
        },
        buttonNavigation: {
            align: {
                items: 'center',
            },
            appearance: 'none',
            background: {
                color: '#f5f5f5',
            },
            border: {
                width: '0',
                radius: '50%',
            },
            color: '#333',
            cursor: 'pointer',
            display: 'inline-flex',
            height: '32px',
            justifyContent: 'center',
            margin: {
                left: '8px',
            },
            padding: {
                _value: '0',
            },
            transition: {
                duration: '300ms',
                property: 'background-color',
                timingFunction: 'ease',
            },
            width: '32px',
            breakpoints: [
                {
                    max: '35.9375em',
                    style: {
                        height: '40px',
                        width: '40px',
                    },
                },
            ],
            selectors: {
                ':focus': {
                    box: {
                        shadow: '0 0 0 2px #005fcc',
                    },
                    outline: {
                        _value: '0',
                    },
                },
                ':active:focus': {
                    box: {
                        shadow: 'none',
                    },
                },
                ':disabled': {
                    cursor: 'default',
                    opacity: '0.5',
                },
                ' svg': {
                    margin: {
                        _value: '0 auto',
                    },
                },
            },
        },
        buttonNext: {},
        buttonPrevious: {},
        datePickerWrapper: {
            box: {
                sizing: 'border-box',
            },
            color: '#333',
            display: 'block',
            font: {
                family: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
            },
            margin: {
                _value: '0',
            },
            position: 'relative',
            text: {
                align: 'left',
            },
            width: '100%',
            selectors: {
                ' *': {
                    box: {
                        sizing: 'border-box',
                    },
                    margin: {
                        _value: '0',
                    },
                },
                ' *::before': {
                    box: {
                        sizing: 'border-box',
                    },
                    margin: {
                        _value: '0',
                    },
                },
                ' *::after': {
                    box: {
                        sizing: 'border-box',
                    },
                    margin: {
                        _value: '0',
                    },
                },
            },
        },
        dialog: {
            right: '0',
            min: {
                width: '320px',
            },
            opacity: '0',
            position: 'absolute',
            top: '100%',
            transform: 'scale(0.96) translateZ(0) translateY(-20px)',
            transformOrigin: 'top right',
            transition: {
                _value: 'transform 300ms ease, opacity 300ms ease, visibility 300ms ease',
            },
            visibility: 'hidden',
            zIndex: '600',
            selectors: {
                '.is-left': {
                    left: '0',
                    right: 'auto',
                },
                '.is-active': {
                    opacity: '1',
                    transform: 'scale(1.0001) translateZ(0) translateY(0)',
                    visibility: 'visible',
                },
            },
            breakpoints: [
                {
                    max: '35.9375em',
                    style: {
                        display: 'flex',
                        width: '100%',
                        background: {
                            color: 'rgba(0, 0, 0, 0.8)',
                        },
                        bottom: '0',
                        position: 'fixed',
                        right: '0',
                        top: '0',
                        transform: 'translateZ(0)',
                        transformOrigin: 'bottom center',
                    },
                },
            ],
        },
        dialogContent: {
            background: {
                color: '#fff',
            },
            border: {
                width: '1px',
                style: 'solid',
                color: 'rgba(0, 0, 0, 0.1)',
                radius: '4px',
            },
            box: {
                shadow: '0 4px 10px 0 rgba(0, 0, 0, 0.1)',
            },
            margin: {
                left: 'auto',
                top: '8px',
            },
            max: {
                width: '310px',
            },
            min: {
                width: '290px',
            },
            padding: {
                _value: '16px 16px 20px',
            },
            position: 'relative',
            transform: 'none',
            width: '100%',
            zIndex: '600',
            breakpoints: [
                {
                    max: '35.9375em',
                    style: {
                        border: {
                            width: '0',
                            radius: '0',
                            top: {
                                leftRadius: '4px',
                                rightRadius: '4px',
                            },
                        },
                        bottom: '0',
                        left: '0',
                        margin: {
                            _value: '0',
                        },
                        max: {
                            width: 'none',
                        },
                        min: {
                            height: '26em',
                        },
                        opacity: '0',
                        padding: {
                            _value: '0 8% 20px',
                        },
                        position: 'absolute',
                        transform: 'translateZ(0) translateY(100%)',
                        transition: {
                            _value: 'transform 400ms ease, opacity 400ms ease, visibility 400ms ease',
                        },
                        visibility: 'hidden',
                        selectors: {
                            '.is-active': {
                                opacity: '1',
                                transform: 'translateZ(0) translateY(0)',
                                visibility: 'visible',
                            },
                        },
                    },
                },
            ],
        },
        header: {
            align: {
                items: 'center',
            },
            display: 'flex',
            justifyContent: 'space-between',
            margin: {
                bottom: '16px',
            },
            width: '100%',
        },
        mobile: {
            align: {
                items: 'center',
            },
            border: {
                bottom: {
                    _value: '1px solid rgba(0, 0, 0, 0.12)',
                },
            },
            display: 'flex',
            justifyContent: 'space-between',
            margin: {
                bottom: '20px',
                left: '-10%',
            },
            overflow: {
                _value: 'hidden',
            },
            padding: {
                _value: '12px 20px',
            },
            position: 'relative',
            text: {
                overflow: 'ellipsis',
            },
            whiteSpace: 'nowrap',
            width: '120%',
            breakpoints: [
                {
                    min: '36em',
                    style: {
                        border: {
                            width: '0',
                        },
                        margin: {
                            _value: '0',
                        },
                        overflow: {
                            _value: 'visible',
                        },
                        padding: {
                            _value: '0',
                        },
                        position: 'absolute',
                        right: '-8px',
                        top: '-8px',
                        width: 'auto',
                    },
                },
            ],
        },
        mobileHeading: {
            display: 'inline-block',
            font: {
                weight: '600',
            },
            max: {
                width: '84%',
            },
            overflow: {
                _value: 'hidden',
            },
            text: {
                overflow: 'ellipsis',
            },
            whiteSpace: 'nowrap',
            breakpoints: [
                {
                    min: '36em',
                    style: {
                        display: 'none',
                    },
                },
            ],
        },
        navigationWrapper: {
            whiteSpace: 'nowrap',
        },
        selectLabel: {
            align: {
                items: 'center',
            },
            border: {
                radius: '4px',
            },
            color: '#333',
            display: 'flex',
            font: {
                size: '1.25rem',
                weight: '600',
            },
            lineHeight: '1.25',
            padding: {
                _value: '0 4px 0 8px',
            },
            pointerEvents: 'none',
            position: 'relative',
            width: '100%',
            zIndex: '1',
            selectors: {
                ' svg': {
                    width: '16px',
                    height: '16px',
                },
            },
        },
        selectMonth: {},
        selectWrapper: {
            display: 'inline-flex',
            margin: {
                top: '4px',
            },
            position: 'relative',
            selectors: {
                ' span': {
                    margin: {
                        right: '4px',
                    },
                },
                ' select': {
                    cursor: 'pointer',
                    font: {
                        size: '1rem',
                    },
                    height: '100%',
                    left: '0',
                    opacity: '0',
                    position: 'absolute',
                    top: '0',
                    width: '100%',
                    zIndex: '2',
                    selectors: {
                        ':focus + .select-label': {
                            box: {
                                shadow: '0 0 0 2px #005fcc',
                            },
                        },
                    },
                },
            },
        },
        selectYear: {},
    },
};
