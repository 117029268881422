export const dateRangeInputStyle = {
    children: {
        wrapper: {
            display: 'flex',
            flex: {
                direction: 'row',
            },
            box: {
                shadow: 'rgba(0, 0, 0, 0.19) 0px 10px 30px, rgba(0, 0, 0, 0.23) 0px 6px 10px',
            },
            border: {
                radius: '2px',
            },
            width: '800px',
            font: {
                family: '"DM Sans", sans-serif',
            },
        },
        fieldsWrapper: {
            background: {
                color: 'rgb(243, 244, 248)',
            },
            flex: {
                _value: '1',
                direction: 'column',
            },
            padding: {
                _value: '30px 40px 15px',
            },
        },
        field: {
            margin: {
                bottom: '20px',
            },
            position: 'relative',
            overrides: [
                {
                    selector: '[data-selector="base-icon-wrapper"]',
                    style: {
                        position: 'absolute',
                        right: '2px',
                        bottom: '4px',
                        cursor: 'pointer',
                    },
                },
                {
                    selector: '[data-selector="base-input"]',
                    style: {
                        background: {
                            _value: 'none',
                        },
                        border: {
                            _value: 'none',
                            bottom: {
                                _value: '1px solid rgb(58, 64, 81)',
                            },
                        },
                        outline: {
                            _value: 'none',
                        },
                        width: '100%',
                    },
                },
                {
                    selector: '[data-selector="base-input"].active-field',
                    style: {
                        border: {
                            bottom: {
                                _value: '2px solid rgb(34, 114, 243)',
                            },
                        },
                    },
                },
                {
                    selector: '[data-selector="base-dropdown-wrapper"]',
                    style: {
                        font: {
                            size: '13px',
                            weight: 'normal',
                        },
                    },
                },
                {
                    selector: '[data-selector="base-dropdown-header"]',
                    style: {
                        width: '100%',
                        border: {
                            _value: 'none',
                            bottom: {
                                _value: '1px solid rgb(58, 64, 81)',
                            },
                        },
                    },
                },
                {
                    selector: '[data-selector="base-dropdown-headerTitle"]',
                    style: {
                        font: {
                            size: '13px',
                            weight: 'normal',
                        },
                    },
                },
                {
                    selector: '[data-selector="base-dropdown-listItems"]',
                    style: {
                        width: '100%',
                    },
                },
            ],
        },
        fieldLabel: {
            color: 'rgba(0, 0, 0, 0.3)',
            font: {
                size: '13px',
            },
            lineHeight: '22px',
            display: 'block',
        },
        selectedDateWrapper: {
            background: {
                color: 'rgb(34, 114, 243)',
            },
            width: '165px',
            padding: {
                _value: '20px',
            },
            color: 'white',
        },
        selectedYear: {
            opacity: '0.7',
            font: {
                size: '16px',
                weight: '500',
            },
            lineHeight: '16px',
            cursor: 'pointer',
            margin: {
                bottom: '10px',
            },
            width: '144px',
        },
        selectedDate: {
            font: {
                size: '36px',
                weight: '500',
            },
            lineHeight: '36px',
            cursor: 'pointer',
        },
        calendarWrapper: {
            width: '240px',
            overrides: [
                {
                    selector: '[data-selector="base-calendarInput-calendar"]',
                    style: {
                        box: {
                            shadow: 'none',
                        },
                    },
                },
            ],
        },
    },
};
