export const documentDownloadListStyle = {
    children: {
        wrapper: {
            font: {
                size: '13px',
            },
        },
        documentLabel: {
            lineHeight: '34px',
        },
    },
    overrides: [
        {
            selector: '[data-selector="base-list"]',
            style: {
                padding: {
                    left: '0',
                },
            },
        },
    ],
};
