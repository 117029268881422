export const changeIndicatorStyle = {
    children: {
        wrapper: {},
        valueDown: {
            color: 'red',
        },
        valueUp: {
            color: 'green',
        },
        icon: {
            height: '30px',
            width: '30px',
            padding: {
                bottom: '5px',
            },
            display: 'inline',
        },
    },
};
