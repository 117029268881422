export const accordionPanelStyle = {
    children: {
        wrapper: {
            overrides: [
                {
                    selector: '[data-selector="base-pill-wrapper"]',
                    style: {
                        background: {
                            color: 'dodgerblue',
                        },
                        color: 'white',
                        margin: {
                            left: '10px',
                            bottom: '5px',
                        },
                        padding: {
                            _value: '4px 10px',
                        },
                        border: {
                            radius: '10px',
                        },
                        font: {
                            size: '11px',
                        },
                    },
                },
                {
                    selector: '[data-selector="base-accordionPanel-toggle"][data-align="right"]',
                    style: {
                        flex: {
                            grow: 'auto',
                        },
                    },
                },
                {
                    selector: '[data-selector="base-accordionPanel-header"][data-is-open="true"]',
                    style: {
                        color: 'dodgerblue',
                        border: {
                            bottom: {
                                style: 'solid',
                                width: '2px',
                                color: 'dodgerblue',
                            },
                        },
                    },
                },
                {
                    selector: '[data-selector="base-tooltip-tooltipContentWrapper"]',
                    style: {
                        color: 'black',
                        font: {
                            size: 'smaller',
                            weight: 'normal',
                        },
                    },
                },
            ],
        },
        header: {
            display: 'flex',
            flex: {
                direction: 'row',
            },
            font: {
                size: 'large',
                weight: 'bold',
            },
            margin: {
                bottom: '4px',
            },
            cursor: 'pointer',
        },
        headerText: {
            display: 'flex',
            flex: {
                _value: '1',
            },
        },
        content: {
            margin: {
                bottom: '10px',
            },
        },
    },
};
