export const summaryBlockStyle = {
    children: {
        wrapper: {
            text: {
                align: 'center',
            },
            border: {
                color: '#dddddd',
                style: 'solid',
                right: {
                    width: '1px',
                },
                left: {
                    width: '1px',
                },
                top: {
                    width: '0px',
                },
                bottom: {
                    width: '3px',
                },
                radius: '3px',
            },
            padding: {
                _value: '10px',
            },
            overrides: [
                {
                    selector: '[data-selector="fact-fact-title"]',
                    style: {
                        margin: {
                            bottom: '10px',
                        },
                    },
                },
                {
                    selector: '[data-selector="fact-fact-subtitle"]',
                    style: {
                        margin: {
                            bottom: '10px',
                        },
                    },
                },
                {
                    selector: '[data-selector="fact-fact-valueText"]',
                    style: {
                        color: 'dodgerblue',
                        font: {
                            size: 'larger',
                        },
                    },
                },
            ],
        },
    },
};
