export const factStyle = {
    children: {
        wrapper: {},
        title: {},
        groupWrapper: {
            width: '100%',
            display: 'block',
        },
        groupListWrapper: {
            padding: {
                left: '5px',
            },
            overrides: [
                {
                    selector: '[data-selector="base-list-listItem"]',
                    style: {
                        border: {
                            _value: 'none',
                        },
                    },
                },
            ],
        },
        subtitle: {
            color: '#aaaaaa',
            font: {
                size: 'small',
            },
        },
        value: {},
        valuePlaceholder: {
            font: {
                style: 'italic',
            },
        },
    },
};
