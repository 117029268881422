import React from 'react';
import { withSelector } from '../../../../shared/index.js';
import { InjectedStyledComponent } from '../../../../shared/InjectedStyledComponent.js';
import { TOOLTIP_CATEGORY_KEY, TOOLTIP_COMPONENT_KEY } from '../Tooltip.meta.js';
const pathStyle = {
    fill: 'inherit',
    stroke: 'inherit',
    strokeWidth: '1px',
    strokeDasharray: '14,7,0',
};
const arrow = () => (React.createElement("svg", { width: "24px", height: "24px", viewBox: "0 0 24 24" },
    React.createElement("path", { className: "arrow__path", d: "M7,10L12,15L17,10H7Z", style: pathStyle })));
// eslint-disable-next-line @typescript-eslint/naming-convention
const ArrowIcon = (props) => {
    const { className, children, ...sectionProps } = props;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const Section = withSelector('section', props);
    return (React.createElement(Section, { className: className, ...sectionProps }, arrow()));
};
export default InjectedStyledComponent(ArrowIcon, TOOLTIP_CATEGORY_KEY, TOOLTIP_COMPONENT_KEY, ['arrowIcon']);
