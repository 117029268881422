import { factListStyle } from '../fact/FactList/styles/FactList.style.js';
import { factStyle } from './Fact/styles/Fact.style.js';
import { summaryBlockStyle } from './SummaryBlock/styles/SummaryBlock.style.js';
import { summaryBlocksStyle } from './SummaryBlocks/styles/SummaryBlocks.style.js';
/* [Component: import] */
/* [ChartComponent: import] */
export const factTheme = {
    fact: factStyle,
    factList: factListStyle,
    summaryBlock: summaryBlockStyle,
    summaryBlocks: summaryBlocksStyle,
    /* [Component: interface] */
    /* [ChartComponent: interface] */
};
