export const iconStyle = {
    children: {
        wrapper: {
            display: 'flex',
            flex: {
                direction: 'column',
            },
            justifyContent: 'center',
            overrides: [
                {
                    selector: '[data-selector="base-icon"]',
                    style: {
                        verticalAlign: 'middle',
                    },
                },
            ],
        },
    },
};
