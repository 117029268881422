export const pillStyle = {
    text: {
        transform: 'uppercase',
    },
    children: {
        priorityWrapper: {
            border: {
                radius: '4px',
            },
            padding: {
                _value: '8px 16px',
            },
            color: '#ffffff',
        },
        primaryWrapper: {
            background: {
                color: '#005587',
            },
        },
        secondaryWrapper: {
            background: {
                color: 'red',
            },
        },
        tertiaryWrapper: {
            background: {
                color: 'green',
            },
        },
        defaultWrapper: {
            background: {
                color: '#ffffff',
            },
            color: '#000000',
        },
    },
};
