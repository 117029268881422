import React from 'react';
import { observer } from 'mobx-react';
import { ComponentBase } from '../../../shared/ComponentBase.js';
import { InjectedStyledComponent } from '../../../shared/InjectedStyledComponent.js';
import { AccessibilityContext } from '../Accessibility/Accessibility.js';
import Image from '../Image/Image.js';
import Wrapper from './styledComponents/Wrapper.js';
import { ICON_CATEGORY_KEY, ICON_COMPONENT_KEY } from './Icon.meta.js';
export class Icon extends ComponentBase {
    constructor(props) {
        super(props);
        this.categoryKey = ICON_CATEGORY_KEY;
        this.componentKey = ICON_COMPONENT_KEY;
    }
    imageAccessibleProps(accessibilityContext) {
        const { accessibilityText, asset } = this.props;
        const props = {};
        // default the alt to blank to hide the image from screen readers
        props['alt'] = accessibilityText ?? '';
        return props;
    }
    render() {
        const { className, onClick, accessibilityText, ...props } = this.props;
        const accessibilityContext = this.context;
        return (React.createElement(Wrapper, { className: className },
            React.createElement(Image, { className: className, onClick: onClick, ...props, ...this.imageAccessibleProps(accessibilityContext) })));
    }
}
Icon.contextType = AccessibilityContext;
export default InjectedStyledComponent(observer(Icon), ICON_CATEGORY_KEY, ICON_COMPONENT_KEY);
