export const awardStyle = {
    children: {
        wrapper: {
            max: {
                width: '335px',
            },
            padding: {
                _value: '15px',
            },
            box: {
                shadow: '0 2px 4px 0 rgba(0,0,0,0.2);',
            },
        },
        imageWrapper: {
            text: {
                align: 'center',
            },
        },
        awardImage: {
            max: {
                width: '303px',
                height: '122px',
            },
        },
        name: {
            lineHeight: '13px',
            font: {
                size: '14px',
                weight: 'bold',
            },
            margin: {
                top: '25px',
            },
        },
        description: {
            color: '#707173',
            lineHeight: '16px',
            font: {
                size: '14px',
            },
        },
    },
};
