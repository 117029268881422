import React from 'react';
import { InjectedStyledComponent } from '../../../../shared/InjectedStyledComponent.js';
import { withSelector } from '../../../../shared/withSelector.js';
import { TEXT_CATEGORY_KEY, TEXT_COMPONENT_KEY } from '../Text.meta.js';
// eslint-disable-next-line @typescript-eslint/naming-convention
const Content = (props) => {
    const { className, children = '' } = props;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const Section = withSelector('section', props);
    return (React.createElement(Section, { role: 'text', className: className }, children));
};
export default InjectedStyledComponent(Content, TEXT_CATEGORY_KEY, TEXT_COMPONENT_KEY, ['content']);
