import { stores } from '@kurtosys/app-start';
import { action, computed, makeObservable } from 'mobx';

import { IComponentStyles } from '../../../models/app/IComponentStyles.js';
import { IConfiguration } from '../../../models/app/IConfiguration.js';
import { ISelectionFieldBase } from '../../../models/app/ISelectionFieldBase.js';
import { TSelectionFieldOnChange } from '../../../models/app/TSelectionFieldOnChange.js';
import { TStoreContext } from '../../../models/app/TStoreContext.js';
import { AcceptanceStore } from '../../Acceptance/stores/AcceptanceStore.js';
import { AcceptanceDisclaimerStore } from '../../AcceptanceDisclaimer/stores/AcceptanceDisclaimerStore.js';
import { AppStore } from '../../App/stores/AppStore.js';
import { DisclaimerStore } from '../../Disclaimer/stores/DisclaimerStore.js';
import { SelectionStore } from '../../Selection/stores/SelectionStore.js';

type TranslationStore = stores.TranslationStore<IConfiguration, IComponentStyles>;
import { IManifest } from '@kurtosys/types/appsManager/index.js';

export class SelectionFieldStore extends stores.base.StoreBase<IConfiguration, IComponentStyles> {
	constructor(storeContext: TStoreContext, manifest: IManifest) {
		super(storeContext, manifest);
		makeObservable(this);
	}

	@computed
	get appStore(): AppStore {
		return this.storeContext.get<AppStore>('appStore');
	}

	@computed
	get translationStore(): TranslationStore {
		return this.storeContext.get<TranslationStore>('translationStore');
	}

	@computed
	get acceptanceStore(): AcceptanceStore {
		return this.storeContext.get<AcceptanceStore>('acceptanceStore');
	}

	@computed
	get selectionStore(): SelectionStore {
		return this.storeContext.get<SelectionStore>('selectionStore');
	}

	@computed
	get acceptanceDisclaimerStore(): AcceptanceDisclaimerStore {
		return this.storeContext.get<AcceptanceDisclaimerStore>('acceptanceDisclaimerStore');
	}

	@computed
	get disclaimerStore(): DisclaimerStore {
		return this.storeContext.get<DisclaimerStore>('disclaimerStore');
	}

	@computed
	get configuration() {
		if (this.storeContext && this.appStore) {
			return this.appStore.configuration;
		}
	}

	@action
	async initialize(): Promise<void> {
		return;
	}

	@computed
	get onChangeOptions() {
		return this.configuration && this.configuration.onChangeOptions;
	}

	@action
	handleFieldOnChange = (field: ISelectionFieldBase, value: any) => {
		const { onChange } = field;
		if (this.acceptanceStore) {
			// reset the accepted state to prevent previous states from defaulting when changing field values
			this.acceptanceStore.resetAcceptedState();
		}
		if (onChange) {
			const { functionName } = onChange;
			if (this[functionName]) {
				this[functionName](this.selectionStore.rawValues, onChange);
			}
		}
	};

	@action
	cultureChange = async (values: any, onChange: TSelectionFieldOnChange) => {
		if (onChange.functionName === 'cultureChange') {
			if (this.translationStore && this.selectionStore) {
				const { options } = onChange;
				const cultureChangeOptions = options || (this.onChangeOptions && this.onChangeOptions.cultureChange);
				if (cultureChangeOptions) {
					const cultureInput =
						this.appStore &&
						this.appStore.appParamsHelper &&
						this.appStore.appParamsHelper.inputs &&
						this.appStore.appParamsHelper.inputs['culture'];
					const newCulture = this.selectionStore.getConditionalValueFieldValue(
						cultureChangeOptions,
						values,
						cultureInput,
					);
					if (this.translationStore.culture !== newCulture) {
						await this.translationStore.setCulture(newCulture);
					}
				}
			}
			if (this.disclaimerStore) {
				this.disclaimerStore.loadDisclaimers();
			}
			if (this.acceptanceDisclaimerStore) {
				this.acceptanceDisclaimerStore.loadDisclaimers();
			}
		}
	};
}
