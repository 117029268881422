import { bulkDocumentDownloadStyle } from './BulkDocumentDownload/styles/BulkDocumentDownload.style.js';
import { documentDownloadStyle } from './DocumentDownload/styles/DocumentDownload.style.js';
import { documentDownloadListStyle } from './DocumentDownloadList/styles/DocumentDownloadList.style.js';
import { documentTileStyle } from './DocumentTile/styles/DocumentTile.style.js';
import { documentTilesStyle } from './DocumentTiles/styles/DocumentTiles.style.js';
/* [Component: import] */
/* [ChartComponent: import] */
export const fileTheme = {
    documentDownload: documentDownloadStyle,
    documentDownloadList: documentDownloadListStyle,
    bulkDocumentDownload: bulkDocumentDownloadStyle,
    documentTile: documentTileStyle,
    documentTiles: documentTilesStyle,
    /* [Component: interface] */
    /* [ChartComponent: interface] */
};
