import React from 'react';
import { Manifest } from '@kurtosys/app-loader/dist/Manifest.js';
import { getSelector } from '@kurtosys/ksys-app-components/dist/shared/selector.js';
import { withSelector } from '@kurtosys/ksys-app-components/dist/shared/withSelector.js';
import { common, query } from '@kurtosys/ksys-app-template';
import { IComponentStyle } from '@kurtosys/types/componentStyle/IComponentStyle.js';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

import { TEMPLATE_STORE } from '../../index.js';
import { IAppStoreInjection } from '../../models/app/IAppStoreInjection.js';
import { IComponentStyles } from '../../models/app/IComponentStyles.js';
const manifest = new Manifest(TEMPLATE_STORE.templateConfiguration);
const componentStyleInstance = new common.commonUtils.ComponentStyle({
	queryClass: query.Query,
});

// tslint:disable-next-line: function-name
export function InjectedStyledComponent<K extends keyof IComponentStyles>(
	ComponentOrElement: any,
	componentStyleKey: K,
	childStyleKeys: string[] = [],
	storeKeys: string[] = ['appStore'],
	rawCssPrefix?: string | ((props: unknown) => string),
	rawCssSuffix?: string | ((props: unknown) => string),
	componentStyle?: IComponentStyle,
) {
	const appName = (manifest.packageName || '').replace('ksys-app-', '');
	const selector = getSelector(appName, componentStyleKey, ...childStyleKeys);
	const styles = (props: IAppStoreInjection) => {
		const { appStore } = props;
		const style = appStore && appStore.getComponentStyle(componentStyleKey, ...(childStyleKeys || []));
		const css = componentStyleInstance.getCss(style, props);
		if (css) {
			return css;
		}

		/**
		 * Apply the default style per app component or app styled component.
		 */
		if (componentStyle) {
			const style = appStore && appStore.extractComponentStyle(componentStyle, ...(childStyleKeys || []));
			return componentStyleInstance.getCss(style, props);
		}
		return '';
	};
	return inject(...storeKeys)(
		observer(
			withSelector(
				styled((props) => {
					// strip stores
					const newProps = Object.keys(props).reduce((newProps: Record<string, any>, propKey: string) => {
						if (storeKeys.find((key) => key === propKey) === undefined && propKey !== 'componentStyle') {
							newProps[propKey] = props[propKey];
						}
						return newProps;
					}, {});

					return <ComponentOrElement {...newProps} />;
				})`
					${(props) => (typeof rawCssPrefix === 'function' ? rawCssPrefix(props) : rawCssPrefix || '')}
					${styles}
					${(props) => (typeof rawCssSuffix === 'function' ? rawCssSuffix(props) : rawCssSuffix || '')}
				`,
				selector,
			),
		),
	);
}
