export const menuListStyle = {
    min: {
        width: '150px',
    },
    listStyle: {
        _value: 'none',
    },
    margin: {
        _value: '0',
    },
    padding: {
        _value: '4px 0',
    },
};
