export const labelledInputStyle = {
    children: {
        wrapper: {
            margin: {
                bottom: '20px',
            },
        },
        labelComponent: {
            display: 'block',
            font: {
                size: '9px',
            },
        },
        inputComponent: {
            display: 'block',
            font: {
                size: '9px',
            },
            padding: {
                _value: '4px',
            },
            width: '150px',
            margin: {
                _value: '4px 0px',
            },
        },
        validationMessage: {
            display: 'block',
            font: {
                size: '9px',
            },
            color: 'red',
        },
    },
};
