export const radioButtonsStyle = {
    children: {
        wrapper: {
            overrides: [
                {
                    selector: '[data-selector="base-icon"]',
                    style: {
                        width: '20px',
                        height: '20px',
                    },
                },
            ],
        },
        radioButtonLabel: {
            display: 'flex',
        },
    },
};
