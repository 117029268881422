import { stores } from '@kurtosys/app-start';
import { IManifest } from '@kurtosys/types/appsManager/index.js';
import { action, makeObservable } from 'mobx';

import { IComponentStyles } from '../../../models/app/IComponentStyles.js';
import { IConfiguration } from '../../../models/app/IConfiguration.js';
import { TStoreContext } from '../../../models/app/TStoreContext.js';

export class ModalStore extends stores.base.StoreBase<IConfiguration, IComponentStyles> {
	constructor(storeContext: TStoreContext, manifest: IManifest) {
		super(storeContext, manifest);
		makeObservable(this);
	}

	@action
	async initialize(): Promise<void> {
		return;
	}
}
