export const bulkDocumentDownloadStyle = {
    children: {
        linkButton: {
            border: {
                style: 'solid',
                width: '2px;',
                radius: '15px',
            },
            padding: {
                top: '5px',
                right: '5px',
                bottom: '5px',
                left: '5px',
            },
        },
    },
};
