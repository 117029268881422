import React, { Component } from 'react';
import { components } from '@kurtosys/ksys-app-components';
import { KEYS } from '@kurtosys/ksys-app-components/dist/shared/KeyboardKeys.js';
import { common } from '@kurtosys/ksys-app-template';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';

import { SelectionFieldDisplayLogic } from '../../models/app/SelectionFieldDisplayLogic.js';
import { IconDropdown } from '../IconDropdown/IconDropdown';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';

import Label from './styledComponents/Label.js';
import List from './styledComponents/List.js';
import ListItem from './styledComponents/ListItem.js';
import Option from './styledComponents/Option.js';
import Select from './styledComponents/Select.js';
import Wrapper from './styledComponents/Wrapper.js';
import { ISelectionFieldProps, ISelectionFieldState } from './models';

const { isNullOrUndefined } = common.commonUtils;
const Translate = components.base.Translate.Translate;
const Text = components.base.Text.Text;
const RadioButtons = components.base.RadioButtons.RadioButtons;

class RawSelectionField extends Component<ISelectionFieldProps, ISelectionFieldState> {
	static configurationKey = 'selectionField' as const;
	static styleKey = 'selectionField' as const;

	constructor(props: ISelectionFieldProps) {
		super(props);
		const { field } = props;
		const { type, defaultValue } = field;
		if (type === 'EMBEDDED_INPUT') {
			const value = props.value || defaultValue;
			this.handleChange(value);
		}
	}

	@action
	handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const value = event.target.value;
		this.handleChange(value);
	};

	@action
	handleRadioChange = (value: any) => {
		this.handleChange(value);
	};

	@action
	handleItemClick = (value: any) => {
		this.handleChange(value);
	};

	@action
	handleItemKeyDown = (event: React.KeyboardEvent<HTMLUListElement>, value: any) => {
		// only handle enter key and space key
		if (event.key === KEYS.ENTER || event.key === KEYS.SPACE || event.key === KEYS.SPACEBAR) {
			this.handleChange(value);
		}
	};

	@action
	handleChange = (value: any) => {
		const { onChange, field, selectionFieldStore } = this.props;
		const { onChange: fieldOnChange } = field;

		if (onChange) {
			onChange(field, value);
		}
		// This must go after the onChange from the props
		if (fieldOnChange && selectionFieldStore) {
			selectionFieldStore.handleFieldOnChange(field, value);
		}
		// this.value = value;
	};

	render() {
		const { field, options, value, translationStore, selectionStore } = this.props;
		const { label, type, autoSelectSingleOption, display = SelectionFieldDisplayLogic.show } = field;
		let placeholder;

		if (selectionStore) {
			placeholder = selectionStore.getPlaceholderText(field);
		}

		if (autoSelectSingleOption && isNullOrUndefined(value) && !isNullOrUndefined(options)) {
			const autoSelectOptions = options.filter((option) => !isNullOrUndefined(option.value));
			if (autoSelectOptions.length === 1) {
				this.handleChange(autoSelectOptions[0].value);
			}
		}
		if (display === SelectionFieldDisplayLogic.showIfMoreThanOneOption && (!options || options.length <= 1)) {
			return null;
		}
		return (
			<Wrapper>
				<Label>
					<Translate>{label}</Translate>
				</Label>
				{type === 'RADIO_BUTTONS' && (
					<RadioButtons
						{...field.radioButtonProps}
						value={value}
						options={options.map((option) => {
							return {
								...option,
								label: translationStore ? translationStore.translate(option.key) : option.key,
							};
						})}
						onChange={this.handleRadioChange}
					/>
				)}
				{type === 'ICON_DROP_DOWN' && (
					<IconDropdown
						field={field}
						value={value}
						options={options}
						onSelectItem={this.handleItemClick}
						onChange={this.handleSelectChange}
					/>
				)}
				{type === 'DROP_DOWN' && (
					<Select
						key={value || placeholder}
						defaultValue={value || placeholder}
						onChange={this.handleSelectChange}
					>
						<Option disabled hidden value={placeholder}>
							{translationStore && placeholder ? translationStore.translate(placeholder) : placeholder}
						</Option>
						{options.map((option) => {
							return (
								<Option key={option.value} value={option.value}>
									{translationStore ? translationStore.translate(option.key) : option.key}
								</Option>
							);
						})}
					</Select>
				)}
				{type === 'LIST' && (
					<List role="radiogroup">
						{options.map((option) => {
							const descriptionTextProps =
								selectionStore && selectionStore.getDescriptionTextProps(option, field);
							return (
								<ListItem
									key={option.value}
									onClick={() => this.handleItemClick(option.value)}
									onKeyDown={(event: React.KeyboardEvent<HTMLUListElement>) =>
										this.handleItemKeyDown(event, option.value)
									}
									selected={value === option.value}
									role="radio"
									aria-checked={value === option.value}
									tabIndex={0}
								>
									<Translate>{option.key}</Translate>
									{descriptionTextProps && <Text {...descriptionTextProps} />}
								</ListItem>
							);
						})}
					</List>
				)}
			</Wrapper>
		);
	}
}

export const SelectionField = inject(
	'appStore',
	'selectionFieldStore',
	'translationStore',
)(observer(RawSelectionField));
export default InjectedStyledComponent(SelectionField, 'selectionField');
