export const relativePerformanceChartStyle = {
    children: {
        benchmarkIndicator: {
            position: 'absolute',
            height: '2px',
            left: '4px',
            right: '4px',
        },
        benchmarkIndicatorLabel: {
            position: 'absolute',
            top: '-15px',
            right: '-2px',
            font: {
                size: '10px',
            },
        },
        benchmarkIndicatorDot: {
            position: 'absolute',
            height: '10px',
            width: '10px',
            border: {
                radius: '50%',
            },
            background: {
                color: 'blue',
            },
            top: '-4px',
        },
        benchmarkIndicatorLine: {
            position: 'absolute',
            height: '2px',
            left: '0',
            right: '0',
            background: {
                color: 'blue',
            },
        },
        blocks: {
            position: 'relative',
            display: 'flex',
            flex: {
                direction: 'row',
            },
        },
        blocksWrapper: {
            position: 'relative',
            padding: {
                _value: '0px 15px',
            },
        },
        blockWrapper: {
            margin: {
                _value: '0 5px',
            },
            flex: {
                grow: '1',
            },
            text: {
                align: 'center',
            },
        },
        block: {
            position: 'relative',
            border: {
                _value: 'solid 1px #000000',
            },
            height: '200px',
        },
        blockValue: {
            position: 'absolute',
            height: '2px',
            left: '0',
            right: '0',
        },
        chart: {
            position: 'relative',
            padding: {
                _value: '10px 0',
            },
        },
    },
};
