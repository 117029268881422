import React, { Component } from 'react';
import { components } from '@kurtosys/ksys-app-components';
import { inject, observer } from 'mobx-react';

import { DisclaimerStore } from '../Disclaimer/stores/DisclaimerStore';
import Footnote from '../Footnote/Footnote';
import { Modal } from '../Modal/Modal';
import { SelectionStore } from '../Selection/stores/SelectionStore';
import DebugInfoAction from '../shared/DebugInfoAction';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Loading from '../shared/Loading';
import SkeletonLoader from '../shared/SkeletonLoader';

import { IAppProps } from './models/IAppProps';
import { IAppState } from './models/IAppState';
import InfoMessage from './styledComponents/InfoMessage';
import InfoMessageWrapper from './styledComponents/InfoMessageWrapper';
import LoadingWrapper from './styledComponents/LoadingWrapper';
import RedirectMessageWrapper from './styledComponents/RedirectMessageWrapper';
import Wrapper from './styledComponents/Wrapper';

const Application = components.base.Application.Application;
const NoDataPlaceholder = components.base.NoDataPlaceholder.NoDataPlaceholder;
const Disclaimer = components.overview.Disclaimer.Disclaimer;
const Grid = components.base.Grid.Grid;
const VisuallyHidden = components.base.VisuallyHidden.VisuallyHidden;
const Translate = components.base.Translate.Translate;

class RawApp extends Component<IAppProps, IAppState> {
	static configurationKey = 'app' as const;
	static styleKey = 'app' as const;
	constructor(props: IAppProps) {
		super(props);
	}

	renderAttestation = () => {
		const { appStore, className } = this.props;

		const selectionStore = appStore.storeContext.get<SelectionStore>('selectionStore');
		const disclaimerStore = appStore.storeContext.get<DisclaimerStore>('disclaimerStore');

		if (!appStore || !selectionStore || !disclaimerStore) {
			return null;
		}
		const { isAttestationBootstrapped, skeletonLoader, skeletonLoaders, redirectMessage, showRedirectMessage } =
			appStore;
		const { embedLoadingElement } = this.props;
		if (!isAttestationBootstrapped) {
			if (showRedirectMessage) {
				return (
					<RedirectMessageWrapper>
						<LoadingWrapper>
							<Loading />
						</LoadingWrapper>
						<InfoMessageWrapper>
							<InfoMessage>{redirectMessage}</InfoMessage>
						</InfoMessageWrapper>
					</RedirectMessageWrapper>
				);
			}
			if (skeletonLoader) {
				return (
					<SkeletonLoader config={skeletonLoader} variables={skeletonLoaders && skeletonLoaders.variables} />
				);
			}
			return <Loading embedLoadingElement={embedLoadingElement} />;
		}
		const {
			theme,
			show,
			showPlaceholder,
			noDataPlaceholder: noDataPlaceholderText,
			noDataPlaceholderDisclaimer,
			libraryComponentsConfiguration,
			getTranslateFunction,
			culture,
			assets,
			assetCacheOptions,
			assetRegisters,
			getAccessibilityTextFunction,
			components,
			grid,
			hasSelectionWizard,
			ctaValidationText,
		} = appStore;

		if (!show) {
			return null;
		}

		const noDataPlaceholder =
			(noDataPlaceholderDisclaimer && <Disclaimer {...noDataPlaceholderDisclaimer} />) ||
			(noDataPlaceholderText && <NoDataPlaceholder>{noDataPlaceholderText}</NoDataPlaceholder>);

		const translate = getTranslateFunction();
		const accessibilityText = getAccessibilityTextFunction();

		return (
			<Application
				theme={theme}
				configuration={libraryComponentsConfiguration}
				translate={translate}
				culture={culture}
				assets={assets}
				assetCacheOptions={assetCacheOptions}
				assetRegisters={assetRegisters}
				accessibilityText={accessibilityText}
				analyticsHelper={appStore.analyticsHelper}
			>
				<DebugInfoAction appStore={appStore} />
				<Wrapper className={className}>
					{showPlaceholder && noDataPlaceholder}
					{!showPlaceholder && <Grid components={components as any} grid={grid} />}
					{!hasSelectionWizard && <Footnote />}
				</Wrapper>
				<VisuallyHidden>
					<span role="alert" aria-live="polite">
						<Translate>{ctaValidationText}</Translate>
					</span>
				</VisuallyHidden>
			</Application>
		);
	};

	render() {
		const { appStore, className } = this.props;

		if (!appStore) {
			return null;
		}

		if (appStore.isAttestationDisabled) {
			return null;
		}
		const { isAttestationBootstrapped, prelaunchModal, skeletonLoader, skeletonLoaders } = appStore;
		if (isAttestationBootstrapped || prelaunchModal) {
			if (appStore.isAttestationActive) {
				const modalClass = prelaunchModal && !isAttestationBootstrapped ? 'attestation-prelaunch-modal' : '';
				return (
					<Modal className={modalClass} open={appStore.isAttestationRequired}>
						{this.renderAttestation()}
					</Modal>
				);
			}
			return this.renderAttestation();
		}
		if (!appStore.isAttestationActive) {
			if (skeletonLoader) {
				return (
					<SkeletonLoader config={skeletonLoader} variables={skeletonLoaders && skeletonLoaders.variables} />
				);
			}
			return <Loading />;
		}

		return null;
	}
}

export const App = inject('appStore', 'queryStore')(observer(RawApp));
export default InjectedStyledComponent(App, 'app');
