export const searchStyle = {
    children: {
        wrapper: {
            position: 'relative',
            height: '44px',
        },
        searchBar: {
            position: 'absolute',
            zIndex: '3',
            width: '100%',
            display: 'flex',
            border: {
                _value: '1px solid #dfdfdf',
            },
            padding: {
                _value: '10px 0',
            },
            overrides: [
                {
                    selector: 'input[data-selector="base-input"]',
                    style: {
                        flex: {
                            basis: '98%',
                        },
                        height: '24px',
                        font: {
                            size: '16px',
                        },
                        lineHeight: '24px',
                        border: {
                            _value: 'none',
                        },
                        margin: {
                            _value: '0 5px',
                        },
                        selectors: {
                            '::-webkit-input-placeholder': {
                                color: '#bbbbbb',
                            },
                            '::-ms-clear': {
                                display: 'none',
                            },
                        },
                    },
                },
            ],
        },
        searchClear: {
            min: {
                width: '40px',
            },
            cursor: 'pointer',
            background: {
                _value: 'none',
            },
            border: {
                _value: 'none',
            },
        },
        searchIcon: {
            flex: {
                basis: '1%',
            },
            margin: {
                _value: '0 10px',
            },
            min: {
                width: '24px',
            },
        },
        searchPill: {
            flex: {
                basis: '1%',
            },
            height: '24px',
            margin: {
                _value: '0 10px',
            },
            padding: {
                _value: '2px 8px 0 8px',
            },
            text: {
                align: 'center',
            },
            border: {
                radius: '15px',
            },
            width: 'auto',
            whiteSpace: 'nowrap',
            children: {
                priorityWrapper: {
                    border: {
                        radius: '4px',
                    },
                    padding: {
                        _value: '8px 16px',
                    },
                    color: '#ffffff',
                },
                primaryWrapper: {
                    background: {
                        color: '#03a9f4',
                    },
                },
                secondaryWrapper: {
                    color: '#777777',
                    background: {
                        color: '#eeeeee',
                    },
                },
                tertiaryWrapper: {
                    color: '#cccccc',
                    background: {
                        color: 'none',
                    },
                },
                defaultWrapper: {
                    background: {
                        color: '#ffffff',
                    },
                    color: '#000000',
                },
            },
            overrides: [
                {
                    selector: 'span[data-selector="base-pill-value"]',
                    style: {
                        font: {
                            size: '14px',
                        },
                        lineHeight: '24px',
                        padding: {
                            _value: '0',
                        },
                    },
                },
            ],
        },
        resultBackground: {
            position: 'fixed',
            top: '0',
            right: '0',
            bottom: '0',
            left: '0',
            background: {
                color: 'rgba(0,0,0,0.0)',
            },
            zIndex: '2',
        },
        resultCard: {
            position: 'absolute',
            top: '47px',
            listStyle: {
                type: 'none',
            },
            padding: {
                _value: '0',
            },
            margin: {
                _value: '0',
            },
            width: '100%',
            background: {
                color: 'white',
            },
            border: {
                _value: '1px solid #ccc',
            },
            zIndex: '3',
        },
        resultGroup: {
            padding: {
                _value: '10px',
            },
            border: {
                bottom: {
                    _value: '1px solid #ccc',
                },
                top: {
                    _value: '1px solid #ccc',
                },
            },
            selectors: {
                ':first-of-type': {
                    border: {
                        top: {
                            _value: 'none',
                        },
                    },
                },
            },
            background: {
                color: '#F5F6FB',
            },
            font: {
                size: '10px',
                weight: 'bold',
            },
        },
        resultGroupWrapper: {
            listStyle: {
                type: 'none',
            },
            margin: {
                _value: '8px',
            },
            padding: {
                _value: '0',
            },
            display: 'flex',
        },
        resultGroupElement: {
            background: {
                color: '#F5F6FB',
            },
            flex: {
                basis: '1%',
            },
            height: '20px',
            margin: {
                _value: '0 5px',
            },
            padding: {
                _value: '4px 8px 0 8px',
            },
            text: {
                align: 'center',
            },
            border: {
                radius: '15px',
            },
            width: 'auto',
            whiteSpace: 'nowrap',
            font: {
                size: '12px',
            },
        },
        resultElement: {
            cursor: 'pointer',
            padding: {
                _value: '10px',
            },
            border: {
                bottom: {
                    _value: '1px solid #ccc',
                },
            },
            selectors: {
                ':nth-last-of-type': {
                    _parameters: ['1'],
                    border: {
                        _value: 'none',
                    },
                },
            },
            zIndex: '4',
        },
        selectedResultPill: {
            cursor: 'pointer',
            background: {
                color: '#F5F6FB',
            },
            flex: {
                basis: '1%',
            },
            height: '24px',
            margin: {
                _value: '0 5px',
            },
            padding: {
                _value: '4px 8px 0 8px',
            },
            text: {
                align: 'center',
            },
            border: {
                radius: '15px',
                style: 'none',
            },
            width: 'auto',
            whiteSpace: 'nowrap',
            font: {
                size: '12px',
            },
        },
        selectedResultGroup: {
            font: {
                weight: 'bold',
            },
            padding: {
                right: '4px',
            },
        },
        selectedResultValue: {
            padding: {
                right: '4px',
            },
        },
    },
};
