export const dateSelectionStyle = {
    children: {
        wrapper: {},
        startDate: {},
        endDate: {},
        calendarDateLabel: {
            display: 'flex',
            flex: {
                _value: '1 0 90px',
            },
            margin: {
                _value: 'auto 0',
            },
        },
        calendarDateWrapper: {
            width: '300px',
            display: 'flex',
            flex: {
                direction: 'row',
            },
            margin: {
                _value: '5px 0',
            },
        },
        calendarDateValue: {
            border: {
                _value: '1px solid #ccc',
                radius: '3px',
            },
            padding: {
                _value: '4px',
            },
            display: 'flex',
            height: 'fit-content',
        },
    },
};
