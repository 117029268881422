export const contextMenuStyle = {
    children: {
        wrapper: {
            overrides: [
                {
                    selector: '[data-selector="base-contextMenu-button"].base-contextMenu-button-with-icon',
                    style: {
                        min: {
                            width: '100px',
                        },
                        text: {
                            align: 'right',
                        },
                    },
                },
            ],
        },
        button: {
            border: {
                style: 'none',
            },
            outline: {
                style: 'none',
            },
            font: {
                size: '13px',
            },
            lineHeight: '34px',
            selectors: [
                {
                    ':focus': {
                        outline: {
                            _value: '-webkit-focus-ring-color auto 1px',
                        },
                    },
                },
            ],
        },
        dropdownContent: {
            position: 'relative',
            min: {
                width: '150px',
            },
            width: '200px',
        },
        dropdownItemWrapper: {
            text: {
                align: 'left',
            },
            margin: {
                top: '2px',
            },
            border: {
                color: '#cccccc',
                style: 'solid',
                width: 'thin',
                radius: '3px',
            },
            position: 'absolute',
            width: '200px',
            min: {
                width: '150px',
            },
            background: {
                color: 'white',
            },
        },
        dropdownItem: {
            display: 'flex',
            justifyContent: 'space-between',
            font: {
                size: '13px',
            },
            padding: {
                left: '5px',
                right: '5px',
            },
            lineHeight: '34px',
            selectors: {
                ':hover': {
                    background: {
                        color: '#0075BE',
                    },
                    color: '#FFF',
                },
            },
        },
        inlineItemWrapper: {
            text: {
                align: 'left',
            },
            margin: {
                top: '2px',
            },
            width: '200px',
            min: {
                width: '150px',
            },
            overrides: [
                {
                    selector: '[data-selector="base-contextMenu-dropdownItem"]',
                    style: {
                        selectors: {
                            ':hover': {
                                background: {
                                    color: '#0075BE',
                                },
                                color: '#FFF',
                            },
                        },
                    },
                },
            ],
        },
        inlineLabel: {
            font: {
                weight: 'bold',
            },
        },
        inlineDescription: {
            color: '#555',
            font: {
                weight: 'normal',
                size: '0.9em',
            },
        },
        styledLabel: {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
        },
        count: {
            text: {
                align: 'right',
            },
        },
        iconWrapper: {
            width: '40px',
            display: 'flex',
            justifyContent: 'flex-end',
            align: {
                items: 'center',
            },
        },
        icon: {
            height: '25px',
            verticalAlign: 'middle',
        },
        clearButton: {
            text: {
                align: 'left',
            },
            width: '100%',
            lineHeight: '30px',
            background: {
                color: 'transparent',
            },
            border: {
                collapse: 'collapse',
                color: 'transparent',
            },
            selectors: {
                ':hover': {
                    color: 'white',
                },
            },
        },
        clickLayer: {
            position: 'fixed',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
            background: {
                color: 'transparent',
            },
        },
        contextMenuPill: {
            flex: {
                basis: '1%',
            },
            height: '24px',
            margin: {
                _value: '0 10px',
            },
            padding: {
                _value: '2px 8px 0 8px',
            },
            text: {
                align: 'center',
            },
            border: {
                radius: '15px',
            },
            width: 'auto',
            whiteSpace: 'nowrap',
            conditionals: [
                {
                    conditional: {
                        conditions: [
                            {
                                field: 'priority',
                                operator: '=',
                                value: 'primary',
                            },
                        ],
                    },
                    style: {
                        background: {
                            color: '#03a9f4',
                        },
                    },
                },
                {
                    conditional: {
                        conditions: [
                            {
                                field: 'priority',
                                operator: '=',
                                value: 'secondary',
                            },
                        ],
                    },
                    style: {
                        color: '#777777',
                        background: {
                            color: '#eeeeee',
                        },
                    },
                },
                {
                    conditional: {
                        conditions: [
                            {
                                field: 'priority',
                                operator: '=',
                                value: 'tertiary',
                            },
                        ],
                    },
                    style: {
                        color: '#cccccc',
                        background: {
                            color: 'none',
                        },
                    },
                },
            ],
            overrides: [
                {
                    selector: 'span[data-selector="base-pill-value"]',
                    style: {
                        font: {
                            size: '14px',
                        },
                        lineHeight: '24px',
                        padding: {
                            _value: '0',
                        },
                    },
                },
            ],
        },
    },
};
