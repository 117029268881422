import { accessibilityStyle } from './Accessibility/styles/Accessibility.style.js';
import { accordionStyle } from './Accordion/styles/Accordion.style.js';
import { accordionPanelStyle } from './AccordionPanel/styles/AccordionPanel.style.js';
import { actionsStyle } from './Actions/styles/Actions.style.js';
import { applicationStyle } from './Application/styles/Application.style.js';
import { applicationEmbedStyle } from './ApplicationEmbed/styles/ApplicationEmbed.style.js';
import { asOfDateStyle } from './AsOfDate/styles/AsOfDate.style.js';
import { assetStyle } from './Asset/styles/Asset.style.js';
import { badgeStyle } from './Badge/styles/Badge.style.js';
import { buttonStyle } from './Button/styles/Button.style.js';
import { buttonFlatStyle } from './ButtonFlat/styles/ButtonFlat.style.js';
import { buttonRaisedStyle } from './ButtonRaised/styles/ButtonRaised.style.js';
import { calendarInputStyle } from './CalendarInput/styles/CalendarInput.style.js';
import { cardStyle } from './Card/styles/Card.style.js';
import { carouselStyle } from './Carousel/styles/Carousel.style.js';
import { checkboxStyle } from './Checkbox/styles/Checkbox.style.js';
import { clickLayerStyle } from './ClickLayer/styles/ClickLayer.style.js';
import { colorStyle } from './Color/styles/Color.style.js';
import { confirmDialogStyle } from './ConfirmDialog/styles/ConfirmDialog.style.js';
import { contextMenuStyle } from './ContextMenu/styles/ContextMenu.style.js';
import { CopyButtonStyle } from './CopyButton/styles/CopyButton.style.js';
import { dateDropdownStyle } from './DateDropdown/styles/DateDropdown.style.js';
import { datePickerStyle } from './DatePicker/styles/DatePicker.style.js';
import { dateRangeInputStyle } from './DateRangeInput/styles/DateRangeInput.style.js';
import { dateSelectionStyle } from './DateSelection/styles/DateSelection.style.js';
import { definitionListStyle } from './DefinitionList/styles/DefinitionList.style.js';
import { dialogStyle } from './Dialog/styles/Dialog.style.js';
import { dividerStyle } from './Divider/styles/Divider.style.js';
import { dropdownStyle } from './Dropdown/styles/Dropdown.style.js';
import { gridStyle } from './Grid/styles/Grid.style.js';
import { headingStyle } from './Heading/styles/Heading.style.js';
import { iconStyle } from './Icon/styles/Icon.style.js';
import { iconInputStyle } from './IconInput/styles/IconInput.style.js';
import { imageStyle } from './Image/styles/Image.style.js';
import { imagePickerStyle } from './ImagePicker/styles/ImagePicker.style.js';
import { inputStyle } from './Input/styles/Input.style.js';
import { labelStyle } from './Label/styles/Label.style.js';
import { labelledInputStyle } from './LabelledInput/styles/LabelledInput.style.js';
import { legendStyle } from './Legend/styles/Legend.style.js';
import { linearProgressBarStyle } from './LinearProgressBar/styles/LinearProgressBar.style.js';
import { linkStyle } from './Link/styles/Link.style.js';
import { listStyle } from './List/styles/List.style.js';
import { loadingIndicatorStyle } from './LoadingIndicator/styles/LoadingIndicator.style.js';
import { menuStyle } from './Menu/styles/Menu.style.js';
import { menuItemStyle } from './MenuItem/styles/MenuItem.style.js';
import { menuListStyle } from './MenuList/styles/MenuList.style.js';
import { modalStyle } from './Modal/styles/Modal.style.js';
import { morningStarStyle } from './MorningStar/styles/MorningStar.style.js';
import { morningstarStyleBoxStyle } from './MorningstarStyleBox/styles/MorningstarStyleBox.style.js';
import { noDataPlaceholderStyle } from './NoDataPlaceholder/styles/NoDataPlaceholder.style.js';
import { numberPickerStyle } from './NumberPicker/styles/NumberPicker.style.js';
import { pagingStyle } from './Paging/styles/Paging.style.js';
import { pillStyle } from './Pill/styles/Pill.style.js';
import { progressIndicatorStyle } from './ProgressIndicator/styles/ProgressIndicator.style.js';
import { radioButtonsStyle } from './RadioButtons/styles/RadioButtons.style.js';
import { redirectButtonStyle } from './RedirectButton/styles/RedirectButton.style.js';
import { savedSearchStyle } from './SavedSearch/styles/SavedSearch.style.js';
import { savedSearchListStyle } from './SavedSearchList/styles/SavedSearchList.style.js';
import { scaleStyle } from './Scale/styles/Scale.style.js';
import { scaleLegendStyle } from './ScaleLegend/styles/ScaleLegend.style.js';
import { searchStyle } from './Search/styles/Search.style.js';
import { sortButtonStyle } from './SortButton/styles/SortButton.style.js';
import { superscriptStyle } from './Superscript/styles/Superscript.style.js';
import { tabStyle } from './Tab/styles/Tab.style.js';
import { tableStyle } from './Table/styles/Table.style.js';
import { tableGroupingStyle } from './TableGrouping/styles/TableGrouping.style.js';
import { tabsStyle } from './Tabs/styles/Tabs.style.js';
import { textStyle } from './Text/styles/Text.style.js';
import { themeProviderStyle } from './ThemeProvider/styles/ThemeProvider.style.js';
import { toggleButtonStyle } from './ToggleButton/styles/ToggleButton.style.js';
import { tooltipStyle } from './Tooltip/styles/Tooltip.style.js';
import { translateStyle } from './Translate/styles/Translate.style.js';
import { virtualizedListStyle } from './VirtualizedList/styles/VirtualizedList.style.js';
import { visuallyHiddenStyle } from './VisuallyHidden/styles/VisuallyHidden.style.js';
import { weightingBarStyle } from './WeightingBar/styles/WeightingBar.style.js';
/* [Component: import] */
/* [ChartComponent: import] */
export const baseTheme = {
    application: applicationStyle,
    button: buttonStyle,
    card: cardStyle,
    icon: iconStyle,
    text: textStyle,
    translate: translateStyle,
    carousel: carouselStyle,
    grid: gridStyle,
    actions: actionsStyle,
    list: listStyle,
    image: imageStyle,
    checkbox: checkboxStyle,
    asset: assetStyle,
    link: linkStyle,
    badge: badgeStyle,
    contextMenu: contextMenuStyle,
    tabs: tabsStyle,
    tab: tabStyle,
    table: tableStyle,
    asOfDate: asOfDateStyle,
    tooltip: tooltipStyle,
    heading: headingStyle,
    definitionList: definitionListStyle,
    search: searchStyle,
    input: inputStyle,
    pill: pillStyle,
    progressIndicator: progressIndicatorStyle,
    dropdown: dropdownStyle,
    accordion: accordionStyle,
    accordionPanel: accordionPanelStyle,
    redirectButton: redirectButtonStyle,
    dateSelection: dateSelectionStyle,
    applicationEmbed: applicationEmbedStyle,
    color: colorStyle,
    morningStar: morningStarStyle,
    iconInput: iconInputStyle,
    buttonRaised: buttonRaisedStyle,
    buttonFlat: buttonFlatStyle,
    imagePicker: imagePickerStyle,
    loadingIndicator: loadingIndicatorStyle,
    labelledInput: labelledInputStyle,
    label: labelStyle,
    noDataPlaceholder: noDataPlaceholderStyle,
    confirmDialog: confirmDialogStyle,
    dialog: dialogStyle,
    paging: pagingStyle,
    radioButtons: radioButtonsStyle,
    dateDropdown: dateDropdownStyle,
    clickLayer: clickLayerStyle,
    calendarInput: calendarInputStyle,
    dateRangeInput: dateRangeInputStyle,
    numberPicker: numberPickerStyle,
    toggleButton: toggleButtonStyle,
    accessibility: accessibilityStyle,
    visuallyHidden: visuallyHiddenStyle,
    sortButton: sortButtonStyle,
    savedSearch: savedSearchStyle,
    savedSearchList: savedSearchListStyle,
    scale: scaleStyle,
    themeProvider: themeProviderStyle,
    scaleLegend: scaleLegendStyle,
    weightingBar: weightingBarStyle,
    legend: legendStyle,
    virtualizedList: virtualizedListStyle,
    tableGrouping: tableGroupingStyle,
    CopyButton: CopyButtonStyle,
    linearProgressBar: linearProgressBarStyle,
    morningstarStyleBox: morningstarStyleBoxStyle,
    superscript: superscriptStyle,
    modal: modalStyle,
    datePicker: datePickerStyle,
    menu: menuStyle,
    menuList: menuListStyle,
    menuItem: menuItemStyle,
    divider: dividerStyle,
    /* [Component: interface] */
    /* [ChartComponent: interface] */
};
