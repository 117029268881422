export const menuStyle = {
    background: {
        color: '#ffffff',
    },
    display: 'inline-block',
    border: {
        _value: '1px solid #efefef',
    },
    box: {
        shadow: '0 2px 10px -1px #cfcfcf',
    },
    selectors: {
        '.ksys-mega-menu': {
            display: 'flex',
            flex: {
                direction: 'row',
            },
            justifyContent: 'space-between',
            padding: {
                _value: '15px 5px',
            },
        },
        '.ksys-mega-menu > ul': {
            display: 'inline-block',
            padding: {
                _value: '0 15px',
            },
        },
    }
};
