export const savedSearchListStyle = {
    children: {
        wrapper: {
            display: 'flex',
            flex: {
                direction: 'column',
            },
            overrides: [
                {
                    selector: '[data-selector="base-pill-wrapper"]',
                    style: {
                        border: {
                            radius: '40px',
                        },
                        padding: {
                            _value: '4px 7px',
                        },
                        font: {
                            size: '11px',
                        },
                        lineHeight: '14px',
                        text: {
                            align: 'right',
                        },
                    },
                },
                {
                    selector: '[data-selector="base-iconInput-wrapper"]',
                    style: {
                        width: '100%',
                        margin: {
                            _value: 'none',
                        },
                    },
                },
            ],
        },
        row: {
            display: 'flex',
            flex: {
                direction: 'row',
            },
            font: {
                size: '14px',
            },
            lineHeight: '18px',
            border: {
                bottom: {
                    _value: '1px solid #ccc',
                },
            },
            padding: {
                _value: '10px',
            },
        },
        savedSearchName: {
            flex: {
                _value: '1',
            },
        },
        notificationOn: {
            background: {
                color: '#E4F9EC',
            },
            color: '#00C761',
        },
        notificationOff: {
            background: {
                color: '#F5F6FB',
            },
            color: '#000000',
        },
    },
};
