import React, { Component } from 'react';
import { components } from '@kurtosys/ksys-app-components';
import { inject, observer } from 'mobx-react';

import { InjectedStyledComponent } from '../shared/InjectedStyledComponent.js';

import Wrapper from './styledComponents/Wrapper.js';
import { IHeaderProps, IHeaderState } from './models';

const Grid = components.base.Grid.Grid;

class RawHeader extends Component<IHeaderProps, IHeaderState> {
	static configurationKey = 'header' as const;
	static styleKey = 'header' as const;
	constructor(props: IHeaderProps) {
		super(props);
	}
	render() {
		const { className, headerStore, appStore } = this.props;
		if (!headerStore) {
			return null;
		}
		const { components, grid } = headerStore;
		if (appStore && appStore.rawConfiguration) {
			return (
				<Wrapper className={className}>
					<Grid grid={grid} components={components} />
				</Wrapper>
			);
		}
		return null;
	}
}

export const Header = inject('appStore', 'headerStore')(observer(RawHeader));
export default InjectedStyledComponent(Header, 'header');
