import React, { Fragment } from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import { common } from '@kurtosys/ksys-app-template';
import { action, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { ComponentBase } from '../../../shared/ComponentBase.js';
import { InjectedStyledComponent } from '../../../shared/InjectedStyledComponent.js';
import { KEYS } from '../../../shared/KeyboardKeys.js';
import Translate from '../Translate/Translate.js';
import { TranslationContext } from '../Translate/Translate.js';
import ArrowIcon from './styledComponents/ArrowIcon.js';
import ClickLayer from './styledComponents/ClickLayer.js';
import TooltipCharacter from './styledComponents/TooltipCharacter.js';
import TooltipChildrenButton from './styledComponents/TooltipChildrenButton.js';
import TooltipContent from './styledComponents/TooltipContent.js';
import TooltipIcon from './styledComponents/TooltipIcon.js';
import TooltipIconButton from './styledComponents/TooltipIconButton.js';
import Wrapper from './styledComponents/Wrapper.js';
import { TOOLTIP_CATEGORY_KEY, TOOLTIP_COMPONENT_KEY } from './Tooltip.meta.js';
export class Tooltip extends ComponentBase {
    onKeyDown(evt) {
        if (evt.key === KEYS.ESCAPE) {
            this.hide();
        }
    }
    constructor(props) {
        super(props);
        this.categoryKey = TOOLTIP_CATEGORY_KEY;
        this.componentKey = TOOLTIP_COMPONENT_KEY;
        this.shown = false;
        this.show = () => {
            this.shown = true;
        };
        this.hide = () => {
            this.shown = false;
        };
        this.onClick = (e) => {
            const { preserveClickAction = false, trigger = 'click' } = this.props;
            if (!preserveClickAction) {
                e.stopPropagation();
            }
            if (trigger === 'click') {
                this.show();
            }
        };
        this.onMouseEnter = () => {
            const { trigger = 'click' } = this.props;
            if (trigger === 'hover') {
                this.show();
            }
        };
        this.onMouseLeave = () => {
            const { trigger = 'click' } = this.props;
            if (trigger === 'hover') {
                this.hide();
            }
        };
        this.onBlur = () => {
            this.hide();
        };
        makeObservable(this, {
            shown: observable,
            show: action,
            hide: action,
            onClick: action,
            onMouseEnter: action,
            onMouseLeave: action,
            onKeyDown: action,
            onBlur: action,
            tooltipButtonContent: computed,
            tooltipContent: computed,
        });
    }
    get tooltipButtonContent() {
        const { character, iconProps, buttonAccessibleText, children } = this.props;
        if (!common.commonUtils.isNullOrEmpty(character)) {
            return React.createElement(TooltipCharacter, null, character);
        }
        const tooltipIconProps = iconProps || {
            asset: 'base.tooltip.defaultIcon',
        };
        return React.createElement(TooltipIcon, { ...tooltipIconProps });
    }
    get tooltipContent() {
        let { content = '' } = this.props;
        if (typeof content === 'string') {
            if (this.translationContext) {
                content = this.translationContext.translate(content);
            }
            return React.createElement("div", { dangerouslySetInnerHTML: { __html: content } });
        }
        return content;
    }
    getButton(ref) {
        const { children, buttonAccessibleText } = this.props;
        if (!ref) {
            return null;
        }
        /**
         * Tab index of 0 is to allow the element to receive focus for the keyDown event to be triggered
         * This have been tested on Chrome, Safari, Opera, Edge, and IE as well (just for completeness)
         */
        return (React.createElement("div", { ref: ref }, !common.commonUtils.isNullOrUndefined(children) ? (React.createElement(TooltipChildrenButton, { onClick: this.onClick, onMouseEnter: this.onMouseEnter, onMouseLeave: this.onMouseLeave, onKeyDown: (evt) => this.onKeyDown(evt), onBlur: this.onBlur, "aria-label": buttonAccessibleText, role: "button", tabIndex: 0 }, children)) : (React.createElement(TooltipIconButton, { onClick: this.onClick, onMouseEnter: this.onMouseEnter, onMouseLeave: this.onMouseLeave, onKeyDown: (evt) => this.onKeyDown(evt), onBlur: this.onBlur, "aria-label": buttonAccessibleText, role: "button", tabIndex: 0 }, this.tooltipButtonContent))));
    }
    getContent(ref, style, placement, arrowProps) {
        const { showTooltipArrow = true } = this.props;
        return (React.createElement("div", { "data-selector": "base-tooltip-tooltipContentWrapper", ref: ref, style: style, "data-placement": placement },
            React.createElement(TooltipContent, { "aria-live": "polite" },
                React.createElement(Translate, null, this.tooltipContent),
                showTooltipArrow && (React.createElement("div", { "data-selector": "base-tooltip-arrowIconWrapper", ref: arrowProps.ref, style: arrowProps.style, "aria-hidden": true },
                    React.createElement(ArrowIcon, null))))));
    }
    get hasContent() {
        const { content = '' } = this.props;
        return !common.commonUtils.isNullOrEmpty(content);
    }
    render() {
        const { className, position = 'right', trigger = 'click', fixedTooltip = false } = this.props;
        if (!this.hasContent) {
            return null;
        }
        return (React.createElement(Wrapper, { className: className },
            React.createElement(Manager, null,
                React.createElement(Reference, null, ({ ref }) => this.getButton(ref)),
                this.shown && (React.createElement(Fragment, null,
                    trigger === 'click' && React.createElement(ClickLayer, { onClick: this.hide }),
                    React.createElement(TranslationContext.Consumer, null, (translationContext) => {
                        this.translationContext = translationContext;
                        return null;
                    }),
                    React.createElement(Popper, { placement: position, strategy: fixedTooltip ? 'fixed' : 'absolute' }, ({ ref, style, placement, arrowProps }) => this.getContent(ref, style, placement, arrowProps)))))));
    }
}
export default InjectedStyledComponent(observer(Tooltip), TOOLTIP_CATEGORY_KEY, TOOLTIP_COMPONENT_KEY);
