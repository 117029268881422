import {stores } from '@kurtosys/app-start';
import { components } from '@kurtosys/ksys-app-components';
import { common, query } from '@kurtosys/ksys-app-template';
import { IKeyValuePair } from '@kurtosys/types/common/IKeyValuePair.js';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { IComponentStyles } from '../../../models/app/IComponentStyles.js';
import { IConfiguration } from '../../../models/app/IConfiguration.js';
import { ISelectionFieldBase } from '../../../models/app/ISelectionFieldBase.js';
import { ISelectionFieldOption } from '../../../models/app/ISelectionFieldOption.js';
import { ISelectionOption } from '../../../models/app/ISelectionOption.js';
import { TStoreContext } from '../../../models/app/TStoreContext.js';
import { AppStore } from '../../App/stores/AppStore.js';
import { SelectionFieldStore } from '../../SelectionField/stores/SelectionFieldStore.js';
import { ISelectionConfiguration } from '../models';
import { IGeolocation } from '../models/IGeolocation.js';
import { ISelectionValues } from '../models/ISelectionValues.js';
import { TSelectionMode } from '../models/TSelectionMode.js';

type ITextProps = components.base.Text.models.ITextProps;
type TranslationStore = stores.TranslationStore<IConfiguration, IComponentStyles>;
type QueryStore = stores.QueryStore<IConfiguration, IComponentStyles>;
import { IManifest } from '@kurtosys/types/appsManager/index.js';

const { sortBy, isNullOrUndefined, deepMergeObjects, isUndefined } = common.commonUtils;

export class SelectionStore extends stores.base.StoreBase<IConfiguration, IComponentStyles> {
	@observable.ref values: ISelectionValues = {};
	static componentKey = 'selection' as const;

	constructor(storeContext: TStoreContext, manifest: IManifest) {
		super(storeContext, manifest);
		makeObservable(this);
	}

	@computed
	get rawValues(): ISelectionValues {
		return this.values;
	}

	@computed
	get appStore(): AppStore {
		return this.storeContext.get<AppStore>('appStore');
	}

	@computed
	get translationStore(): TranslationStore {
		return this.storeContext.get<TranslationStore>('translationStore');
	}

	@computed
	get queryStore(): QueryStore {
		return this.storeContext.get<QueryStore>('queryStore');
	}

	@computed
	get selectionFieldStore(): SelectionFieldStore {
		return this.storeContext.get<SelectionFieldStore>('selectionFieldStore');
	}

	@action
	async initialize(): Promise<void> {
		return;
	}

	getConditionalValueFieldValue = (field: ISelectionFieldBase, values?: any, explicitDefaultValue?: string) => {
		const { options, defaultValue } = field;
		let response: any = defaultValue;
		let selectedOption: ISelectionFieldOption | undefined;
		if (options) {
			for (const option of options) {
				const { conditional } = option;
				if (conditional) {
					const conditionalHelper = new common.helpers.ConditionalHelper(
						{ queryClass: query.Query },
						conditional,
					);
					if (
						conditionalHelper.matchesWithOptions({
							instance: values,
							executionOptions: this.queryStore.executionOptions,
						})
					) {
						selectedOption = option;
						break;
					}
				} else {
					selectedOption = option;
					break;
				}
			}
		}
		if (selectedOption) {
			const { value, allowedExplicitDefaultValues } = selectedOption;
			response = value;
			if (response === '{useExplicitDefaultValue}') {
				if (
					!isUndefined(explicitDefaultValue) &&
					(!allowedExplicitDefaultValues || allowedExplicitDefaultValues.includes(explicitDefaultValue))
				) {
					response = explicitDefaultValue;
				} else {
					response = defaultValue;
				}
			}
		}
		return response;
	};

	getSelectedFieldOption = (
		field: ISelectionFieldBase,
		options?: ISelectionOption[],
		values?: any,
	): ISelectionOption => {
		const { key, type, defaultValue } = field;
		const response: ISelectionOption = { key, category: undefined, value: undefined };
		const inputs = this.appStore.appParamsHelper.inputs;
		if (!values) {
			values = this.values;
		}

		if (type === 'EMBEDDED_INPUT') {
			const inputValue = inputs && inputs[key];
			response.value = inputValue || defaultValue;
			return response;
		}
		if (type === 'CONDITIONAL_VALUE') {
			const explicitDefaultValue = inputs && inputs[key];
			response.value = this.getConditionalValueFieldValue(field, values, explicitDefaultValue);
			return response;
		}

		const value = values[key];
		const option = this.getOption(field, value, values, options);
		if (option) {
			response.value = value;
			response.category = option.category;
			return response;
		}
		return response;
	};

	getLabel = (field: ISelectionFieldBase, options?: IKeyValuePair[], values?: any) => {
		if (!options) {
			options = this.getOptions(field, values);
		}
		if (!values) {
			values = this.values;
		}
		const { key } = field;
		const value = values[key];
		const option = this.findValueInOptions(value, options);
		if (option) {
			return option.key;
		}
		return value;
	};

	@action
	setValue = (field: ISelectionFieldBase, value: any, options?: IKeyValuePair[]) => {
		const { key, onChange } = field;
		const option = this.getOption(field, value);
		const labelKey = `${key}_label`;
		const categoryKey = `${key}_category`;
		const values = Object.assign(
			{},
			{
				...this.values,
				[key]: value,
				[labelKey]: option && option.key,
				[categoryKey]: option && option.category,
			},
		);
		const cleanValues = this.cleanValues(values, options);
		runInAction(() => {
			this.values = cleanValues;
		});
		if (onChange) {
			const fieldResponse = this.getSelectedFieldOption(field);
			this.selectionFieldStore.handleFieldOnChange(field, fieldResponse.value);
		}
	};

	@computed
	get hasAllSelections() {
		const values = this.rawValues;
		const fields = this.fields;
		let hasAllSelections = true;
		for (const field of fields) {
			const { key } = field;
			const value = values[key];
			if (isNullOrUndefined(value)) {
				hasAllSelections = false;
				break;
			}
		}
		return hasAllSelections;
	}

	cleanValues = (values: any, options?: IKeyValuePair[]) => {
		let hasChange = true;
		const fields = this.fields;
		while (hasChange) {
			hasChange = false;
			let mustClear = false;
			for (const field of fields) {
				if (this.mode === 'default') {
					mustClear = false;
				}
				const { key } = field;
				const fieldResponse = this.getSelectedFieldOption(field, options, values);
				let value = fieldResponse.value;
				if (fieldResponse.category) {
					values[`${key}_category`] = fieldResponse.category;
				}
				if (!mustClear && isNullOrUndefined(value)) {
					mustClear = true;
				}
				if (mustClear) {
					value = undefined;
				}
				if (value && key.endsWith('_label')) {
					value = this.translationStore.translate(value);
				}
				if (value !== values[key]) {
					values[key] = value;
					hasChange = true;
				}
			}
		}
		return values;
	};

	getOption = (
		field: ISelectionFieldBase,
		value: any,
		values?: any,
		options?: ISelectionOption[],
	): ISelectionOption | undefined => {
		const { key, type, defaultValue } = field;
		if (type === 'EMBEDDED_INPUT') {
			return {
				key,
				value: value || defaultValue,
			} as IKeyValuePair;
		}
		if (!options) {
			options = this.getOptions(field, values);
		}
		// alt values here
		const option = this.findValueInOptions(value, options);
		return option;
	};

	findValueInOptions = (value: any, options: ISelectionOption[]): ISelectionOption | undefined => {
		const translate = this.appStore.getTranslateFunction();
		const val = options.find((option) => {
			return option.value === value || translate(option.value) === value;
		});
		return val;
	};

	getOptions = (field: ISelectionFieldBase, values?: any): ISelectionOption[] => {
		if (!values) {
			values = this.rawValues;
		}
		const options = (field.options || [])
			.filter((option) => {
				const { conditional } = option;
				let response = true;
				if (conditional) {
					const conditionalHelper = new common.helpers.ConditionalHelper(
						{ queryClass: query.Query },
						conditional,
					);

					response = conditionalHelper.matchesWithOptions({
						instance: values,
						executionOptions: this.queryStore.executionOptions,
					});
				}
				return response;
			})
			.map((option) => {
				const { label, value, icon, category, description, descriptionTextProps } = option;
				const response: ISelectionOption = {
					value,
					category,
					descriptionTextProps,
					icon: icon && icon.src,
					key: label,
					description: description && this.queryStore.query(description),
				};
				return response;
			});
		return options;
	};

	@computed
	get fieldSelectionLabels(): Record<string, string> {
		const fields = this.fields;
		const selectedFieldLabels: Record<string, any> = {};
		fields &&
			fields.forEach((field) => {
				const val = this.getSelectedFieldOption(field);
				if (val) {
					const key = val.key;
					const value = val.value;
					const options = field.options;
					const label = this.getSelectedFieldLabel(key, value);
					selectedFieldLabels[key] = label && this.translationStore.translate(label);
				}
			});
		return selectedFieldLabels;
	}

	getSelectedFieldLabel = (fieldKey: string, optionValue: any): string | undefined => {
		const field = this.fields.find((field) => field.key === fieldKey);
		if (field) {
			const options = field.options;
			const selectedOption = options && options.find((option) => option.value === optionValue);
			if (selectedOption) {
				return selectedOption.label;
			}
		}
	};

	getDescriptionTextProps(option: ISelectionOption, field: ISelectionFieldBase): ITextProps | undefined {
		const { description, descriptionTextProps = {} } = option;
		if (description) {
			const appDescriptionTextProps =
				(this.appStore.appComponentConfiguration &&
					this.appStore.appComponentConfiguration.descriptionTextProps) ||
				{};
			const fieldDescriptionTextProps = (field && field.descriptionTextProps) || {};
			const response: ITextProps = {
				value: description,
			};
			return deepMergeObjects(appDescriptionTextProps, fieldDescriptionTextProps, descriptionTextProps, response);
		}
		return undefined;
	}

	getPlaceholderText(field: ISelectionFieldBase) {
		const text = field.placeholderText || (this.configuration && this.configuration.placeholderText) || '';
		if (this.translationStore) {
			return this.translationStore.translate(text);
		}
		return text;
	}
	@computed
	get configuration(): ISelectionConfiguration | undefined {
		if (this.appStore) {
			return this.appStore.getComponentConfiguration(SelectionStore.componentKey);
		}
	}

	@computed
	get geolocationConfig(): IGeolocation {
		const geolocation = this.configuration && this.configuration.geolocation;
		const defaultGeolocation: IGeolocation = {
			enabled: false,
			cascadingFieldValues: [],
		};
		return { ...defaultGeolocation, ...geolocation };
	}

	@observable.ref rawFields: ISelectionFieldBase[] | undefined;
	@computed
	get fields(): ISelectionFieldBase[] {
		if (this.rawFields) {
			return this.rawFields;
		}
		let response: ISelectionFieldBase[] = [];
		if (this.configuration && this.configuration.fields) {
			response = this.configuration.fields;
		}
		response = sortBy(response, (field) => (field.order || 99999).toString());
		return response;
	}
	set fields(value: ISelectionFieldBase[]) {
		this.rawFields = value;
	}
	@computed
	get mode(): TSelectionMode {
		return (this.configuration && this.configuration.mode) || 'default';
	}
}
