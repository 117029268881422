export const tabsStyle = {
    children: {
        wrapper: {
            overrides: [
                {
                    selector: '[data-selector="base-tabs-pane"]:focus',
                    style: {
                        outline: {
                            style: 'solid',
                            width: '3px',
                        },
                    },
                },
            ],
        },
        header: {
            display: 'flex',
            border: {
                bottom: {
                    color: '#cccccc',
                    style: 'solid',
                    width: '1px',
                },
            },
            margin: {
                bottom: '10px',
            },
            overrides: [
                {
                    selector: '[role="tab"]:focus,[role="tab"]:hover',
                    style: {
                        color: 'red',
                        border: {
                            bottom: {
                                color: 'red',
                                style: 'solid',
                            },
                        },
                    },
                },
            ],
        },
        tabHeader: {
            padding: {
                right: '20px',
            },
            cursor: 'pointer',
            flex: {
                _value: '1',
            },
            position: 'relative',
        },
        activeTabHeader: {
            font: {
                weight: 'bold',
            },
            color: '#1EA7FD',
            border: {
                bottom: {
                    color: '#1EA7FD',
                    style: 'solid',
                },
            },
        },
        tabHeaderIcon: {
            width: '16px',
            height: '16px',
            margin: {
                right: '5px',
                left: '5px',
            },
            verticalAlign: 'text-top',
        },
        dropdown: {
            width: '100%',
            overrides: [
                {
                    selector: '[data-selector="base-dropdown-header"]',
                    style: {
                        width: '100%',
                    },
                },
            ],
        },
    },
};
