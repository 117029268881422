/* eslint-disable @typescript-eslint/naming-convention */
export var KEYS;
(function (KEYS) {
    KEYS["UP_ARROW"] = "ArrowUp";
    KEYS["DOWN_ARROW"] = "ArrowDown";
    KEYS["LEFT_ARROW"] = "ArrowLeft";
    KEYS["RIGHT_ARROW"] = "ArrowRight";
    KEYS["PAGE_UP"] = "PageUp";
    KEYS["PAGE_DOWN"] = "PageDown";
    KEYS["ENTER"] = "Enter";
    KEYS["SPACE"] = " ";
    KEYS["SPACEBAR"] = "Space";
    KEYS["ESCAPE"] = "Escape";
    KEYS["TAB"] = "Tab";
    KEYS["HOME"] = "Home";
    KEYS["END"] = "End";
})(KEYS || (KEYS = {}));
