import DocumentIcon from '../../assets/images/DocumentIcon.svg';
import Spinner from '../../assets/images/Spinner.svg';
/* [AddAsset: import] */
export const fileAssets = {
    documentTile: {
        icon: DocumentIcon,
        spinner: Spinner,
        /* [AddAsset: documentTile] */
    },
    /* [InitComponentAsset: assetKey] */
};
