export const barChartStyle = {
    font: {
        family: 'Arial',
        size: '11px',
    },
    color: '#001928',
    overrides: [
        {
            selector: 'div.recharts-wrapper > svg > g.recharts-layer.recharts-cartesian-axis.recharts-yAxis.yAxis > g text',
            style: {
                fill: '#001928',
            },
        },
        {
            selector: 'div.recharts-wrapper > div > ul > li.recharts-legend-item > svg',
            style: {
                margin: {
                    right: '12px !important',
                },
                width: '12px',
                height: '12px',
            },
        },
    ],
};
