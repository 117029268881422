export const fundManagerStyle = {
    children: {
        wrapper: {
            width: '150px',
            text: {
                align: 'center',
            },
        },
        name: {
            text: {
                align: 'center',
            },
        },
        title: {
            text: {
                align: 'center',
            },
            font: {
                size: '14px',
            },
            color: '#888888',
        },
        actionsWrapper: {
            overrides: [
                {
                    selector: '[data-selector="base-actions-wrapper"]',
                    style: {
                        justifyContent: 'center',
                    },
                },
            ],
        },
        imageWrapper: {
            height: '100px',
            overflow: {
                y: 'hidden',
            },
            padding: {
                bottom: '5px',
            },
            margin: {
                bottom: '5px',
            },
        },
        fundManagerImage: {
            width: '100px',
            height: '100px',
            border: {
                radius: '50%',
                color: '#ccc',
                width: '2px',
                style: 'solid',
            },
        },
    },
    overrides: [
        {
            selector: '[data-selector="base-image"]',
            style: {
                width: '100px',
                height: '100px',
                border: {
                    radius: '50%',
                },
            },
        },
    ],
};
