export const legendStyle = {
    children: {
        wrapper: {
            border: {
                _value: '1px solid #cfcfcf',
            },
            width: '25%',
            margin: {
                _value: '10px auto',
            },
            padding: {
                _value: '5px',
            },
        },
        list: {
            listStyle: {
                type: 'none',
            },
            margin: {
                _value: '0',
            },
            padding: {
                _value: '0',
            },
            display: 'flex',
            justifyContent: 'space-around',
        },
        item: {
            display: 'flex',
            justifyContent: 'flex-start',
        },
        itemColor: {
            overrides: [
                {
                    selector: '[data-selector="base-color-wrapper"]',
                    style: {
                        width: '12px',
                        height: '12px',
                        border: {
                            _value: '3px solid black',
                            radius: '10px',
                        },
                    },
                },
            ],
        },
        itemLabel: {
            font: {
                weight: 'bold',
            },
            margin: {
                left: '5px',
            },
        },
        itemValue: {
            margin: {
                left: '5px',
            },
        },
    },
};
