export const linearProgressBarStyle = {
    children: {
        bar: {
            width: '400px',
            height: '15px',
            border: {
                radius: '10px',
            },
        },
    },
};
