import * as React from 'react';
import { getSelector } from './selector.js';
import { stripBaseProps } from './stripBaseProps.js';
export class ComponentBase extends React.Component {
    constructor(props) {
        super(props);
    }
    get parentComponentKey() {
        return undefined;
    }
    get configuration() {
        const { configuration } = this.props;
        if (configuration) {
            const category = configuration[this.categoryKey];
            const parentComponentKey = this.parentComponentKey;
            if (parentComponentKey) {
                const parentConfiguration = (category && category[parentComponentKey]);
                if (parentConfiguration && parentConfiguration.childComponents) {
                    return parentConfiguration.childComponents[this.componentKey];
                }
            }
            else {
                return (category && category[this.componentKey]);
            }
        }
        return;
    }
    get globalConfiguration() {
        const { configuration } = this.props;
        if (configuration) {
            return configuration.global;
        }
    }
    get selector() {
        return getSelector(this.categoryKey, this.componentKey);
    }
    get componentProps() {
        return stripBaseProps(this.props);
    }
    appendClassNames(additionalClassNames) {
        const className = this.props.className || '';
        if (!additionalClassNames || (Array.isArray(additionalClassNames) && additionalClassNames.length <= 0)) {
            return className;
        }
        if (typeof additionalClassNames === 'string') {
            additionalClassNames = [additionalClassNames];
        }
        return `${className} ${additionalClassNames.map((name) => `ksys-${name}`).join(' ')}`;
    }
}
