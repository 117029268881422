export const errorMessageStyle = {
    text: {
        align: 'center',
    },
    box: {
        sizing: 'inherit',
    },
    font: {
        family: '"DM Sans", sans-serif',
        weight: '400',
        size: '13px',
    },
    color: 'rgb(255, 116, 73)',
    margin: {
        _value: '0px',
    },
};
