import {stores } from '@kurtosys/app-start';
import { ITextProps } from '@kurtosys/ksys-app-components/dist/components/base/Text/models/ITextProps';
import { common } from '@kurtosys/ksys-app-template';
import { IManifest } from '@kurtosys/types/appsManager/index.js';
import { action, computed, makeObservable, observable } from 'mobx';

import { IComponentStyles } from '../../../models/app/IComponentStyles.js';
import { IConfiguration } from '../../../models/app/IConfiguration.js';
import { TStoreContext } from '../../../models/app/TStoreContext.js';
import { AppStore } from '../../App/stores/AppStore.js';
import { DisclaimerStore } from '../../Disclaimer/stores/DisclaimerStore.js';
import { IFootnoteConfiguration } from '../models';

const { isNullOrUndefined } = common.commonUtils;

export class FootnoteStore extends stores.base.StoreBase<IConfiguration, IComponentStyles> {
	static componentKey = 'footnote' as const;
	@observable.ref disclaimer = '';

	constructor(storeContext: TStoreContext, manifest: IManifest) {
		super(storeContext, manifest);
		makeObservable(this);
	}

	@computed
	get appStore(): AppStore {
		return this.storeContext.get<AppStore>('appStore');
	}

	@computed
	get disclaimerStore(): DisclaimerStore {
		return this.storeContext.get<DisclaimerStore>('disclaimerStore');
	}

	@computed
	get configuration(): IFootnoteConfiguration | undefined {
		if (this.appStore) {
			return this.appStore.getComponentConfiguration(FootnoteStore.componentKey);
		}
	}

	@action
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	async initialize(): Promise<void> {}

	@computed
	get text(): string {
		const text = this.configuration && this.configuration.text;
		if (!isNullOrUndefined(text) && typeof text !== 'string') {
			return this.disclaimerStore.footnoteDisclaimer;
		}
		return text || '';
	}

	@computed
	get textProps(): ITextProps | undefined {
		const textProps = this.configuration && this.configuration.textProps;
		return {
			...textProps,
			value: this.text,
		};
	}

	@computed
	get disclaimerType(): string | undefined {
		if (this.configuration && this.configuration.text && typeof this.configuration.text !== 'string') {
			return this.configuration.text.type;
		}
	}
}
