import React, { createContext, Fragment } from 'react';
import { observer } from 'mobx-react';
import { ComponentBase } from '../../../shared/index.js';
// eslint-disable-next-line @typescript-eslint/naming-convention
export const TranslationContext = createContext({
    translate: (key) => key,
    culture: 'Default',
});
import { TRANSLATE_CATEGORY_KEY, TRANSLATE_COMPONENT_KEY } from './Translate.meta.js';
export class Translate extends ComponentBase {
    constructor() {
        super(...arguments);
        this.categoryKey = TRANSLATE_CATEGORY_KEY;
        this.componentKey = TRANSLATE_COMPONENT_KEY;
        this.context = null;
    }
    get translation() {
        const { children, placeholders } = this.props;
        if (typeof children !== 'string' || !this.context || !this.context.translate) {
            return children;
        }
        return this.context.translate(children, placeholders);
    }
    render() {
        return React.createElement(Fragment, null, this.translation);
    }
}
Translate.contextType = TranslationContext;
export default observer(Translate);
