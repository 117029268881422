export const pagingStyle = {
    children: {
        wrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            font: { family: '"DM Sans", sans-serif' },
            background: {
                color: '#f3f4f8',
            },
            border: {
                width: '1px',
                style: 'solid',
                _value: '#d6d9e1',
            },
            overrides: [
                {
                    selector: '[data-selector="base-buttonFlat"]',
                    style: {
                        font: {
                            size: '16px',
                        },
                    },
                },
            ],
        },
        pageBackwards: {
            width: '90px',
        },
        pageForwards: {
            width: '70px',
        },
        pagingResultsStatus: {
            padding: {
                top: '12px',
            },
        },
        pageSelectorWrapper: {
            display: 'flex',
            flex: {
                direction: 'row',
            },
        },
        pageSelectorPrefix: {
            padding: {
                _value: '12px',
            },
        },
        pageSelectorSuffix: {
            padding: {
                _value: '12px',
            },
        },
        pageSizeWrapper: {
            display: 'flex',
            flex: {
                direction: 'row',
            },
        },
        pageSizeLabel: {
            padding: {
                _value: '12px',
            },
        },
        pageNavigation: {
            display: 'flex',
        },
    },
};
