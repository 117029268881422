import { baseTheme } from '../components/base/baseTheme.js';
import { chartingTheme } from '../components/charting/chartingTheme.js';
import { factTheme } from '../components/fact/factTheme.js';
import { fileTheme } from '../components/file/fileTheme.js';
import { managementTheme } from '../components/management/managementTheme.js';
import { messageTheme } from '../components/message/messageTheme.js';
import { overviewTheme } from '../components/overview/overviewTheme.js';
import { portalTheme } from '../components/portal/portalTheme.js';
import { riskTheme } from '../components/risk/riskTheme.js';
import { svgTheme } from '../components/svg/svgTheme.js';
export const theme = {
    base: baseTheme,
    overview: overviewTheme,
    management: managementTheme,
    charting: chartingTheme,
    file: fileTheme,
    risk: riskTheme,
    fact: factTheme,
    message: messageTheme,
    portal: portalTheme,
    svg: svgTheme,
    /* [Category: interface] */
};
