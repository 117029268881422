import { baseAssets } from '../components/base/baseAssets.js';
import { chartingAssets } from '../components/charting/chartingAssets.js';
import { fileAssets } from '../components/file/fileAssets.js';
import { managementAssets } from '../components/management/managementAssets.js';
import { overviewAssets } from '../components/overview/overviewAssets.js';
export const assets = {
    baseUrl: '',
    base: baseAssets,
    charting: chartingAssets,
    file: fileAssets,
    management: managementAssets,
    overview: overviewAssets,
};
