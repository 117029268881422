import AccordionCollapseIcon from '../../assets/images/AccordionCollapseIcon.svg';
import AccordionExpandIcon from '../../assets/images/AccordionExpandIcon.svg';
import BackArrowIcon from '../../assets/images/BackArrowIcon.svg';
import Calendar from '../../assets/images/Calendar.svg';
import CalendarPlaceholder from '../../assets/images/CalendarPlaceholder.svg';
import CaretDown from '../../assets/images/CaretDown.svg';
import CaretUp from '../../assets/images/CaretUp.svg';
import CarouselNavArrowLeftIcon from '../../assets/images/CarouselNavArrowLeftIcon.svg';
import CarouselNavArrowRightIcon from '../../assets/images/CarouselNavArrowRightIcon.svg';
import CheckboxHalfIcon from '../../assets/images/CheckboxHalfIcon.svg';
import CheckboxIcon from '../../assets/images/CheckboxIcon.svg';
import CheckboxMarkedIcon from '../../assets/images/CheckboxMarkedIcon.svg';
import Close from '../../assets/images/Close.svg';
import CopyUrlIcon from '../../assets/images/CopyLink.svg';
import Cross from '../../assets/images/Cross.svg';
import DeleteSavedSearch from '../../assets/images/DeleteSavedSearch.svg';
import DocumentLabelIcon from '../../assets/images/DocumentLabelIcon.svg';
import DownloadIcon from '../../assets/images/DownloadIcon.svg';
import DropdownChevronIcon from '../../assets/images/DropdownChevronIcon.svg';
import DropdownChevronIconExpanded from '../../assets/images/DropdownChevronIconExpanded.svg';
import FingerPrintIcon from '../../assets/images/FingerPrintIcon.svg';
import HomeIcon from '../../assets/images/HomeIcon.svg';
import InformationIcon from '../../assets/images/InformationIcon.svg';
import KurtosysLogo from '../../assets/images/KurtosysLogo.svg';
import LayoutIcon from '../../assets/images/LayoutIcon.svg';
import MagnificationGlass from '../../assets/images/MagnificationGlass.svg';
import MessageIcon from '../../assets/images/MessageIcon.svg';
import NextMonth from '../../assets/images/NextMonth.svg';
import PagingFirstArrow from '../../assets/images/PagingFirstArrow.svg';
import PagingFirstArrowDisabled from '../../assets/images/PagingFirstArrowDisabled.svg';
import PagingLastArrow from '../../assets/images/PagingLastArrow.svg';
import PagingLastArrowDisabled from '../../assets/images/PagingLastArrowDisabled.svg';
import PagingLeftArrow from '../../assets/images/PagingLeftArrow.svg';
import PagingLeftArrowDisabled from '../../assets/images/PagingLeftArrowDisabled.svg';
import PagingRightArrow from '../../assets/images/PagingRightArrow.svg';
import PagingRightArrowDisabled from '../../assets/images/PagingRightArrowDisabled.svg';
import PersonOutlineIcon from '../../assets/images/PersonOutlineIcon.svg';
import PreviousMonth from '../../assets/images/PreviousMonth.svg';
import RadioButtonsCross from '../../assets/images/RadioButtonsCross.svg';
import RadioButtonsTick from '../../assets/images/RadioButtonsTick.svg';
import RefreshIcon from '../../assets/images/RefreshIcon.svg';
import SavedSearchList from '../../assets/images/SavedSearchList.svg';
import SavedSearchNotificationOff from '../../assets/images/SavedSearchNotificationOff.svg';
import SavedSearchNotificationOn from '../../assets/images/SavedSearchNotificationOn.svg';
import SavedSearchSave from '../../assets/images/SavedSearchSave.svg';
import SavedSearchToggleClosed from '../../assets/images/SavedSearchToggleClosed.svg';
import SavedSearchToggleOpen from '../../assets/images/SavedSearchToggleOpen.svg';
import SelectedResultClear from '../../assets/images/SelectedResultClear.svg';
import ShieldIcon from '../../assets/images/ShieldIcon.svg';
import SortAscending from '../../assets/images/SortAscending.svg';
import SortDescending from '../../assets/images/SortDescending.svg';
import SortLoading from '../../assets/images/SortLoading.svg';
import SortUnset from '../../assets/images/SortUnset.svg';
import Spinner from '../../assets/images/Spinner.svg';
import StarEmpty from '../../assets/images/StarEmpty.svg';
import StarFilledDefault from '../../assets/images/StarFilledDefault.svg';
import StarFilledGood from '../../assets/images/StarFilledGood.svg';
import StarFilledModerate from '../../assets/images/StarFilledModerate.svg';
import StarFilledOutstanding from '../../assets/images/StarFilledOutstanding.svg';
import StarFilledPoor from '../../assets/images/StarFilledPoor.svg';
import ToggleActive from '../../assets/images/ToggleActive.svg';
import ToggleActiveMobile from '../../assets/images/ToggleActiveMobile.svg';
import ToggleDisabledInactive from '../../assets/images/ToggleDisabledInactive.svg';
import ToggleDisabledInactiveMobile from '../../assets/images/ToggleDisabledInactiveMobile.svg';
import ToggleInactive from '../../assets/images/ToggleInactive.svg';
import ToggleInactiveMobile from '../../assets/images/ToggleInactiveMobile.svg';
import ToggleLoading from '../../assets/images/ToggleLoading.svg';
/* [AddAsset: import] */
export const baseAssets = {
    icons: {
        download: DownloadIcon,
        spinner: Spinner,
        backArrow: BackArrowIcon,
        message: MessageIcon,
        copyUrl: CopyUrlIcon,
        /* [AddAsset: icons] */
    },
    image: {
        logo: KurtosysLogo,
        /* [AddAsset: image] */
    },
    contextMenu: {
        dropdownIcon: DropdownChevronIcon,
        dropdownIconExpanded: DropdownChevronIconExpanded,
        /* [AddAsset: contextMenu] */
    },
    calendarInput: {
        calendar: Calendar,
        previousMonth: PreviousMonth,
        nextMonth: NextMonth,
        /* [AddAsset: calendarInput] */
    },
    carousel: {
        navLeft: CarouselNavArrowLeftIcon,
        navRight: CarouselNavArrowRightIcon,
        /* [AddAsset: carousel] */
    },
    checkbox: {
        checked: CheckboxMarkedIcon,
        unchecked: CheckboxIcon,
        half: CheckboxHalfIcon,
        /* [AddAsset: checkbox] */
    },
    datePicker: {
        calendar: Calendar,
        previousMonth: PreviousMonth,
        nextMonth: NextMonth,
        dropdown: CaretDown,
        close: Close,
        /* [AddAsset: calendarInput] */
    },
    dateRangeInput: {
        clearField: Close,
        /* [AddAsset: dateRangeInput] */
    },
    documentDownload: {
        icon: DownloadIcon,
        spinner: Spinner,
        documentLabelIcon: DocumentLabelIcon,
        /* [AddAsset: documentDownload] */
    },
    dialog: {
        close: Close,
        /* [AddAsset: dialog] */
    },
    search: {
        cross: Cross,
        magnificationGlass: MagnificationGlass,
        spinner: Spinner,
        selectedResultClear: SelectedResultClear,
        /* [AddAsset: search] */
    },
    savedSearch: {
        save: SavedSearchSave,
        list: SavedSearchList,
        toggleOpen: SavedSearchToggleOpen,
        toggleClosed: SavedSearchToggleClosed,
        /* [AddAsset: savedSearch] */
    },
    savedSearchList: {
        delete: DeleteSavedSearch,
        notificationOff: SavedSearchNotificationOff,
        notificationOn: SavedSearchNotificationOn,
        /* [AddAsset: savedSearchList] */
    },
    tooltip: {
        defaultIcon: InformationIcon,
        /* [AddAsset: tooltip] */
    },
    dropdown: {
        caretUp: CaretUp,
        caretDown: CaretDown,
        header: LayoutIcon,
        /* [AddAsset: dropdown] */
    },
    accordionPanel: {
        expandIcon: AccordionExpandIcon,
        collapseIcon: AccordionCollapseIcon,
        /* [AddAsset: accordionPanel] */
    },
    iconInput: {
        home: HomeIcon,
        personOutline: PersonOutlineIcon,
        message: MessageIcon,
        fingerPrint: FingerPrintIcon,
        shield: ShieldIcon,
        clear: Close,
        /* [AddAsset: iconInput] */
    },
    imagePicker: {
        toggleDown: CaretDown,
        toggleUp: CaretUp,
        refresh: RefreshIcon,
        /* [AddAsset: imagePicker] */
    },
    loadingIndicator: {
        spinner: Spinner,
        /* [AddAsset: loadingIndicator] */
    },
    paging: {
        leftArrow: PagingLeftArrow,
        rightArrow: PagingRightArrow,
        leftArrowDisabled: PagingLeftArrowDisabled,
        rightArrowDisabled: PagingRightArrowDisabled,
        pagingFirstArrow: PagingFirstArrow,
        pagingLastArrow: PagingLastArrow,
        pagingFirstArrowDisabled: PagingFirstArrowDisabled,
        pagingLastArrowDisabled: PagingLastArrowDisabled,
        /* [AddAsset: paging] */
    },
    toast: {
        close: Close,
        /* [AddAsset: toast] */
    },
    dateDropdown: {
        calendar: Calendar,
        calendarPlaceholder: CalendarPlaceholder,
        /* [AddAsset: dateDropdown] */
    },
    toggleButton: {
        disabledInactive: ToggleDisabledInactive,
        disabledInactiveMobile: ToggleDisabledInactiveMobile,
        inactive: ToggleInactive,
        active: ToggleActive,
        inactiveMobile: ToggleInactiveMobile,
        activeMobile: ToggleActiveMobile,
        loading: ToggleLoading,
        /* [AddAsset: toggleButton] */
    },
    sortButton: {
        ascending: SortAscending,
        descending: SortDescending,
        loading: SortLoading,
        unset: SortUnset,
        /* [AddAsset: sortButton] */
    },
    scale: {
        empty: StarEmpty,
        filledDefault: StarFilledDefault,
        overrides: [
            {
                value: 1,
                asset: StarFilledPoor,
            },
            {
                value: 2,
                asset: StarFilledModerate,
            },
            {
                value: 3,
                asset: StarFilledGood,
            },
            {
                value: 4,
                asset: StarFilledOutstanding,
            },
        ],
        /* [AddAsset: scale] */
    },
    radioButtons: {
        tick: RadioButtonsTick,
        cross: RadioButtonsCross,
    },
    modal: {
        close: Close,
        openIcon: DropdownChevronIcon,
        openedIcon: DropdownChevronIconExpanded,
        /* [AddAsset: modal] */
    },
    /* [InitComponentAsset: assetKey] */
};
