export const factListStyle = {
    children: {
        wrapper: {
            padding: {
                bottom: '10px',
            },
            overrides: [
                {
                    selector: '[data-selector="fact-fact-wrapper"]',
                    style: {
                        display: 'flex',
                        flex: {
                            direction: 'row',
                        },
                    },
                },
                {
                    selector: '[data-selector="fact-fact-titleWrapper"]',
                    style: {
                        flex: {
                            _value: 'auto',
                        },
                    },
                },
                {
                    selector: '[data-selector="fact-fact-value"]',
                    style: {
                        flex: {
                            grow: '0',
                        },
                    },
                },
                {
                    selector: '[data-selector="fact-fact-value"][data-has-title="false"]',
                    style: {
                        flex: {
                            _value: 'auto',
                        },
                    },
                },
                {
                    selector: '[data-selector="risk-riskRating-wrapper"]',
                    style: {
                        width: '300px',
                    },
                },
                {
                    selector: '[data-selector="base-list-listItemWrapper"][data-single-item="true"] [data-selector="base-list-listItem"]',
                    style: {
                        border: {
                            style: 'none',
                        },
                    },
                },
            ],
        },
    },
};
