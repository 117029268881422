import React, { createContext } from 'react';
import { common } from '@kurtosys/ksys-app-template';
// eslint-disable-next-line @typescript-eslint/naming-convention
export const ConfigurationContext = createContext({});
export const withConfiguration = (
// eslint-disable-next-line @typescript-eslint/naming-convention
Component) => { var _a; return _a = class WithLoading extends React.Component {
        constructor(props) {
            super(props);
            this.context = null;
            this.orchestrateConfiguration = (props) => {
                const contextConfiguration = this.context;
                const { configuration: propsConfiguration } = props;
                if (propsConfiguration) {
                    return common.commonUtils.deepMergeObjects(contextConfiguration, propsConfiguration);
                }
                return contextConfiguration;
            };
            this.getStateFromProps = (props) => {
                return {
                    mergedConfiguration: this.orchestrateConfiguration(props),
                    overrideConfiguration: props.configuration,
                };
            };
            this.state = {};
        }
        get configuration() {
            return this.state.mergedConfiguration;
        }
        // The parameters must be of type "any" otherwise it will break ¯\_(ツ)_/¯
        static getDerivedStateFromProps(nextProps, previousState) {
            if (nextProps.configuration !== previousState.overrideConfiguration) {
                return { mergedConfiguration: null };
            }
            return null;
        }
        componentDidUpdate() {
            if (!this.state.mergedConfiguration) {
                this.setState(this.getStateFromProps(this.props));
            }
        }
        componentDidMount() {
            if (!this.state.mergedConfiguration) {
                this.setState(this.getStateFromProps(this.props));
            }
        }
        render() {
            const { ...props } = this.props;
            const configuration = this.context;
            return React.createElement(Component, { configuration: configuration, ...props });
        }
    },
    _a.contextType = ConfigurationContext,
    _a; };
