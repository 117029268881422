export const tableStyle = {
    width: '100%',
    overrides: [
        {
            selector: '#colspan-table th:first-child',
            style: {
                border: {
                    bottom: {
                        _value: '2px solid red',
                    },
                },
            },
        },
        {
            selector: '[data-selector="base-tooltip-tooltipContent"]',
            style: {
                color: 'black',
                lineHeight: '14px',
                font: {
                    size: '12px',
                    weight: 'normal',
                },
            },
        },
        {
            selector: 'li[data-selector="base-list-listItemWrapper"]',
            style: {
                padding: {
                    _value: '10px',
                },
                border: {
                    bottom: {
                        _value: '1px solid #c7c7c7',
                    },
                },
            },
        },
        {
            selector: 'li[data-selector="base-list-listItemWrapper"] div',
            style: {
                border: {
                    _value: 'none',
                },
            },
        },
        {
            selector: 'li[data-selector="base-list-listItemWrapper"][data-multipart="false"]:nth-child(1)',
            style: {
                font: {
                    weight: 'bold',
                },
                color: '#228de2',
                border: {
                    bottom: {
                        width: '2px',
                        style: 'solid',
                        color: '#228de2',
                    },
                },
            },
        },
        {
            selector: '[data-selector="base-table-card"]:nth-child(even)',
            style: {
                background: {
                    color: '#F3F9FF',
                },
            },
        },
        {
            selector: '[data-selector="base-table-card"]',
            style: {
                border: {
                    radius: '10px',
                    _value: '1px solid #cccccc',
                },
            },
        },
        {
            selector: 'li[data-selector="base-list-listItemWrapper"]:last-child',
            style: {
                border: {
                    bottom: {
                        _value: 'none',
                    },
                },
            },
        },
    ],
    children: {
        table: {
            width: '100%',
            font: {
                family: 'Arial',
            },
            border: {
                collapse: 'collapse',
            },
        },
        head: {
            color: '#000000',
        },
        headRow: {
            overrides: [
                {
                    selector: '.ksys-header-performance',
                    style: {
                        background: {
                            color: 'blue',
                        },
                        color: '#ffffff',
                    },
                },
                {
                    selector: '.ksys-header-cost-and-charges',
                    style: {
                        background: {
                            color: 'green',
                        },
                        color: '#ffffff',
                    },
                },
                {
                    selector: '.ksys-header-service',
                    style: {
                        background: {
                            color: 'red',
                        },
                        color: '#ffffff',
                    },
                },
            ],
        },
        cardGrouping: {
            border: {
                radius: '10px',
            },
            padding: {
                _value: '10px',
            },
            margin: {
                top: '10px',
                bottom: '10px',
            },
            display: 'flex',
            overrides: [
                {
                    selector: '[data-selector="base-toggleButton-wrapper"] [data-selector="base-button"]',
                    style: {
                        background: {
                            color: 'unset',
                        },
                        border: {
                            _value: 'unset',
                        },
                    },
                },
                {
                    selector: 'label',
                    style: {
                        flex: {
                            _value: '1',
                        },
                    },
                },
            ],
        },
        headCell: {
            border: {
                bottom: {
                    _value: '1px solid #C7C7C7',
                },
            },
            verticalAlign: 'bottom',
            padding: {
                left: '5px',
                right: '16px',
            },
            text: {
                align: 'center',
            },
            font: {
                size: '16px',
                weight: 'bold',
            },
            lineHeight: '20px',
            overrides: [
                {
                    selector: '[data-selector="base-sortButton-wrapper"]',
                    style: {
                        float: 'right',
                        overrides: [
                            {
                                selector: 'button',
                                style: {
                                    border: {
                                        _value: 'none',
                                    },
                                    background: {
                                        color: 'transparent',
                                    },
                                    overrides: [
                                        {
                                            selector: 'img',
                                            style: {
                                                width: '16px',
                                                height: '16px',
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        },
        headCellSubTitle: {
            text: {
                align: 'center',
            },
            font: {
                size: '12px',
                weight: 'normal',
            },
            lineHeight: '16px',
        },
        body: {
            font: {
                size: '16px',
            },
            color: '#001928',
            lineHeight: '24px',
        },
        row: {
            height: '52px',
            background: {
                color: '#FFFFFF',
            },
        },
        rowHeadCell: {
            border: {
                bottom: {
                    _value: '1px solid #C7C7C7',
                },
            },
            text: {
                transform: 'uppercase',
            },
        },
        cell: {
            border: {
                bottom: {
                    color: '#C7C7C7',
                    width: '1px',
                    style: 'solid',
                },
            },
            selectors: [
                {
                    ':first-child': {
                        text: {
                            align: 'left',
                        },
                        padding: {
                            left: '16px',
                        },
                    },
                },
            ],
            text: {
                align: 'right',
            },
            padding: {
                left: '5px',
                right: '16px',
            },
        },
        disclaimersWrapper: {
            margin: {
                top: '20px',
            },
        },
        card: {
            margin: {
                _value: '4px',
            },
            padding: {
                _value: '4px',
            },
        },
        listGroupTitle: {
            font: {
                weight: 'bold',
            },
        },
        listGroupSubTitle: {
            display: 'block',
            color: 'grey',
            font: {
                weight: 'normal',
            },
        },
        documentContextMenuLink: {
            display: 'flex',
            flex: {
                direction: 'row',
            },
            color: 'black !important',
            text: {
                decoration: 'none !important',
            },
            width: '100%',
            selectors: {
                ':hover': {
                    background: {
                        color: '#ededed',
                    },
                },
            },
            overrides: [
                {
                    selector: '[data-selector="base-label"]',
                    style: {
                        flex: {
                            _value: '1',
                        },
                    },
                },
            ],
        },
    },
};
