import { fundManagerStyle } from './FundManager/styles/FundManager.style.js';
import { fundManagersCarouselStyle } from './FundManagersCarousel/styles/FundManagersCarousel.style.js';
/* [Component: import] */
/* [ChartComponent: import] */
export const managementTheme = {
    fundManager: fundManagerStyle,
    fundManagersCarousel: fundManagersCarouselStyle,
    /* [Component: interface] */
    /* [ChartComponent: interface] */
};
