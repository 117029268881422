import React from 'react';
// Maybe handle the loading here or another HOC
export const withSelector = (
// eslint-disable-next-line @typescript-eslint/naming-convention
WrappedComponent, propsOrSelector, className = '') => {
    class withSelector extends React.Component {
        render() {
            const props = this.props;
            let selector = propsOrSelector;
            if (typeof propsOrSelector === 'object') {
                selector = propsOrSelector.selector || propsOrSelector['data-selector'];
            }
            if (props.hide) {
                return null;
            }
            const elementClassName = `${className}${props.className ? ` ${props.className}` : ''}`;
            if (typeof WrappedComponent === 'string') {
                return getTagComponent({ tag: WrappedComponent, ...props, className: elementClassName });
            }
            return React.createElement(WrappedComponent, { "data-selector": selector, ...props, className: elementClassName });
        }
    }
    return withSelector;
};
const getTagComponent = ({ 
// eslint-disable-next-line @typescript-eslint/naming-convention
tag: Wrapper = 'div', children, ...rest }) => {
    return React.createElement(Wrapper, { ...rest }, children);
};
