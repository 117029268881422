export const gaugeChartStyle = {
    children: {
        wrapper: {
            display: 'flex',
            flex: {
                direction: 'column',
            },
            justifyContent: 'center',
        },
        chartWrapper: {
            display: 'flex',
            justifyContent: 'center',
        },
    },
};
