import { common } from '@kurtosys/ksys-app-template';

import { ISelectionValues } from '../components/Selection/models/ISelectionValues';

export function getSelectionsForRedirect(
	appParamsHelper: common.helpers.AppParamsHelper<any, any, any>,
	rawValues: ISelectionValues,
	values?: ISelectionValues,
) {
	let selections = values || rawValues;
	if (appParamsHelper.values) {
		const { inputs } = appParamsHelper.values;
		selections = common.commonUtils.deepMergeObjects(inputs, selections);
	}
	return selections;
}
