import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import SelectionField from '../SelectionField/SelectionField.js';
import SelectionWizard from '../SelectionWizard/SelectionWizard.js';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent.js';

import { ISelectionField } from './models/ISelectionField.js';
import Wrapper from './styledComponents/Wrapper.js';
import { ISelectionProps, ISelectionState } from './models';

class RawSelection extends Component<ISelectionProps, ISelectionState> {
	static configurationKey = 'selection' as const;
	static styleKey = 'selection' as const;
	constructor(props: ISelectionProps) {
		super(props);
	}
	handleChange = (field: ISelectionField, value: any) => {
		const { selectionStore } = this.props;
		if (selectionStore) {
			selectionStore.setValue(field, value);
		}
	};
	render() {
		const { selectionStore, className } = this.props;
		if (!selectionStore) {
			return null;
		}
		const { mode, getOptions, getSelectedFieldOption } = selectionStore;
		return (
			<Wrapper className={className}>
				{mode === 'wizard' && <SelectionWizard />}
				{mode === 'default' &&
					selectionStore.fields.map((field) => {
						const { key } = field;
						const options = getOptions(field);
						const fieldResponse = getSelectedFieldOption(field, options);
						return (
							<SelectionField
								selectionStore={selectionStore}
								key={key}
								field={field}
								options={options}
								value={fieldResponse.value}
								onChange={this.handleChange}
							/>
						);
					})}
			</Wrapper>
		);
	}
}

export const Selection = inject('appStore', 'selectionStore')(observer(RawSelection));
export default InjectedStyledComponent(Selection, 'selection');
