import React, { Component } from 'react';
import { components } from '@kurtosys/ksys-app-components';
import { inject, observer } from 'mobx-react';

import { InjectedStyledComponent } from '../shared/InjectedStyledComponent.js';

import { IAcceptanceDisclaimerContent } from './models/IAcceptanceDisclaimerContent.js';
import Content from './styledComponents/Content.js';
import Heading from './styledComponents/Heading.js';
import List from './styledComponents/List.js';
import ListItem from './styledComponents/ListItem.js';
import Wrapper from './styledComponents/Wrapper.js';
import { IAcceptanceDisclaimerProps, IAcceptanceDisclaimerState } from './models';

const Translate = components.base.Translate.Translate;

class RawAcceptanceDisclaimer extends Component<IAcceptanceDisclaimerProps, IAcceptanceDisclaimerState> {
	static configurationKey = 'acceptanceDisclaimer' as const;
	static styleKey = 'acceptanceDisclaimer' as const;

	constructor(props: IAcceptanceDisclaimerProps) {
		super(props);
	}

	render() {
		const { className, acceptanceDisclaimerStore, appStore } = this.props;
		const disclaimers = (acceptanceDisclaimerStore && acceptanceDisclaimerStore.disclaimers) || [];
		const heading = (acceptanceDisclaimerStore && acceptanceDisclaimerStore.heading) || '';

		if (!appStore || !appStore.hasSelectionsAndDisclaimers) {
			return null;
		}
		return (
			<Wrapper className={className}>
				<Heading>
					<Translate>{heading}</Translate>
				</Heading>
				<List>
					{disclaimers.map((disclaimer: IAcceptanceDisclaimerContent, index) => (
						<ListItem key={index}>
							<Content dangerouslySetInnerHTML={{ __html: disclaimer.content }} />
						</ListItem>
					))}
				</List>
			</Wrapper>
		);
	}
}

export const AcceptanceDisclaimer = inject('appStore', 'acceptanceDisclaimerStore')(observer(RawAcceptanceDisclaimer));
export default InjectedStyledComponent(AcceptanceDisclaimer, 'acceptanceDisclaimer');
