import { colorPickerStyle } from './ColorPicker/styles/ColorPicker.style.js';
import { toastStyle } from './Toast/styles/Toast.style.js';
import { toasterStyle } from './Toaster/styles/Toaster.style.js';
/* [Component: import] */
/**
 * The  Portal pillar
 * @title Portal
 */
export const portalTheme = {
    toast: toastStyle,
    toaster: toasterStyle,
    colorPicker: colorPickerStyle,
    /* [Component: interface] */
};
