export const buttonRaisedStyle = {
    box: {
        sizing: 'inherit',
        shadow: 'rgba(0, 33, 121, 0.05) 0px 6px 12px, rgba(0, 20, 74, 0.07) 0px 0px 4px',
    },
    font: {
        weight: '400',
        size: '11px',
    },
    display: 'flex',
    flex: {
        shrink: '0',
    },
    justifyContent: 'center',
    align: {
        items: 'center',
    },
    cursor: 'pointer',
    lineHeight: '24px',
    border: {
        width: '0px',
        style: 'initial',
        color: 'initial',
    },
    color: 'rgb(255, 255, 255)',
    position: 'relative',
    width: 'auto',
    background: {
        color: 'rgb(26, 128, 255)',
    },
    margin: {
        _value: '20px auto',
    },
    padding: {
        _value: '10px 30px',
    },
    selectors: {
        ':disabled': {
            background: {
                color: '#939799',
            },
            cursor: 'no-drop',
        },
    },
};
