export const imagePickerStyle = {
    children: {
        wrapper: {
            position: 'relative',
            margin: {
                top: '15px',
            },
            width: '200px',
            height: '160px',
            flex: {
                shrink: '0',
            },
            box: {
                sizing: 'content-box',
            },
            border: {
                _value: '1px solid #d6d9e1',
            },
            background: {
                color: '#f3f4f8',
            },
        },
        coveringButton: {
            cursor: 'pointer',
            position: 'absolute',
            width: '100%',
            height: 'inherit',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            display: 'flex',
            justifyContent: 'flex-start',
            padding: {
                _value: '0px',
            },
        },
        buttons: {
            position: 'relative',
            flex: {
                grow: '1',
            },
        },
        toggleButton: {
            cursor: 'pointer',
            position: 'absolute',
            display: 'inline-block',
            justifyContent: 'center',
            align: {
                items: 'center',
            },
            width: '100%',
            top: '50%',
            transform: 'translateY(-50%)',
            border: {
                width: '0px',
                style: 'initial',
                color: 'initial',
                image: {
                    _value: 'initial',
                },
            },
            background: {
                _value: 'none',
            },
            padding: {
                _value: '0',
            },
            margin: {
                _value: '0',
            },
        },
        refreshButton: {
            cursor: 'pointer',
            display: 'inline-block',
            justifyContent: 'center',
            align: {
                items: 'center',
            },
            width: '100%',
            border: {
                width: '0px',
                style: 'initial',
                color: 'initial',
                image: {
                    _value: 'initial',
                },
            },
            background: {
                _value: 'none',
            },
            padding: {
                _value: '0',
            },
            margin: {
                _value: '0',
                top: '20px',
            },
        },
        selectedImage: {
            height: 'inherit',
            position: 'relative',
            border: {
                radius: '0px',
            },
        },
        pickerOverlay: {
            position: 'absolute',
            top: '-1px',
            left: '-1px',
            width: '360px',
            height: '240px',
            box: {
                shadow: 'rgba(0, 33, 121, 0.05) 0px 6px 12px, rgba(0, 20, 74, 0.07) 0px 0px 4px',
            },
            zIndex: '1',
            display: 'flex',
            background: {
                color: '#f3f4f8',
            },
            border: {
                _value: '1px solid #d6d9e1',
                image: {
                    _value: 'initial',
                },
            },
        },
        thumbnails: {
            width: '320px',
            position: 'relative',
            height: '100%',
            overflow: {
                y: 'auto',
            },
            display: 'flex',
            flex: {
                wrap: 'wrap',
            },
            justifyContent: 'flex-start',
            box: {
                sizing: 'border-box',
            },
            background: {
                color: 'white',
            },
        },
        thumbnail: {
            cursor: 'pointer',
            width: 'calc(100% / 3)',
            height: '107px',
            padding: {
                _value: '0px',
            },
        },
        thumbnailImage: {
            width: '100%',
            position: 'relative',
            border: {
                radius: '0px',
            },
        },
    },
};
