import { stores } from '@kurtosys/app-start';
import { ITextProps } from '@kurtosys/ksys-app-components/dist/components/base/Text/models/ITextProps';
import { action, computed, makeObservable, observable } from 'mobx';

import { IComponentStyles } from '../../../models/app/IComponentStyles.js';
import { IConfiguration } from '../../../models/app/IConfiguration.js';
import { IConfigurationStorage } from '../../../models/app/IConfigurationStorage.js';
import { TStoreContext } from '../../../models/app/TStoreContext.js';
import { AppStore } from '../../App/stores/AppStore.js';
import { SelectionStore } from '../../Selection/stores/SelectionStore.js';
import { IAcceptanceConfiguration } from '../models';
import { IAcceptanceItem } from '../models/IAcceptanceItem.js';
import { TAcceptanceMode } from '../models/TAcceptanceMode.js';

type TranslationStore = stores.TranslationStore<IConfiguration, IComponentStyles>;
import { IManifest } from '@kurtosys/types/appsManager/index.js';
export class AcceptanceStore extends stores.base.StoreBase<IConfiguration, IComponentStyles> {
	static componentKey = 'acceptance' as const;

	constructor(storeContext: TStoreContext, manifest: IManifest) {
		super(storeContext, manifest);
		makeObservable(this);
	}

	@action
	async initialize(): Promise<void> {
		return;
	}

	@computed
	get appStore(): AppStore {
		return this.storeContext.get<AppStore>('appStore');
	}

	@computed
	get selectionStore(): SelectionStore {
		return this.storeContext.get<SelectionStore>('selectionStore');
	}

	@computed
	get translationStore(): TranslationStore {
		return this.storeContext.get<TranslationStore>('translationStore');
	}

	@computed
	get configuration(): IAcceptanceConfiguration | undefined {
		if (this.storeContext && this.appStore) {
			return this.appStore.getComponentConfiguration(AcceptanceStore.componentKey);
		}
	}
	@observable singleAccepted = false;
	@observable acceptedItems: string[] = [];

	@action
	toggleItem(key: string, value: boolean) {
		if (value) {
			if (this.acceptedItems.indexOf(key) < 0) {
				this.acceptedItems.push(key);
			}
		} else {
			this.acceptedItems = this.acceptedItems.filter((item) => item !== key);
		}
	}

	@action
	resetAcceptedState() {
		this.singleAccepted = false;
		this.acceptedItems = [];
	}

	@computed
	get mode(): TAcceptanceMode {
		return (this.configuration && this.configuration.mode) || 'SINGLE';
	}
	@computed
	get text(): string {
		// Need to use placeholder here
		const rawText = (this.configuration && this.configuration.text) || 'I accept';
		const translatedValues = Object.keys(this.selectionStore.rawValues).reduce(
			(acc: { [key: string]: string }, key) => {
				acc[key] = this.translationStore.translate(this.selectionStore.rawValues[key]);
				return acc;
			},
			{},
		);
		return this.translationStore.translate(rawText, translatedValues);
	}

	@computed
	get items(): IAcceptanceItem[] {
		const items = this.configuration && this.configuration.items;

		if (items) {
			return items.map((item: IAcceptanceItem) => {
				const translatedValues = Object.keys(this.selectionStore.rawValues).reduce(
					(acc: { [key: string]: string }, key) => {
						acc[key] = this.translationStore.translate(this.selectionStore.rawValues[key]);
						return acc;
					},
					{},
				);
				return {
					...item,
					text: this.translationStore.translate(item.text, translatedValues),
				};
			});
		}
		return [];
	}

	@computed
	get acceptedKeys(): string[] | undefined {
		if (this.mode === 'MULTIPLE') {
			return this.items.filter((x) => this.acceptedItems.indexOf(x.key) >= 0).map((x) => x.key);
		}
		return [this.text];
	}

	@computed
	get acceptedState() {
		if (this.mode === 'MULTIPLE') {
			const failCheck = (item: IAcceptanceItem) => !item.optional && this.acceptedItems.indexOf(item.key) < 0;
			return !this.items.some(failCheck);
		}
		return this.singleAccepted;
	}

	@computed
	get accepted(): boolean {
		return this.acceptedState && this.selectionStore && !!this.selectionStore.hasAllSelections;
	}

	@computed
	get storage(): IConfigurationStorage | undefined {
		if (this.configuration && this.configuration.storage) {
			const { mode, storage } = this.configuration;
			const isStorageItem = (item: IAcceptanceItem) =>
				item.key === storage.linkedItem && this.acceptedItems.includes(item.key);
			if (mode === 'STORAGE' || (mode === 'MULTIPLE' && this.items.some(isStorageItem))) {
				return storage;
			}
		}
	}

	// Get the storage value without checking if the linked checkbox is checked
	@computed
	get rawStorage(): IConfigurationStorage | undefined {
		if (this.configuration && this.configuration.storage) {
			const { mode, storage } = this.configuration;
			if (mode === 'STORAGE' || (mode === 'MULTIPLE' && storage.linkedItem)) {
				return storage;
			}
		}
	}

	@computed
	get textProps(): Partial<ITextProps> | undefined {
		return this.configuration && this.configuration.textProps;
	}
}
