export const dialogStyle = {
    children: {
        backdrop: {
            content: '',
            display: 'block',
            position: 'absolute',
            background: {
                color: 'rgba(0, 0, 0, 0.5)',
            },
            zIndex: '-1',
            left: '0',
            top: '0',
            margin: {
                _value: '-50vmax',
            },
            bottom: '0',
            right: '0',
        },
        base: {
            background: {
                color: 'white',
            },
            display: 'flex',
            flex: {
                direction: 'column',
            },
            zIndex: '99',
            transition: {
                _value: 'all 0.2s',
            },
            position: 'absolute',
            top: '50%',
            left: '50%',
            margin: {
                _value: 'auto',
            },
            transform: 'translate(-50%, -50%)',
            max: {
                height: 'calc(100% - 60px)',
                width: 'calc(100% - 60px)',
            },
        },
        body: {
            padding: {
                _value: '0 15px',
            },
        },
        titleBar: {
            padding: {
                _value: '10px',
            },
            border: {
                bottom: {
                    _value: 'solid 1px #8a8a8a',
                },
            },
        },
        close: {
            float: 'right',
            overrides: [
                {
                    selector: 'button',
                    style: {
                        padding: {
                            _value: '0',
                        },
                        lineHeight: '16px',
                    },
                },
            ],
        },
    },
};
