export const clickLayerStyle = {
    zIndex: '999',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    background: {
        color: 'rgba(128, 128, 128, 0.1)',
    },
};
