export const colorPickerStyle = {
    children: {
        colorIndicator: {
            height: '32px',
            width: '32px',
            border: {
                _value: 'solid 1px black',
            },
        },
        activeColorIndicator: {
            cursor: 'pointer',
        },
        pickerWrapper: {
            zIndex: '2',
            position: 'absolute',
        },
        clickLayer: {
            zIndex: '1',
            position: 'fixed',
            top: '0',
            bottom: '0',
            right: '0',
            left: '0',
        },
    },
};
