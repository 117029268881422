import { stores } from '@kurtosys/app-start';
import { action, computed, makeObservable } from 'mobx';

import { IComponentStyles } from '../../../models/app/IComponentStyles.js';
import { IConfiguration } from '../../../models/app/IConfiguration.js';
import { TStoreContext } from '../../../models/app/TStoreContext.js';
import { AppStore } from '../../App/stores/AppStore.js';
import { ISelectionField } from '../../Selection/models/ISelectionField.js';
import { IIconDropdownConfiguration } from '../models';

type TranslationStore = stores.TranslationStore<IConfiguration, IComponentStyles>;
import { IManifest } from '@kurtosys/types/appsManager/index.js';

export class IconDropdownStore extends stores.base.StoreBase<IConfiguration, IComponentStyles> {
	static componentKey = 'iconDropdown' as const;

	constructor(storeContext: TStoreContext, manifest: IManifest) {
		super(storeContext, manifest);
		makeObservable(this);
	}

	@computed
	get appStore(): AppStore {
		return this.storeContext.get<AppStore>('appStore');
	}

	@computed
	get translationStore(): TranslationStore {
		return this.storeContext.get<TranslationStore>('translationStore');
	}

	@action
	async initialize(): Promise<void> {
		return;
	}

	@computed
	get configuration(): IIconDropdownConfiguration | undefined {
		if (this.storeContext && this.appStore) {
			return this.appStore.getComponentConfiguration(IconDropdownStore.componentKey);
		}
	}

	@computed
	get iconAlignment() {
		return this.configuration && this.configuration.icon && this.configuration.icon.align;
	}

	getPlaceholderText(field: ISelectionField) {
		const text = field.placeholderText || (this.configuration && this.configuration.placeholderText) || '\u00a0';
		if (this.translationStore) {
			return this.translationStore.translate(text);
		}
		return text;
	}
}
