import React, { createContext, Fragment } from 'react';
import { common } from '@kurtosys/ksys-app-template';
import { appsManager } from '@kurtosys/ksys-app-template';
import { observer } from 'mobx-react';
import { assets as defaultAssets } from '../../../assets/assets.js';
import { ComponentBase } from '../../../shared/index.js';
import { ASSET_CATEGORY_KEY, ASSET_COMPONENT_KEY } from './Asset.meta.js';
const appendCacheQueryStringRaw = appsManager.utils.appendCacheQueryString;
// eslint-disable-next-line @typescript-eslint/naming-convention
export const AssetContextValue = (assets = {}, assetRegisters = [], cacheOptions) => {
    const assetRegisterByFileName = common.commonUtils.pivotCollection(assetRegisters, (assetRegister) => assetRegister.fileName);
    const appendCacheQueryString = (fileName) => {
        const assetRegister = assetRegisterByFileName[fileName];
        fileName = appendCacheQueryStringRaw(fileName, assetRegister, cacheOptions);
        return fileName;
    };
    const getAsset = (key) => {
        const asset = common.commonUtils.get(assets, key);
        const response = appendCacheQueryString(asset || key);
        return response;
    };
    const hasAsset = (key) => {
        const asset = common.commonUtils.get(assets, key, null);
        return !common.commonUtils.isNullOrEmpty(asset);
    };
    const baseUrl = () => {
        return (assets && assets.baseUrl) || '';
    };
    return {
        assets,
        getAsset,
        hasAsset,
        baseUrl,
        appendCacheQueryString,
    };
};
// eslint-disable-next-line @typescript-eslint/naming-convention
export const AssetContext = createContext(AssetContextValue(defaultAssets));
export class Asset extends ComponentBase {
    constructor() {
        super(...arguments);
        this.categoryKey = ASSET_CATEGORY_KEY;
        this.componentKey = ASSET_COMPONENT_KEY;
        this.context = null;
    }
    get asset() {
        const { children } = this.props;
        if (typeof children !== 'string' || !this.context || !this.context.assets) {
            return children;
        }
        return this.context.getAsset(children);
    }
    render() {
        return React.createElement(Fragment, null, this.asset);
    }
}
Asset.contextType = AssetContext;
export default observer(Asset);
