export const menuItemStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    margin: {
        _value: '0',
    },
    padding: {
        _value: '6px 16px',
    },
    selectors: {
        '&:hover': {
            cursor: 'pointer',
        },
        '&:hover, &:focus': {
            background: {
                color: '#efefef',
            },
        },
        '&.ksys-selected': {
            background: {
                color: '#cfcfcf',
            },
        },
        '&.ksys-selected:hover, &.ksys-selected:focus': {
            background: {
                color: '#dfdfdf',
            },
        }
    }
};
