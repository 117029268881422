export const gridStyle = {
    children: {
        row: {
            display: 'flex',
            flex: {
                direction: 'row',
            },
        },
        column: {
            display: 'flex',
            flex: {
                _value: '1',
                direction: 'column',
            },
            margin: {
                right: '20px',
            },
        },
    },
};
