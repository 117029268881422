export const summaryBlocksStyle = {
    children: {
        row: {
            display: 'flex',
        },
        block: {
            flex: {
                _value: 'auto',
            },
            margin: {
                _value: '4px',
            },
            overrides: [
                {
                    selector: '[data-selector="fact-summaryBlock-wrapper"]',
                    style: {
                        min: {
                            height: '90px',
                        },
                        max: {
                            height: '120px',
                        },
                    },
                },
            ],
        },
    },
};
