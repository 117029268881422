export const documentTilesStyle = {
    children: {
        wrapper: {
            display: 'flex',
            flex: {
                direction: 'row',
            },
            justifyContent: 'space-between',
        },
    },
};
