import React, { Fragment } from 'react';
import { common } from '@kurtosys/ksys-app-template';
import { action, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { AnalyticsContext, ComponentBase, InjectedStyledComponent } from '../../../shared/index.js';
import Icon from '../Icon/Icon.js';
import Translate from '../Translate/Translate.js';
import { BUTTON_CATEGORY_KEY, BUTTON_COMPONENT_KEY } from './Button.meta.js';
const isNullOrUndefined = common.commonUtils.isNullOrUndefined;
const isNullOrEmpty = common.commonUtils.isNullOrEmpty;
export class Button extends ComponentBase {
    constructor(props) {
        super(props);
        this.categoryKey = BUTTON_CATEGORY_KEY;
        this.componentKey = BUTTON_COMPONENT_KEY;
        this.isSuccess = false;
        this.ref = React.createRef();
        this.handleClick = (event) => {
            const { onClick, payload, iconProps, analyticsContext, suppressAnalytics } = this.props;
            if (this.analyticsHelper && !suppressAnalytics) {
                this.analyticsHelper.logEvent({
                    event: 'button_click',
                    eventType: 'click',
                    context: analyticsContext ? analyticsContext : {},
                });
            }
            if (onClick) {
                onClick(event, payload);
                let displaySeconds = 1000;
                if (iconProps && iconProps.iconOptions && iconProps.iconOptions.displaySeconds) {
                    displaySeconds = iconProps.iconOptions.displaySeconds * 1000;
                }
                this.isSuccess = true;
                setTimeout(() => (this.isSuccess = false), displaySeconds);
            }
        };
        this.handleOnKeyDown = (event) => {
            const { onKeyDown, payload } = this.props;
            if (onKeyDown) {
                onKeyDown(event, payload);
            }
        };
        makeObservable(this, {
            isSuccess: observable,
            hasLabel: computed,
            ariaProps: computed,
            content: computed,
            handleClick: action,
        });
    }
    get hasLabel() {
        const { children, text } = this.props;
        return !(isNullOrEmpty(text) && isNullOrEmpty(children));
    }
    get ariaProps() {
        const { iconProps, describedby, expanded } = this.props;
        const aria = {
            'aria-describedby': describedby,
        };
        if (!this.hasLabel && iconProps && iconProps.accessibilityText) {
            aria['aria-label'] = iconProps.accessibilityText;
        }
        if (!isNullOrUndefined(expanded)) {
            aria['aria-expanded'] = expanded;
        }
        return aria;
    }
    get content() {
        const { children, text, iconProps, iconPosition = 'right' } = this.props;
        let icon = iconProps && React.createElement(Icon, { ...iconProps });
        if (iconProps && iconProps.iconOptions) {
            const { iconOptions } = iconProps;
            if (this.isSuccess && iconOptions.successFilename) {
                icon = iconProps && React.createElement(Icon, { ...iconProps, ...iconOptions.successFilename });
            }
            else {
                icon = iconProps && React.createElement(Icon, { ...iconProps, ...iconOptions.filename });
            }
        }
        return (React.createElement(Fragment, null,
            !!icon && iconPosition === 'left' && icon,
            React.createElement(Translate, null, text || children),
            !!icon && iconPosition === 'right' && icon));
    }
    componentDidMount() {
        this.applyFocus();
    }
    componentDidUpdate() {
        this.applyFocus();
    }
    applyFocus() {
        if (this.props.hasFocus && this.ref?.current?.focus) {
            this.ref.current.focus();
        }
    }
    render() {
        const { 
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        children, 
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        text, 
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        iconProps, 
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        iconPosition, 
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        describedby, 
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        displayName, 
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        expanded, 
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        analyticsContext, 
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        suppressAnalytics, 
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        configuration, 
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        __docgenInfo, 
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        payload, hide, forwardedRef, hasFocus, ...buttonProps } = this.props;
        return (React.createElement(AnalyticsContext.Consumer, null, (analyticsHelper) => {
            this.analyticsHelper = analyticsHelper;
            return (React.createElement("button", { ...this.ariaProps, ...buttonProps, ref: forwardedRef || this.ref, onClick: this.handleClick, onKeyDown: this.handleOnKeyDown, hide: hide ? 'true' : undefined }, this.content));
        }));
    }
}
Button.defaultProps = {};
const StyledComponent = InjectedStyledComponent(observer(Button), BUTTON_CATEGORY_KEY, BUTTON_COMPONENT_KEY);
export default React.forwardRef((props, ref) => (React.createElement(StyledComponent, { ...props, forwardedRef: ref })));
