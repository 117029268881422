export const documentDownloadStyle = {
    children: {
        documentLabel: {
            display: 'flex',
            lineHeight: '34px',
            padding: {
                right: '8px',
            },
            flex: {
                grow: '1',
            },
        },
        icon: {
            verticalAlign: 'middle',
        },
        documentItemWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        wrapper: {
            display: 'flex',
        },
        contextMenuItemIcon: {
            display: 'flex',
            flex: {
                direction: 'column',
            },
            justifyContent: 'center',
        },
    },
};
