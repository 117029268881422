import { stores } from '@kurtosys/app-start';
import { common, query } from '@kurtosys/ksys-app-template';
import { TDisclaimerResponse } from '@kurtosys/types/api/common/TDisclaimerResponse.js';
import { action, computed, makeObservable, observable, toJS } from 'mobx';

import { IComponentStyles } from '../../../models/app/IComponentStyles.js';
import { IConfiguration } from '../../../models/app/IConfiguration.js';
import { TStoreContext } from '../../../models/app/TStoreContext.js';
import { AppStore } from '../../App/stores/AppStore.js';
import { KurtosysApiStore } from '../../App/stores/KurtosysApiStore.js';
import { DisclaimerStore } from '../../Disclaimer/stores/DisclaimerStore.js';
import { IFootnoteConfiguration } from '../../Footnote/models';
import { SelectionStore } from '../../Selection/stores/SelectionStore.js';
import { IAcceptanceDisclaimer } from '../models/IAcceptanceDisclaimer.js';
import { IAcceptanceDisclaimerContent } from '../models/IAcceptanceDisclaimerContent.js';
import { IDisclaimerPlaceholders, IPlaceholderValues } from '../models/IPlaceholderValueOptions.js';

type QueryStore = stores.QueryStore<IConfiguration, IComponentStyles>;
type TranslationStore = stores.TranslationStore<IConfiguration, IComponentStyles>;
import { IManifest } from '@kurtosys/types/appsManager/index.js';

const { replacePlaceholders, isNullOrEmpty } = common.commonUtils;

export class AcceptanceDisclaimerStore extends stores.base.StoreBase<IConfiguration, IComponentStyles> {
	@observable.ref disclaimerRecords: TDisclaimerResponse[] = [];

	constructor(storeContext: TStoreContext, manifest: IManifest) {
		super(storeContext, manifest);
		makeObservable(this);
	}

	@action
	async initialize(): Promise<void> {
		return;
	}

	@computed
	get appStore(): AppStore {
		return this.storeContext.get<AppStore>('appStore');
	}

	@computed
	get translationStore(): TranslationStore {
		return this.storeContext.get<TranslationStore>('translationStore');
	}

	@computed
	get queryStore(): QueryStore {
		return this.storeContext.get<QueryStore>('queryStore');
	}

	@computed
	get kurtosysApiStore() {
		return this.storeContext.get<KurtosysApiStore>('kurtosysApiStore');
	}

	@computed
	get selectionStore(): SelectionStore {
		return this.storeContext.get<SelectionStore>('selectionStore');
	}

	@computed
	get disclaimerStore(): DisclaimerStore {
		return this.storeContext.get<DisclaimerStore>('disclaimerStore');
	}

	@computed
	get configuration(): IAcceptanceDisclaimer[] {
		const disclaimerConfiguration = this.appStore.getComponentConfiguration('acceptanceDisclaimer');
		return (disclaimerConfiguration && disclaimerConfiguration.disclaimers) || [];
	}

	// get the configuration to setup the footnote disclaimer with the loadDisclaimers
	@computed
	get footnoteConfiguration(): IFootnoteConfiguration | undefined {
		if (this.appStore) {
			return this.appStore.getComponentConfiguration('footnote');
		}
	}

	@computed
	get hasDisclaimers(): boolean {
		return this.disclaimers && this.disclaimers.length > 0;
	}

	@computed
	get heading(): string {
		const disclaimerConfiguration = this.appStore.getComponentConfiguration('acceptanceDisclaimer');
		return (disclaimerConfiguration && disclaimerConfiguration.heading) || '';
	}

	@computed
	get disclaimers(): IAcceptanceDisclaimerContent[] {
		if (this.validDisclaimerTypes && this.validDisclaimerTypes.length > 0) {
			const flatDisclaimers: any[] = toJS(this.disclaimerRecords)
				.filter((disclaimerRecord) => this.validDisclaimerTypes.includes(disclaimerRecord.disclaimerType))
				.map((disclaimerRecord) => {
					let disclaimers = disclaimerRecord.disclaimers;
					disclaimers = disclaimers.map((disclaimer) => {
						return { ...disclaimer, type: disclaimerRecord.disclaimerType };
					});
					return disclaimers;
				})
				.flat(1);

			if (this.disclaimersPlaceholderConfiguration) {
				Object.keys(this.disclaimersPlaceholderConfiguration).forEach((key) => {
					const currentDisclaimer = flatDisclaimers.find((disclaimer) => key === disclaimer.type);
					this.replacePlaceholders(currentDisclaimer);
				});
			}

			const result: IAcceptanceDisclaimerContent[] = flatDisclaimers.map((flatDisclaimer) => {
				return {
					content: flatDisclaimer.disclaimer,
				};
			});
			return result;
		}
		return [];
	}

	@action
	replacePlaceholders(currentDisclaimer: any) {
		const type = currentDisclaimer.type;
		if (this.disclaimersPlaceholderConfiguration && this.disclaimersPlaceholderConfiguration[type]) {
			const optionsConfig = this.disclaimersPlaceholderConfiguration[type];
			const keys = Object.keys(optionsConfig);
			const values: Record<string, string> = {};
			const replacementValues = this.getPlaceholderValues();
			keys.forEach((key) => {
				const valueConfig = optionsConfig[key];
				if (valueConfig && valueConfig.value) {
					const value = this.queryStore.query(valueConfig.value);
					values[key] = value;
				} else if (valueConfig && valueConfig.fieldSelectionKey) {
					values[key] = replacementValues[key];
				}
			});
			currentDisclaimer.disclaimer = replacePlaceholders({
				values,
				text: currentDisclaimer.disclaimer,
			});
		}
	}

	@computed
	get disclaimersPlaceholderConfiguration(): IDisclaimerPlaceholders | undefined {
		const placeholderValues: Record<string, IPlaceholderValues> = {};
		this.configuration
			.filter((disclaimer) => this.validDisclaimerTypes.includes(disclaimer.type))
			.forEach((disclaimer) => {
				if (disclaimer.fieldPlaceholderValues) {
					placeholderValues[disclaimer.type] = disclaimer.fieldPlaceholderValues;
				}
			});
		return isNullOrEmpty(placeholderValues) ? undefined : placeholderValues;
	}

	@action
	getPlaceholderValues(): Record<string, string> {
		const selectionStore = this.selectionStore;
		const values = selectionStore.fieldSelectionLabels;
		return values;
	}

	@computed
	get validDisclaimerTypes(): string[] {
		if (this.storeContext) {
			const values = this.selectionStore.values;

			return this.configuration
				.filter((disclaimer) => {
					if (disclaimer.conditional) {
						const conditionalHelper = new common.helpers.ConditionalHelper(
							{ queryClass: query.Query },
							disclaimer.conditional,
						);
						return conditionalHelper.matchesWithOptions({
							instance: values,
							executionOptions: this.queryStore.executionOptions,
						});
					}
					return true;
				})
				.map((disclaimer) => disclaimer.type);
		}
		return [];
	}

	// Set up all disclaimer types for Attestation
	@computed
	get allDisclaimerTypes(): string[] {
		const disclaimerTypes = this.configuration.map((disclaimer) => disclaimer.type);
		if (this.footnoteDisclaimerType) {
			disclaimerTypes.push(this.footnoteDisclaimerType);
		}
		return disclaimerTypes;
	}

	@computed
	get footnoteDisclaimerType(): string | undefined {
		if (
			this.footnoteConfiguration &&
			this.footnoteConfiguration.text &&
			typeof this.footnoteConfiguration.text !== 'string'
		) {
			return this.footnoteConfiguration.text.type;
		}
	}

	@action
	async loadDisclaimers() {
		const DEFAULT_KEY = 'Default';
		if (this.storeContext) {
			this.disclaimerRecords = [];

			if (this.allDisclaimerTypes && this.allDisclaimerTypes.length > 0) {
				const culture = this.translationStore.culture;
				const options = {
					body: {
						disclaimerType: this.allDisclaimerTypes,
					},
				};
				const allDisclaimerRecords = await this.kurtosysApiStore.client.disclaimers.list.execute(options);
				this.disclaimerRecords = allDisclaimerRecords.map((disclaimerRecord) => {
					const { disclaimers } = disclaimerRecord;
					if (disclaimers && disclaimers.length > 1) {
						const cultureRecord = disclaimers.find((disclaimer) => disclaimer.culture === culture);
						if (cultureRecord) {
							disclaimerRecord.disclaimers = [cultureRecord];
						} else {
							const defaultRecord = disclaimers.find((disclaimer) => disclaimer.culture === DEFAULT_KEY);
							if (defaultRecord) {
								disclaimerRecord.disclaimers = [defaultRecord];
							} else {
								disclaimerRecord.disclaimers = [disclaimerRecord.disclaimers[0]];
							}
						}
					}
					return disclaimerRecord;
				});
			} else {
				this.disclaimerRecords = [];
			}
		}
	}

	@computed
	get footnoteDisclaimer() {
		const DEFAULT_KEY = 'Default';
		if (this.storeContext) {
			if (this.footnoteDisclaimerType) {
				const culture = this.translationStore.culture;
				const filteredDisclaimers = this.disclaimerStore.disclaimerRecords.filter(
					(disclaimerRecord) => disclaimerRecord.disclaimerType === this.footnoteDisclaimerType,
				);
				const disclaimerRecords = filteredDisclaimers
					.map((disclaimerRecord) => {
						const { disclaimers } = disclaimerRecord;
						if (disclaimers && disclaimers.length > 1) {
							const cultureRecord = disclaimers.find((disclaimer) => disclaimer.culture === culture);
							if (cultureRecord) {
								disclaimerRecord.disclaimers = [cultureRecord];
							} else {
								const defaultRecord = disclaimers.find(
									(disclaimer) => disclaimer.culture === DEFAULT_KEY,
								);
								if (defaultRecord) {
									disclaimerRecord.disclaimers = [defaultRecord];
								} else {
									disclaimerRecord.disclaimers = [disclaimerRecord.disclaimers[0]];
								}
							}
						}
						return disclaimerRecord.disclaimers;
					})
					.flat(1)
					.map((flatDisclaimer: any) => {
						return flatDisclaimer.disclaimer;
					});

				if (!isNullOrEmpty(disclaimerRecords)) {
					return disclaimerRecords[0];
				}
			}
		}
	}
}
