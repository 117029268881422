export const asOfDateStyle = {
    children: {
        title: {
            display: 'block',
            height: '18px',
            color: '#C21B17',
            font: {
                family: 'Roboto Medium, Arial',
                size: '16px',
            },
            letterSpacing: '0.22px',
            lineHeight: '18px',
            text: {
                transform: 'uppercase',
            },
        },
        dateWrapper: {
            height: '15px',
            color: '#9B9B9B',
            font: {
                family: 'Helvetica Neue',
                size: '12px',
            },
            letterSpacing: '-0.1px',
            lineHeight: '15px',
            display: 'block',
        },
    },
};
