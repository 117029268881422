import BlockRangeSeriesPointBottom from '../../assets/images/BlockRangeSeriesPointBottom.svg';
import BlockRangeSeriesPointTop from '../../assets/images/BlockRangeSeriesPointTop.svg';
/* [AddAsset: import] */
export const chartingAssets = {
    blockRangeChart: {
        seriesPointBottom: BlockRangeSeriesPointBottom,
        seriesPointTop: BlockRangeSeriesPointTop,
        /* [AddAsset: blockRangeChart] */
    },
    /* [InitComponentAsset: assetKey] */
};
