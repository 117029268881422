import React, { Component } from 'react';
import { components } from '@kurtosys/ksys-app-components';
import { common } from '@kurtosys/ksys-app-template';
import { inject, observer } from 'mobx-react';

import Acceptance from '../Acceptance/Acceptance.js';
import AcceptanceDisclaimer from '../AcceptanceDisclaimer/AcceptanceDisclaimer.js';
import CallToAction from '../CallToAction/CallToAction.js';
import Disclaimer from '../Disclaimer/Disclaimer.js';
import Footnote from '../Footnote/Footnote.js';
import Links from '../Links/Links.js';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent.js';

import ActiveNavigatorItem from './styledComponents/ActiveNavigatorItem.js';
import Chevron from './styledComponents/Chevron.js';
import Confirmation from './styledComponents/Confirmation.js';
import Content from './styledComponents/Content.js';
import DisabledNavigatorItem from './styledComponents/DisabledNavigatorItem.js';
import Navigator from './styledComponents/Navigator.js';
import NavigatorItem from './styledComponents/NavigatorItem.js';
import NavigatorItemChevron from './styledComponents/NavigatorItemChevron.js';
import Option from './styledComponents/Option.js';
import Options from './styledComponents/Options.js';
import Wrapper from './styledComponents/Wrapper.js';
import { ISelectionWizardProps, ISelectionWizardState } from './models';

const { isNullOrUndefined } = common.commonUtils;
const Text = components.base.Text.Text;
const Translate = components.base.Translate.Translate;

class RawSelectionWizard extends Component<ISelectionWizardProps, ISelectionWizardState> {
	static configurationKey = 'selectionWizard' as const;
	static styleKey = 'selectionWizard' as const;
	constructor(props: ISelectionWizardProps) {
		super(props);
	}
	render() {
		const { className, selectionWizardStore, selectionStore, translationStore } = this.props;

		if (!selectionWizardStore || !selectionStore || !translationStore) {
			return null;
		}
		const {
			navigatorFields,
			options,
			handleOptionSelect,
			handleNavigatorItemSelect,
			selectedField,
			selectedFieldKey,
		} = selectionWizardStore;

		if (selectedField) {
			const { autoSelectSingleOption } = selectedField;
			if (autoSelectSingleOption && !isNullOrUndefined(options)) {
				const autoSelectOptions = options.filter((option) => !isNullOrUndefined(option.value));
				if (autoSelectOptions && autoSelectOptions?.length === 1) {
					handleOptionSelect(autoSelectOptions[0].value);
				}
			}
		}

		return (
			<Wrapper className={className}>
				<Navigator>
					{(navigatorFields || []).map((navigatorField) => {
						const { label, key, disabled = false } = navigatorField;
						const valueLabel = selectionStore.getLabel(navigatorField);
						const rawDisplayText = (isNullOrUndefined(valueLabel) ? label : valueLabel) || key;
						const displayText = translationStore.translate(rawDisplayText);

						const Component =
							navigatorField.key === selectedFieldKey
								? ActiveNavigatorItem
								: disabled
								? DisabledNavigatorItem
								: NavigatorItem;
						// eslint-disable-next-line @typescript-eslint/no-empty-function
						const onClick = !disabled ? handleNavigatorItemSelect : () => {};
						return (
							<Component key={key} onClick={() => onClick(navigatorField)}>
								<span dangerouslySetInnerHTML={{ __html: displayText }}></span>
								{!disabled && <NavigatorItemChevron />}
							</Component>
						);
					})}
				</Navigator>
				<Content>
					{!selectionStore.hasAllSelections && selectedField && (
						<Options>
							{(options || []).map((option) => {
								const { key, value } = option;
								const descriptionTextProps = selectionStore.getDescriptionTextProps(
									option,
									selectedField,
								);
								// If we have any toggleButtons, default their suppressAnalytics to true (so it needs
								// to be explicitly supplied as false to get the default analytics)
								if (descriptionTextProps && descriptionTextProps.toggleButtons) {
									if (descriptionTextProps.toggleButtons.expand.suppressAnalytics === undefined) {
										descriptionTextProps.toggleButtons.expand.suppressAnalytics = true;
									}
									if (descriptionTextProps.toggleButtons.collapse.suppressAnalytics === undefined) {
										descriptionTextProps.toggleButtons.collapse.suppressAnalytics = true;
									}
								}
								return (
									<Option onClick={() => handleOptionSelect(value)} key={value}>
										<Translate>{key}</Translate>
										<Chevron />
										{descriptionTextProps && <Text {...descriptionTextProps} />}
									</Option>
								);
							})}
						</Options>
					)}
					{selectionStore.hasAllSelections && (
						<Confirmation>
							<AcceptanceDisclaimer />
							<Disclaimer />
							<Links />
							<Acceptance />
							<CallToAction />
						</Confirmation>
					)}
					<Footnote />
				</Content>
			</Wrapper>
		);
	}
}

export const SelectionWizard = inject(
	'appStore',
	'selectionWizardStore',
	'selectionStore',
	'translationStore',
)(observer(RawSelectionWizard));
export default InjectedStyledComponent(SelectionWizard, 'selectionWizard');
