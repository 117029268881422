import React, { Component } from 'react';
import { inject } from 'mobx-react';

import { IAppStoreInjection } from '../../../models/app/IAppStoreInjection.js';
import { IImage } from '../../../models/app/IImage.js';
import { InjectedStyledComponent } from '../InjectedStyledComponent.js';

export interface ImageProps extends IImage, IAppStoreInjection {}

class RawImage extends Component<ImageProps> {
	static styleKey = 'image' as const;
	constructor(props: ImageProps) {
		super(props);
	}

	render() {
		const {
			appStore,
			className,
			src,
			prefixBaseUrl = true,
			// tslint:disable-next-line
			...props
		} = this.props;
		return (
			<img
				className={className}
				src={appStore && prefixBaseUrl ? `${appStore.assetsBaseUrl}${src}` : `${src}`}
				{...props}
			/>
		);
	}
}

export const Image = inject('appStore')(RawImage);
export default InjectedStyledComponent(Image, 'shared', ['image']);
