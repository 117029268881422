export const rangeChartStyle = {
    children: {
        wrapper: {},
        innerWrapper: {
            margin: {
                left: '20px',
                right: '20px',
            },
        },
        bar: {
            width: '100%',
            position: 'relative',
            clear: 'both',
            height: '16px',
            margin: {
                top: '8px',
                bottom: '8px',
            },
            border: {
                _value: 'solid 1px #dddddd',
            },
        },
        axis: {
            width: '100%',
            position: 'relative',
        },
        axisTick: {
            position: 'absolute',
            transform: 'translate(-50%, 0)',
        },
        point: {
            position: 'absolute',
            transform: 'translate(-50%, 0)',
            border: {
                radius: '50%',
                style: 'solid',
                width: '1px',
                color: '#dddddd',
            },
            width: '28px',
            height: '28px',
            top: '-7px',
        },
        pointContent: {
            border: {
                radius: '50%',
                style: 'solid',
                width: '6px',
                color: '#ffffff',
            },
            width: '16px',
            height: '16px',
            background: {
                color: 'transparent',
            },
        },
    },
};
