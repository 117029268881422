export const riskRatingStyle = {
    children: {
        ratings: {
            border: {
                right: { _value: 'solid 1px #ADB5BD' },
                collapse: 'collapse',
            },
            display: 'flex',
            flex: {
                direction: 'row',
            },
            height: '45px',
        },
        activeRatingBox: {
            background: {
                color: '#0078C0',
            },
            color: '#FFFFFF',
            text: {
                align: 'center',
            },
            flex: {
                grow: '1',
            },
            lineHeight: '45px',
            border: {
                _value: 'solid 1px #0078C0',
                collapse: 'collapse',
            },
        },
        ratingBox: {
            border: {
                _value: 'solid 1px #ADB5BD',
                right: { _value: 'none' },
                collapse: 'collapse',
            },
            text: {
                align: 'center',
            },
            flex: {
                grow: '1',
            },
            lineHeight: '45px',
        },
        topLabels: {
            display: 'flex',
            flex: {
                direction: 'row',
            },
        },
        bottomLabels: {
            display: 'flex',
            flex: {
                direction: 'row',
            },
        },
        leftLabel: {
            flex: {
                grow: '1',
            },
            text: {
                align: 'left',
            },
        },
        rightLabel: {
            flex: {
                grow: '1',
            },
            text: {
                align: 'right',
            },
        },
    },
};
