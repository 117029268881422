import React from 'react';
import { observer } from 'mobx-react';
import { ComponentBase } from '../../../shared/ComponentBase.js';
import { InjectedStyledComponent } from '../../../shared/InjectedStyledComponent.js';
import Wrapper from './styledComponents/Wrapper.js';
import { VISUALLYHIDDEN_CATEGORY_KEY, VISUALLYHIDDEN_COMPONENT_KEY } from './VisuallyHidden.meta.js';
export class VisuallyHidden extends ComponentBase {
    constructor(props) {
        super(props);
        this.categoryKey = VISUALLYHIDDEN_CATEGORY_KEY;
        this.componentKey = VISUALLYHIDDEN_COMPONENT_KEY;
    }
    render() {
        const { className, children } = this.props;
        return (React.createElement(Wrapper, { className: className, style: {
                position: 'absolute !important',
                height: '1px',
                width: '1px',
                overflow: 'hidden',
                clip: 'rect(1px, 1px, 1px, 1px)',
                whiteSpace: 'nowrap',
            } }, children));
    }
}
export default InjectedStyledComponent(observer(VisuallyHidden), VISUALLYHIDDEN_CATEGORY_KEY, VISUALLYHIDDEN_COMPONENT_KEY);
