export const chartBaseStyle = {
    height: '500px',
    children: {
        defaultLegendContent: {
            color: '#777',
        },
        chartSummary: {
            text: {
                align: 'center',
            },
        },
    },
};
