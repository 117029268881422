import { InjectedStyledComponent } from '../../shared/InjectedStyledComponent.js';

function rawCssPrefix(props: unknown): string {
	return `

	`;
}

export default InjectedStyledComponent(
	'div',
	'disclaimer',
	['disclaimerWrapper'],
	['appStore'],
	rawCssPrefix,
	undefined,
);
