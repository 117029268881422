export const numberPickerStyle = {
    children: {
        wrapper: {
            max: {
                height: '300px',
            },
            overflow: {
                _value: 'auto',
            },
            display: 'flex',
            flex: {
                direction: 'column',
            },
        },
        value: {
            color: 'rgb(58, 64, 81)',
            font: {
                size: '17px',
            },
            lineHeight: '35px',
            width: '100%',
            text: {
                align: 'center',
            },
            selectors: {
                ':hover': {
                    color: 'rgb(26, 128, 255)',
                },
            },
        },
        selectedValue: {
            lineHeight: '35px',
            width: '100%',
            text: {
                align: 'center',
            },
            font: {
                size: '26px',
                weight: '500',
            },
            color: 'rgb(26, 128, 255)',
        },
    },
};
