export const toasterStyle = {
    children: {
        wrapper: {
            position: 'fixed',
            bottom: '0',
            left: '0',
            right: '0',
            padding: {
                _value: '4px',
            },
        },
        toastWrapper: {
            margin: {
                bottom: '4px',
            },
        },
    },
};
