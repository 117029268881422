export const morningStarStyle = {
    display: 'flex',
    flex: {
        wrap: 'wrap',
    },
    justifyContent: 'center',
    width: '100%',
    children: {
        morningStarIconWrapper: {
            min: {
                width: '24px',
            },
        },
    },
};
