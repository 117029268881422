export function getComponentStyle(theme, categoryKey, componentKey, ...childKeys) {
    let response = undefined;
    if (theme) {
        const category = (theme || {})[categoryKey];
        if (category) {
            response = category[componentKey];
            if (childKeys) {
                for (const childKey of childKeys) {
                    if (response && response.children && response.children[childKey]) {
                        response = response.children[childKey];
                    }
                    else {
                        response = undefined;
                        break;
                    }
                }
            }
        }
    }
    return response;
}
