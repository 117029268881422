export const infoMessageStyle = {
    text: {
        align: 'center',
    },
    box: {
        sizing: 'inherit',
    },
    font: {
        family: '"DM Sans", sans-serif',
        weight: '400',
        size: '13px',
    },
    color: '#D6D9E1',
    margin: {
        _value: '0px',
    },
};
