export const awardsStyle = {
    children: {
        wrapper: {
            overrides: [
                {
                    selector: '[data-selector="base-carousel-dots"]',
                    style: {
                        display: 'none',
                    },
                },
                {
                    selector: '[data-selector="overview-award-wrapper"]',
                    style: {
                        text: {
                            align: 'left',
                        },
                    },
                },
            ],
        },
        awardWrapper: {
            display: 'inline-flex',
            padding: {
                bottom: '10px',
            },
            margin: {
                right: '20px',
            },
        },
    },
};
