import React, { Component } from 'react';
import { components } from '@kurtosys/ksys-app-components';
import { inject, observer } from 'mobx-react';

import { InjectedStyledComponent } from '../shared/InjectedStyledComponent.js';

import Wrapper from './styledComponents/Wrapper.js';
import { ICallToActionProps, ICallToActionState } from './models';

const Button = components.base.Button.Button;
const Translate = components.base.Translate.Translate;

class RawCallToAction extends Component<ICallToActionProps, ICallToActionState> {
	static configurationKey = 'callToAction' as const;
	static styleKey = 'callToAction' as const;
	constructor(props: ICallToActionProps) {
		super(props);
	}

	componentDidUpdate(): void {
		const { appStore } = this.props;
		appStore.setCtaAriaLabelText();
	}

	render() {
		const { callToActionStore, appStore } = this.props;
		if (!callToActionStore || !appStore || !appStore.hasSelectionsAndDisclaimers) {
			return null;
		}
		const { text, rejectText, order, disabled, handleClick, handleRejectClick } = callToActionStore;
		const { ctaAriaLabelText } = appStore;
		const rejectButton = (
			<Button onClick={handleRejectClick} suppressAnalytics={true}>
				<Translate>{rejectText}</Translate>
			</Button>
		);
		const acceptButton = (
			<Button
				aria-label={ctaAriaLabelText}
				disabled={disabled}
				onClick={handleClick}
				analyticsContext={appStore.selectedAttestationContext}
			>
				<Translate>{text}</Translate>
			</Button>
		);
		return (
			<Wrapper>
				{rejectText && order === 'rejectThenAccept' && rejectButton}
				{acceptButton}
				{rejectText && order === 'acceptThenReject' && rejectButton}
			</Wrapper>
		);
	}
}

export const CallToAction = inject('appStore', 'callToActionStore')(observer(RawCallToAction));
export default InjectedStyledComponent(CallToAction, 'callToAction');
