export const barScaleStyle = {
    children: {
        wrapper: {
            display: 'block',
            margin: {
                _value: '0',
            },
            padding: {
                _value: '0',
            },
        },
    },
};
