export const carouselStyle = {
    children: {
        wrapper: {
            overrides: [
                {
                    selector: '[data-selector="base-carousel-componentWrapper"] div[data-selector="base-carousel-pane"]',
                    style: {
                        width: '300px',
                        display: 'inline-block',
                        background: {
                            color: '#efefef',
                        },
                        padding: {
                            _value: '10px',
                        },
                        margin: {
                            _value: '2px',
                            bottom: '10px',
                        },
                    },
                },
            ],
            position: 'relative',
        },
        controlsWrapper: {
            position: 'absolute',
            width: '100%',
        },
        content: {
            display: 'flex',
        },
        navWrapper: {
            position: 'absolute',
            padding: {
                _value: '4px',
            },
            margin: {
                top: '20px',
            },
            border: {
                _value: 'none',
            },
            background: {
                color: 'transparent',
            },
            width: '30px',
            height: '30px',
            text: {
                align: 'center',
            },
        },
        navLeft: {
            left: '0px',
        },
        navRight: {
            right: '0px',
        },
        componentWrapper: {
            transition: {
                _value: 'all 300ms ease-in-out',
            },
            text: {
                align: 'center',
            },
        },
        dot: {
            width: '16px',
            height: '16px',
            background: {
                color: '#DDDDDD',
            },
            border: {
                radius: '50%',
            },
            conditionals: [
                {
                    conditional: {
                        conditions: [
                            {
                                field: 'selected',
                                operator: '=',
                                value: true,
                            },
                        ],
                    },
                    style: {
                        background: {
                            color: 'blue',
                        },
                    },
                },
            ],
        },
        selectedDot: {
            width: '16px',
            height: '16px',
            background: {
                color: 'blue',
            },
            border: {
                radius: '50%',
            },
        },
        dotWrapper: {
            padding: {
                left: '10px',
                right: '10px',
            },
        },
        dots: {
            display: 'flex',
            justifyContent: 'center',
            transform: 'translate(0px, 85px)',
        },
    },
};
