export const scaleLegendStyle = {
    children: {
        wrapper: {
            width: '300px',
            overrides: [
                {
                    selector: '[data-selector="base-list-content"]',
                    style: {
                        flex: {
                            _value: '1 1 50%',
                        },
                    },
                },
                {
                    selector: '[data-selector="base-list-multipartListItem"]',
                    style: {
                        border: {
                            bottom: {
                                _value: 'none',
                            },
                        },
                    },
                },
            ],
        },
    },
};
