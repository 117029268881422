export const buttonFlatStyle = {
    box: { sizing: 'inherit' },
    font: {
        weight: '400',
        size: '13px',
        family: '"DM Sans", sans-serif',
    },
    display: 'flex',
    flex: { shrink: '0' },
    justifyContent: 'center',
    align: { items: 'center' },
    cursor: 'pointer',
    lineHeight: '24px',
    border: {
        width: '0px',
        style: 'initial',
        color: 'initial',
    },
    background: {
        _value: 'none',
    },
    margin: {
        _value: '0px auto',
    },
    padding: {
        _value: '5px 10px',
    },
    overrides: [
        {
            selector: 'img',
            style: {
                margin: {
                    right: '4px',
                },
            },
        },
    ],
};
