export const confirmDialogStyle = {
    children: {
        wrapper: {
            width: '35vw',
        },
        header: {
            color: 'rgb(58, 64, 81)',
            font: {
                size: '22px',
                weight: '400',
            },
            lineHeight: '32px',
        },
        message: {
            color: 'rgba(58, 64, 81, 0.6)',
            font: {
                size: '16px',
            },
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            padding: {
                bottom: '10px',
            },
            text: {
                align: 'right',
            },
        },
        confirmButton: {
            color: 'rgb(26, 128, 255)',
            font: {
                size: '13px',
                weight: '500',
            },
            margin: {
                _value: '0',
            },
            selectors: {
                ':hover': {
                    background: {
                        color: 'rgb(243, 244, 248)',
                    },
                },
                ':focus': {
                    outline: {
                        color: 'none',
                        style: 'none',
                    },
                    box: {
                        shadow: 'none',
                    },
                },
            },
        },
        cancelButton: {
            color: 'rgb(26, 128, 255)',
            font: {
                size: '13px',
                weight: '500',
            },
            margin: {
                _value: '0',
            },
            selectors: {
                ':hover': {
                    background: {
                        color: 'rgb(243, 244, 248)',
                    },
                },
                ':focus': {
                    outline: {
                        color: 'none',
                        style: 'none',
                    },
                    box: {
                        shadow: 'none',
                    },
                },
            },
        },
    },
};
