export const radialChartStyle = {
    font: {
        family: 'Roboto, Arial',
        size: '11px',
    },
    color: '#000000',
    height: '300px',
    overrides: [
        {
            selector: '[data-selector="charting-radialBarChart-wrapper"]',
            style: {
                height: '400px',
            },
        },
    ],
};
