import React, { Component } from 'react';
import { common } from '@kurtosys/ksys-app-template';
import { inject, observer } from 'mobx-react';

import { InjectedStyledComponent } from '../shared/InjectedStyledComponent.js';

import { ILinkProps } from './models/ILinkProps.js';
import Link from './styledComponents/Link.js';
import Wrapper from './styledComponents/Wrapper.js';
import { ILinksProps, ILinksState } from './models';

const { isNullOrEmpty } = common.commonUtils;

class RawLinks extends Component<ILinksProps, ILinksState> {
	static configurationKey = 'links' as const;
	static styleKey = 'links' as const;

	constructor(props: ILinksProps) {
		super(props);
	}

	render() {
		const { className, linksStore } = this.props;
		if (!linksStore) {
			return null;
		}

		const { links, show } = linksStore;

		if (!show || isNullOrEmpty(links)) {
			return null;
		}

		return (
			<Wrapper className={className}>
				{links.map((link: ILinkProps) => {
					return (
						<Link key={link.text} href={link.href} target={link.target}>
							{link.text}
						</Link>
					);
				})}
			</Wrapper>
		);
	}
}

export const Links = inject('appStore', 'linksStore')(observer(RawLinks));
export default InjectedStyledComponent(Links, 'links');
