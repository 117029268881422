export const tooltipStyle = {
    children: {
        wrapper: {
            display: 'inline-block',
            verticalAlign: 'text-bottom',
            overrides: [
                {
                    selector: '[data-selector="base-tooltip-arrowIconWrapper"]',
                    style: {
                        position: 'absolute',
                    },
                },
                {
                    selector: 'div[data-placement="top"] [data-selector="base-tooltip-arrowIconWrapper"]',
                    style: {
                        bottom: '-14px',
                    },
                },
                {
                    selector: 'div[data-placement="bottom"] [data-selector="base-tooltip-arrowIconWrapper"]',
                    style: {
                        top: '-13px',
                        transform: 'rotate(180deg)',
                    },
                },
                {
                    selector: 'div[data-placement="left"] [data-selector="base-tooltip-arrowIconWrapper"]',
                    style: {
                        right: '-11px',
                        transform: 'rotate(-90deg)',
                    },
                },
                {
                    selector: 'div[data-placement="right"] [data-selector="base-tooltip-arrowIconWrapper"]',
                    style: {
                        left: '-11px',
                        transform: 'rotate(90deg)',
                    },
                },
            ],
        },
        tooltipIconButton: {
            cursor: 'pointer',
            position: 'sticky',
        },
        tooltipCharacter: {
            font: {
                size: '9px',
            },
            min: {
                width: '1.5em',
            },
            margin: {
                _value: '0 0.5em',
            },
            padding: {
                top: '0.2em',
                bottom: '0.2em',
                left: '0.5em',
                right: '0.5em',
            },
            color: 'white',
            background: {
                color: '#1EA7FD',
            },
            border: {
                radius: '1.5em',
            },
            text: {
                align: 'center',
            },
        },
        tooltipIcon: {
            width: '16px',
            height: '16px',
        },
        tooltipContent: {
            max: {
                width: '250px',
            },
            background: {
                color: 'white',
            },
            border: {
                style: 'solid',
                width: '1px',
                color: '#cccccc',
                radius: '3px',
            },
            box: {
                shadow: '2px 2px 0 rgba(0,0,0,0.1)',
                sizing: 'border-box',
            },
            padding: {
                _value: '4px',
            },
            font: {
                size: 'smaller',
            },
            text: {
                align: 'center',
            },
            margin: {
                _value: '3px',
                bottom: '2px',
            },
        },
        clickLayer: {
            position: 'fixed',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
            background: {
                color: 'transparent',
            },
        },
        arrowIcon: {
            fill: 'white',
            stroke: '#cccccc',
        },
    },
};
