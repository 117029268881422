export const documentTileStyle = {
    children: {
        wrapper: {
            cursor: 'pointer',
            width: '10%',
            margin: {
                _value: '0 auto',
            },
            border: {
                _value: 'solid 1px black',
            },
            text: {
                align: 'center',
            },
        },
        label: {
            display: 'block',
        },
    },
};
