import { common, query } from '@kurtosys/ksys-app-template';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { theme as defaultTheme } from '../assets/theme.js';
import { getComponentClassName } from './getComponentClassName.js';
import { getComponentStyle } from './getComponentStyle.js';
import { getSelector } from './selector.js';
import { withConfiguration } from './withConfiguration.js';
import { withSelector } from './withSelector.js';
// TODO: Update InjectedStyledComponent to accept an options object, instead of using a growing list of function parameter - this also provides a clearer picture of the params being passed.
/**
 * Applies observer (mobx), styled (styled-components), withConfiguration (internal) & withSelector (internal) HOCs
 *
 * @export
 * @template C
 * @param componentOrElement
 * @param categoryKey
 * @param componentKey
 * @param [childStyleKeys]
 * @param [defaultCss] DEPRECATED
 * @param [rawCssSuffix] DEPRECATED
 * @param defaultStyle
 * @returns
 */
export function InjectedStyledComponent(componentOrElement, categoryKey, componentKey, childStyleKeys, defaultCss, // TODO: Remove in favour of using the componentOrElement to derive the defaultStyles css
rawCssSuffix, // TODO: Remove rawCssSuffix, which includes updating all implementations
defaultStyle) {
    if (typeof componentOrElement !== 'string') {
        componentOrElement = withConfiguration(componentOrElement);
    }
    const selector = getSelector(categoryKey, componentKey, ...(childStyleKeys || []));
    const className = getComponentClassName(categoryKey, componentKey, ...(childStyleKeys || []));
    const response = styled(componentOrElement) `
		${(props) => {
        const { theme = defaultTheme } = props;
        const componentStyle = getComponentStyle(theme, categoryKey, componentKey, ...(childStyleKeys || []));
        const css = new common.commonUtils.ComponentStyle({ queryClass: query.Query }).getCss(componentStyle, props);
        if (css) {
            return css;
        }
        /**
         * defaultStyle uses the our style config for the component as a default white label style, if provided to this function.
         * Currently we are checking for defaultCss, as this is how we have previously done it.
         *
         * Note: we only apply the default white label styles if the app doesn't provide a theme style for the css as seen above.
         *
         * TODO: remove defaultCss & rawCssSuffix from all implementations of InjectedStyledComponent
         */
        if (!defaultCss && defaultStyle) {
            defaultCss = new common.commonUtils.ComponentStyle({ queryClass: query.Query }).getCss(defaultStyle, props);
        }
        return defaultCss || '';
    }}
	`;
    return observer(withSelector(response, selector, className));
}
