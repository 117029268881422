import ChangeDownIcon from '../../assets/images/ChangeDownIcon.svg';
import ChangeUpIcon from '../../assets/images/ChangeUpIcon.svg';
/* [AddAsset: import] */
export const overviewAssets = {
    changeIndicator: {
        changeUpIcon: ChangeUpIcon,
        changeDownIcon: ChangeDownIcon,
        /* [AddAsset: changeIndicator] */
    },
    /* [InitComponentAsset: assetKey] */
};
