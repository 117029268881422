export const scatterChartStyle = {
    children: {
        wrapper: {
            overrides: [
                {
                    selector: '.recharts-legend-wrapper',
                    style: {
                        padding: {
                            left: '10px',
                        },
                    },
                },
                {
                    selector: '.weight-text',
                    style: {
                        font: {
                            weight: 'bold',
                            size: '18px',
                        },
                    },
                },
                {
                    selector: '.stature-text',
                    style: {
                        font: {
                            weight: 'bold',
                            size: '18px',
                        },
                    },
                },
            ],
        },
    },
};
