import { stores } from '@kurtosys/app-start';
import { common, query } from '@kurtosys/ksys-app-template';
import { action, computed, makeObservable, observable, reaction } from 'mobx';

import { IComponentStyles } from '../../../models/app/IComponentStyles.js';
import { IConfiguration } from '../../../models/app/IConfiguration.js';
import { ISelectionOption } from '../../../models/app/ISelectionOption.js';
import { TStoreContext } from '../../../models/app/TStoreContext.js';
import { AcceptanceStore } from '../../Acceptance/stores/AcceptanceStore.js';
import { AppStore } from '../../App/stores/AppStore.js';
import { SelectionStore } from '../../Selection/stores/SelectionStore.js';
import { ISelectionWizardConfiguration } from '../models';
import { ISelectionWizardField } from '../models/ISelectionWizardField.js';

type QueryStore = stores.QueryStore<IConfiguration, IComponentStyles>;
import { IManifest } from '@kurtosys/types/appsManager/index.js';

const { sortBy, isNullOrUndefined } = common.commonUtils;
const { ConditionalHelper } = common.helpers;

export class SelectionWizardStore extends stores.base.StoreBase<IConfiguration, IComponentStyles> {
	static componentKey = 'selectionWizard' as const;
	@observable selectedFieldKey: string | undefined;
	@observable.ref activeFields: ISelectionWizardField[] = [];

	constructor(storeContext: TStoreContext, manifest: IManifest) {
		super(storeContext, manifest);
		makeObservable(this);
	}

	@computed
	get appStore(): AppStore {
		return this.storeContext.get<AppStore>('appStore');
	}

	@computed
	get queryStore(): QueryStore {
		return this.storeContext.get<QueryStore>('queryStore');
	}

	@computed
	get selectionStore(): SelectionStore {
		return this.storeContext.get<SelectionStore>('selectionStore');
	}

	@computed
	get acceptanceStore(): AcceptanceStore {
		return this.storeContext.get<AcceptanceStore>('acceptanceStore');
	}

	@computed
	get configuration(): ISelectionWizardConfiguration | undefined {
		if (this.appStore) {
			return this.appStore.getComponentConfiguration(SelectionWizardStore.componentKey);
		}
	}

	@action
	async initialize(): Promise<void> {
		if (this.configuration) {
			this.loadActiveFields();
			reaction(
				() => this.selectionStore.hasAllSelections,
				(hasAllSelections) => {
					if (hasAllSelections) {
						this.selectedFieldKey = undefined;
					}
				},
			);
		}
	}

	@computed
	get selectedField(): ISelectionWizardField | undefined {
		const navigatorFields = this.navigatorFields || [];
		if (this.selectedFieldKey) {
			const existingField = navigatorFields.find(
				(navigatorField) => navigatorField.key === this.selectedFieldKey,
			);
			if (existingField) {
				return existingField;
			}
		}
		// Return the first defined field
		return navigatorFields.find((navigatorField) => !!navigatorField);
	}

	@computed
	get options(): ISelectionOption[] {
		const field = this.selectedField;
		let options: ISelectionOption[] = [];
		if (this.selectionStore && field) {
			options = this.selectionStore.getOptions(field);
		}
		return options;
	}

	@computed
	get fields(): ISelectionWizardField[] {
		let response: ISelectionWizardField[] = [];
		if (this.configuration && this.configuration.fields) {
			response = this.configuration.fields;
		}
		response = sortBy(response, (field) => (field.order || 99999).toString());
		return response;
	}

	@computed
	get navigatorFields(): ISelectionWizardField[] {
		const activeFields = this.activeFields;
		const navigatorFields: ISelectionWizardField[] = [];
		const currentIndex = this.selectedFieldActiveFieldIndex;
		for (let index = 0; index < activeFields.length; index++) {
			const field = activeFields[index];
			const fieldResponse = this.selectionStore.getSelectedFieldOption(field);
			if (index === currentIndex || !isNullOrUndefined(fieldResponse.value)) {
				const { autoSelectSingleOption } = field;
				if (autoSelectSingleOption) {
					const fieldOptions = this.selectionStore.getOptions(field);
					field.disabled = fieldOptions.length === 1;
				}
				navigatorFields.push(field);
			}
		}
		return navigatorFields;
	}

	@action
	loadActiveFields(): void {
		const values = this.selectionStore.values || {};
		const fields = this.fields;
		const activeFields = [];
		for (const field of fields) {
			const { conditional } = field;
			// Check if the field should be conditional on other values
			if (conditional) {
				const conditionalHelper = new ConditionalHelper({ queryClass: query.Query }, conditional);
				if (
					conditionalHelper.matchesWithOptions({
						instance: values,
						executionOptions: this.queryStore.executionOptions,
					})
				) {
					activeFields.push(field);
				}
			} else {
				activeFields.push(field);
			}
		}
		this.activeFields = activeFields;
		this.selectionStore.fields = this.activeFields;
		if (
			!this.selectionStore.hasAllSelections &&
			!this.selectedFieldKey &&
			activeFields &&
			activeFields.length > 0
		) {
			this.selectedFieldKey = activeFields[0].key;
		}
	}

	@computed
	get selectedFieldActiveFieldIndex(): number {
		return (this.activeFields || []).findIndex((activeField) => activeField.key === this.selectedFieldKey);
	}

	@action
	handleOptionSelect = (value: any): void => {
		if (this.selectedField) {
			this.selectionStore.setValue(this.selectedField, value);
			this.loadActiveFields();
			const activeFields = this.activeFields;
			const currentIndex = this.selectedFieldActiveFieldIndex;
			if (currentIndex + 1 < activeFields.length) {
				this.selectedFieldKey = activeFields[currentIndex + 1].key;
			} else {
				this.selectedFieldKey = undefined;
			}
		}
	};

	@action
	handleNavigatorItemSelect = (field: ISelectionWizardField): void => {
		if (this.selectedField) {
			this.selectionStore.setValue(field, undefined);
			this.loadActiveFields();
			this.selectedFieldKey = field.key;
			this.acceptanceStore.acceptedItems = [];
		}
	};

	// Check if all selections are made for the wizard

	//
}
