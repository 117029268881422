import React, { Component } from 'react';
import { components } from '@kurtosys/ksys-app-components';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';

import { InjectedStyledComponent } from '../shared/InjectedStyledComponent.js';

import Button from './styledComponents/Button.js';
import ButtonLabel from './styledComponents/ButtonLabel.js';
import ButtonToggle from './styledComponents/ButtonToggle.js';
import Icon from './styledComponents/Icon.js';
import List from './styledComponents/List.js';
import ListItem from './styledComponents/ListItem.js';
import Menu from './styledComponents/Menu.js';
import Text from './styledComponents/Text.js';
import Wrapper from './styledComponents/Wrapper.js';
import { IIconDropdownProps, IIconDropdownState } from './models';

const Translate = components.base.Translate.Translate;
const ClickLayer = components.base.ClickLayer.ClickLayer;

class RawIconDropdown extends Component<IIconDropdownProps, IIconDropdownState> {
	static configurationKey = 'iconDropdown' as const;
	static styleKey = 'iconDropdown' as const;

	@observable isOpen = false;

	toggleOpen = () => {
		this.isOpen = !this.isOpen;
	};
	setSelectedItem = (value: any) => {
		if (this.props.onSelectItem) {
			this.props.onSelectItem(value);
		}
		this.isOpen = false;
	};
	onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		if (this.props.onChange) {
			this.props.onChange(event);
		}
		this.isOpen = false;
	};

	constructor(props: IIconDropdownProps) {
		super(props);
	}

	render() {
		const { className, iconDropdownStore, options = [], value, field } = this.props;

		if (!iconDropdownStore) {
			return null;
		}

		const selectedItem = options.find((option) => option.value === value);
		const { iconAlignment } = iconDropdownStore;
		const placeholder = iconDropdownStore.getPlaceholderText(field);
		return (
			<Wrapper className={className}>
				<Button onClick={this.toggleOpen}>
					<ButtonLabel>
						{selectedItem && selectedItem.icon && <Icon src={selectedItem.icon} align={iconAlignment} />}
						<Text>
							<Translate>{selectedItem ? selectedItem.key : placeholder}</Translate>
						</Text>
					</ButtonLabel>
					<ButtonToggle isOpen={this.isOpen} />
				</Button>
				{this.isOpen && (
					<React.Fragment>
						<ClickLayer onClick={this.toggleOpen} />
						<Menu>
							<List>
								{options.map((item, i) => {
									return (
										<ListItem
											key={item.key}
											selected={item.value === value}
											onClick={() => {
												this.setSelectedItem(item.value);
											}}
										>
											{item.icon && <Icon src={item.icon} align={iconAlignment} />}
											<Text>{item.key}</Text>
										</ListItem>
									);
								})}
							</List>
						</Menu>
					</React.Fragment>
				)}
				<select
					value={value || undefined}
					onChange={(event) => {
						this.onChange(event);
					}}
					hidden
				>
					<option />
					{options.map((option) => {
						return (
							<option key={option.key} value={option.value}>
								{option.value}
							</option>
						);
					})}
				</select>
			</Wrapper>
		);
	}
}

export const IconDropdown = inject('appStore', 'iconDropdownStore')(observer(RawIconDropdown));
export default InjectedStyledComponent(IconDropdown, 'iconDropdown');
