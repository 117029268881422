import { stores } from '@kurtosys/app-start';
import { common } from '@kurtosys/ksys-app-template';
import { action, computed, makeObservable } from 'mobx';

import { IComponentStyles } from '../../../models/app/IComponentStyles.js';
import { IConfiguration } from '../../../models/app/IConfiguration.js';
import { TStoreContext } from '../../../models/app/TStoreContext.js';
import { getSelectionsForRedirect } from '../../../utils/getSelectionsForRedirect.js';
import { AppStore } from '../../App/stores/AppStore.js';
import { SelectionStore } from '../../Selection/stores/SelectionStore.js';
import { ILinksConfiguration } from '../models';
import { ILinkProps } from '../models/ILinkProps.js';

const { isNullOrEmpty } = common.commonUtils;
import { IManifest } from '@kurtosys/types/appsManager/index.js';

export class LinksStore extends stores.base.StoreBase<IConfiguration, IComponentStyles> {
	static componentKey = 'links' as const;

	constructor(storeContext: TStoreContext, manifest: IManifest) {
		super(storeContext, manifest);
		makeObservable(this);
	}

	@computed
	get appStore(): AppStore {
		return this.storeContext.get<AppStore>('appStore');
	}

	@computed
	get selectionStore(): SelectionStore {
		return this.storeContext.get<SelectionStore>('selectionStore');
	}

	@computed
	get configuration(): ILinksConfiguration | undefined {
		if (this.appStore) {
			return this.appStore.getComponentConfiguration(LinksStore.componentKey);
		}
	}

	@action
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	async initialize(): Promise<void> {}

	@computed
	get show(): boolean {
		const display = (this.configuration && this.configuration.display) || 'always';
		if (display === 'has-all-selections') {
			return this.selectionStore && this.selectionStore.hasAllSelections;
		}
		return true;
	}

	@computed
	get links(): ILinkProps[] {
		const linksConfig = this.configuration && this.configuration.links;

		if (!isNullOrEmpty(linksConfig)) {
			return linksConfig.map((linkConfig) => {
				const { text, target, redirect } = linkConfig;
				const appParamsHelper = this.appStore.appParamsHelper;
				const selections = getSelectionsForRedirect(appParamsHelper, this.selectionStore.rawValues);
				const href = this.appStore.redirectHelper.getRedirectPath(redirect, selections) || '';
				return { text, target, href };
			});
		}

		return [];
	}
}
