import { svgCircleStyle } from '../svg/SvgCircle/styles/SvgCircle.style.js';
import { containerStyle } from './Container/styles/Container.style.js';
import { lineStyle } from './Line/styles/Line.style.js';
import { rectStyle } from './Rect/styles/Rect.style.js';
import { svgPolygonStyle } from './SvgPolygon/styles/SvgPolygon.style.js';
import { svgRectStyle } from './SvgRect/styles/SvgRect.style.js';
import { textStyle } from './Text/styles/Text.style.js';
/* [Component: import] */
/**
 * The  Svg pillar
 * @title Svg
 */
export const svgTheme = {
    svgCircle: svgCircleStyle,
    svgRect: svgRectStyle,
    svgPolygon: svgPolygonStyle,
    container: containerStyle,
    rect: rectStyle,
    line: lineStyle,
    text: textStyle,
    /* [Component: interface] */
};
