export const tableGroupingStyle = {
    children: {
        groupBody: {
            padding: {
                _value: '4px',
            },
            border: {
                _value: 'solid 1px #eeeeee',
            },
        },
        groupHeader: {
            border: {
                _value: 'solid 1px #eeeeee',
            },
            display: 'flex',
            flex: {
                direction: 'row',
            },
            padding: {
                _value: '10px',
            },
            children: {
                value: {
                    flex: {
                        grow: '1',
                    },
                    lineHeight: '30px',
                    padding: {
                        left: '10px',
                        right: '10px',
                    },
                },
                toggleButtonWrapper: {
                    flex: {
                        grow: '0',
                    },
                },
            },
        },
    },
};
