/* [Component: storesImport] */
import { AcceptanceStore } from '../components/Acceptance/stores/AcceptanceStore.js';
import { AcceptanceDisclaimerStore } from '../components/AcceptanceDisclaimer/stores/AcceptanceDisclaimerStore.js';
import { CallToActionStore } from '../components/CallToAction/stores/CallToActionStore.js';
import { DisclaimerStore } from '../components/Disclaimer/stores/DisclaimerStore.js';
import { FootnoteStore } from '../components/Footnote/stores/FootnoteStore.js';
import { HeaderStore } from '../components/Header/stores/HeaderStore.js';
import { IconDropdownStore } from '../components/IconDropdown/stores/IconDropdownStore.js';
import { LinksStore } from '../components/Links/stores/LinksStore.js';
import { ModalStore } from '../components/Modal/stores/ModalStore.js';
import { SelectionStore } from '../components/Selection/stores/SelectionStore.js';
import { SelectionFieldStore } from '../components/SelectionField/stores/SelectionFieldStore.js';
import { SelectionWizardStore } from '../components/SelectionWizard/stores/SelectionWizardStore.js';
import { TStoreContext } from '../models/app/TStoreContext.js';
// import { ExampleStore } from '../components/Example/stores/ExampleStore';

export function setupStoreContext(storeContext: TStoreContext) {
	//storeContext.set('exampleStore', new ExampleStore(storeContext, storeContext.manifest));
	/* [Component: stores] */
	storeContext.set('selectionWizardStore', new SelectionWizardStore(storeContext, storeContext.manifest));
	storeContext.set('selectionFieldStore', new SelectionFieldStore(storeContext, storeContext.manifest));
	storeContext.set('selectionStore', new SelectionStore(storeContext, storeContext.manifest));
	storeContext.set('modalStore', new ModalStore(storeContext, storeContext.manifest));
	storeContext.set('linksStore', new LinksStore(storeContext, storeContext.manifest));
	storeContext.set('iconDropdownStore', new IconDropdownStore(storeContext, storeContext.manifest));
	storeContext.set('headerStore', new HeaderStore(storeContext, storeContext.manifest));
	storeContext.set('footnoteStore', new FootnoteStore(storeContext, storeContext.manifest));
	storeContext.set('disclaimerStore', new DisclaimerStore(storeContext, storeContext.manifest));
	storeContext.set('callToActionStore', new CallToActionStore(storeContext, storeContext.manifest));
	storeContext.set('acceptanceDisclaimerStore', new AcceptanceDisclaimerStore(storeContext, storeContext.manifest));
	storeContext.set('acceptanceStore', new AcceptanceStore(storeContext, storeContext.manifest));
}
