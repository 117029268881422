export const iconInputStyle = {
    children: {
        label: {
            display: 'flex',
            align: {
                items: 'center',
            },
            margin: {
                _value: '0 16px',
            },
            color: '#666',
        },
        wrapper: {
            position: 'relative',
            display: 'flex',
            width: '450px',
            max: {
                width: '100%',
            },
            margin: {
                _value: '10px auto',
            },
            background: {
                color: '#f3f4f8',
            },
            padding: {
                _value: '8px',
            },
            border: {
                radius: '16px',
            },
        },
        inputComponent: {
            box: {
                sizing: 'border-box',
            },
            width: '100%',
            height: '40px',
            background: {
                color: '#f3f4f8',
            },
            outline: {
                width: '0',
                style: 'none',
            },
            font: {
                size: '13px',
                weight: '400',
            },
            lineHeight: '1',
            padding: {
                _value: '0 5em 0 3em',
            },
            selectors: [
                {
                    ':focus': {
                        border: {
                            width: '2px',
                            style: 'solid',
                            color: '#1a80ff',
                        },
                    },
                    '::placeholder': {
                        color: '#a9a9a9',
                    },
                    ':disabled': {
                        cursor: 'not-allowed',
                    },
                },
            ],
        },
        clearButton: {
            border: {
                _value: 'none',
            },
            background: {
                _value: 'none',
            },
        },
        rightContent: {
            display: 'flex',
            position: 'absolute',
            top: '30%',
            right: '0.75em',
        },
        inputPill: {
            top: '50%',
            padding: {
                _value: '5px',
            },
            border: {
                radius: '15px',
            },
            font: {
                size: '9px',
            },
        },
        standardWrapper: {
            display: 'flex',
            flex: {
                _value: '1',
            },
            overrides: [
                {
                    selector: 'input',
                    style: {
                        border: {
                            width: '2px',
                            style: 'solid',
                            color: 'transparent',
                            radius: '1px',
                        },
                        selectors: {
                            ':focus': {
                                border: {
                                    width: '2px',
                                    style: 'solid',
                                    color: '#1a80ff',
                                },
                            },
                        },
                    },
                },
            ],
        },
        errorWrapper: {
            overrides: [
                {
                    selector: 'input',
                    style: {
                        border: {
                            width: '2px',
                            style: 'solid',
                            color: '#ff7449',
                            radius: '1px',
                        },
                        selectors: {
                            ':focus': {
                                border: {
                                    width: '2px',
                                    style: 'solid',
                                    color: '#ff7449',
                                },
                            },
                        },
                    },
                },
            ],
        },
        iconWrapper: {
            display: 'flex',
            padding: {
                _value: '0 16px',
            },
        },
        iconComponent: {
            fill: 'none',
        },
    },
};
