export const dropdownStyle = {
    children: {
        label: {
            display: 'inline-block',
            width: '100%',
        },
        header: {
            border: {
                bottom: {
                    width: '2px',
                    style: 'solid',
                    color: '#0075BE',
                },
            },
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            lineHeight: '40px',
            width: '25%',
            padding: {
                left: '5px',
            },
            font: {
                weight: 'bold',
            },
        },
        headerTitle: {
            display: 'flex',
        },
        headerIcon: {
            float: 'right',
        },
        listHeading: {
            padding: { _value: '0' },
            margin: { left: '5px', top: '5px', bottom: '0' },
        },
        clearAllButton: {
            width: '100%',
            background: { color: 'transparent' },
            text: { align: 'left' },
            border: { _value: 'none' },
            cursor: 'pointer',
        },
        selectAllButton: {
            width: '100%',
            background: { color: 'transparent' },
            text: { align: 'left' },
            border: { _value: 'none' },
            cursor: 'pointer',
        },
        list: {
            position: 'relative',
            zIndex: '1000',
            background: {
                color: '#FFF',
            },
            width: '250px',
        },
        listItems: {
            width: '100%',
            listStyle: {
                type: 'none',
            },
            margin: {
                _value: '0',
            },
            padding: {
                _value: '0',
            },
        },
        listItem: {
            display: 'flex',
            background: { color: 'transparent' },
            flex: {
                direction: 'row',
            },
            justifyContent: 'flex-start',
            padding: {
                bottom: '5px',
                top: '5px',
                left: '5px',
                right: '5px',
            },
            selectors: {
                ':hover': {
                    background: {
                        color: '#0075BE',
                    },
                    color: '#FFF',
                },
                ':focus': {
                    background: {
                        color: '#d3d3d3',
                    },
                    color: '#FFF',
                },
            },
            border: {
                bottom: {
                    _value: '1px solid #D9DCE1',
                },
            },
            text: {
                align: 'right',
            },
            cursor: 'pointer',
        },
        listItemFocused: {
            background: {
                color: 'red',
            },
        },
        listItemCheckboxWrapper: {
            height: '25px',
            lineHeight: '15px',
        },
        listItemIconWrapper: {
            height: '25px',
            lineHeight: '15px',
        },
        listItemIcon: {
            height: '25px',
            border: {
                radius: '50%',
            },
            margin: {
                right: '10px',
            },
        },
        headerItemIcon: {
            height: '25px',
            border: {
                radius: '50%',
            },
            margin: {
                right: '10px',
            },
        },
        listItemLabel: {
            height: '25px',
            lineHeight: '25px',
            color: '#999999',
        },
        listItemLabelHighlight: {
            color: 'black',
        },
        clickLayer: {
            zIndex: '999',
            position: 'fixed',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
            background: {
                color: 'rgba(128, 128, 128, 0.5)',
            },
        },
        searchInputWrapper: {
            display: 'flex',
        },
        searchInput: {
            width: '100%',
            margin: {
                top: '5px',
                bottom: '8px',
            },
            padding: { _value: '5px 10px 5px' },
            border: { radius: '5px', width: '2px' },
        },
    },
};
