import { areaChartStyle } from './AreaChart/styles/AreaChart.style.js';
import { barChartStyle } from './BarChart/styles/BarChart.style.js';
import { barScaleStyle } from './BarScale/styles/BarScale.style.js';
import { blockRangeChartStyle } from './BlockRangeChart/styles/BlockRangeChart.style.js';
import { chartBaseStyle } from './ChartBase/styles/ChartBase.style.js';
import { chartLegendStyle } from './ChartLegend/styles/ChartLegend.style.js';
import { chartPeriodSelectionStyle } from './ChartPeriodSelection/styles/ChartPeriodSelection.style.js';
import { chartTableComboStyle } from './ChartTableCombo/styles/ChartTableCombo.style.js';
import { gaugeChartStyle } from './GaugeChart/styles/GaugeChart.style.js';
import { lineChartStyle } from './LineChart/styles/LineChart.style.js';
import { pieDoughnutChartStyle } from './PieDoughnutChart/styles/PieDoughnutChart.style.js';
import { radialChartStyle } from './RadialChart/styles/RadialChart.style.js';
import { rangeChartStyle } from './RangeChart/styles/RangeChart.style.js';
import { relativePerformanceChartStyle } from './RelativePerformanceChart/styles/RelativePerformanceChart.style.js';
import { scatterChartStyle } from './ScatterChart/styles/ScatterChart.style.js';
import { steppedBlockChartStyle } from './SteppedBlockChart/styles/SteppedBlockChart.style.js';
/* [Component: import] */
/* [ChartComponent: import] */
export const chartingTheme = {
    chartBase: chartBaseStyle,
    barChart: barChartStyle,
    lineChart: lineChartStyle,
    areaChart: areaChartStyle,
    scatterChart: scatterChartStyle,
    chartTableCombo: chartTableComboStyle,
    chartPeriodSelection: chartPeriodSelectionStyle,
    pieDoughnutChart: pieDoughnutChartStyle,
    chartLegend: chartLegendStyle,
    rangeChart: rangeChartStyle,
    blockRangeChart: blockRangeChartStyle,
    relativePerformanceChart: relativePerformanceChartStyle,
    steppedBlockChart: steppedBlockChartStyle,
    gaugeChart: gaugeChartStyle,
    radialChart: radialChartStyle,
    barScale: barScaleStyle,
    /* [Component: interface] */
    /* [ChartComponent: interface] */
};
