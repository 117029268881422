export const dividerStyle = {
    position: 'relative',
    box: {
        sizing: 'border-box',
    },
    border: {
        radius: '5px',
    },
    selectors: {
        '.ksys-horizontal': {
            display: 'block',
            border: {
                top: { _value: '1px solid rgba(200,200,200,0.25)' },
                bottom: { _value: '1px solid rgba(150,150,150,0.75)' },
            },
            margin: {
                _value: '10px 4px',
            },
            height: '1px',
        },
        '.ksys-vertical': {
            display: 'inline-block',
            border: {
                left: { _value: '1px solid rgba(200,200,200,0.25)' },
                right: { _value: '1px solid rgba(150,150,150,0.75)' },
            },
            margin: {
                _value: '4px 10px',
            },
            width: '1px',
        },
    },
    children: {
        text: {},
    },
};
