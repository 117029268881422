export const weightingBarStyle = {
    children: {
        weightingBlock: {
            display: 'inline-block',
            padding: {
                _value: '10px 20px',
            },
        },
    },
};
