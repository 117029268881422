export const listStyle = {
    children: {
        placeholder: {
            font: {
                style: 'italic',
            },
        },
        heading: {
            display: 'flex',
            overrides: [
                {
                    selector: '[data-selector="base-list-multipartListHeading"]',
                    style: {
                        flex: {
                            _value: 'auto',
                        },
                    },
                },
                {
                    selector: '[data-selector="base-list-multipartListHeading"]:last-child',
                    style: {
                        flex: {
                            grow: '0',
                        },
                    },
                },
            ],
        },
        multipartListItem: {
            display: 'flex',
            padding: {
                top: '4px',
                bottom: '4px',
            },
            border: {
                bottom: {
                    _value: '1px solid #999',
                },
            },
        },
        listItem: {
            border: {
                bottom: {
                    _value: '1px solid #999',
                },
            },
            padding: {
                top: '10px',
                bottom: '10px',
            },
        },
        content: {
            flex: {
                _value: 'auto',
            },
        },
        firstContent: {
            flex: {
                _value: 'auto',
            },
        },
        lastContent: {
            flex: {
                grow: '0',
            },
        },
        listItemWrapper: {
            listStyle: {
                type: 'none',
            },
        },
        unorderedList: {
            listStyle: {
                type: 'none',
            },
            margin: {
                block: {
                    start: '0px',
                    end: '0px',
                },
                inline: {
                    start: '0px',
                    end: '0px',
                },
            },
            padding: {
                inline: {
                    start: '0px',
                    end: '0px',
                },
            },
        },
    },
};
