import * as React from 'react';
import { components } from '@kurtosys/ksys-app-components';

import { InjectedStyledComponent } from '../../shared/InjectedStyledComponent.js';

const Translate = components.base.Translate.Translate;

const Title = (props: any) => {
	const { className, text = '' } = props;
	// const H1 = withSelector('h1', props);
	return (
		<h1 id="ksys-attestation-title" className={className}>
			<Translate>{text}</Translate>
		</h1>
	);
};

export default InjectedStyledComponent(Title, 'header', ['title']);
