export const modalStyle = {
    children: {
        modalWrapper: {
            top: '5%',
            right: '15%',
            left: '15%',
            position: 'fixed',
            display: 'flex',
            justifyContent: 'flex-start',
            flex: {
                direction: 'column',
            },
            background: {
                color: 'white',
            },
            box: {
                shadow: '0 5px 15px -5px',
            },
            border: {
                radius: '0.25em',
            },
            padding: {
                _value: '1em',
            },
            zIndex: '1001',
        },
        modalTitleBar: {
            display: 'flex',
            justifyContent: 'space-between',
            flex: {
                direction: 'row',
            },
            margin: {
                _value: '0 0 10px 0',
            },
        },
        modalHead: {
            margin: {
                _value: '0 0 10px 0',
            },
        },
        modalOpenIcon: {
            height: '16px',
            width: '16px',
        },
        modalOpenButton: {
            display: 'inline-flex',
            justifyContent: 'space-between',
            flex: {
                direction: 'row',
            },
            border: {
                _value: 'none',
                bottom: {
                    _value: '1px solid #dee2e6',
                },
            },
            background: {
                _value: 'none',
            },
            margin: {
                _value: '0 15px',
            },
            padding: {
                _value: '4px 2px',
            },
            cursor: 'pointer',
        },
        modalFooter: {
            margin: {
                _value: '10px 0 0 0',
            },
        },
        modalCloseIcon: {
            height: '16px',
            width: '16px',
        },
        modalCloseButton: {
            display: 'flex',
            justifyContent: 'space-between',
            flex: {
                direction: 'row',
            },
            border: {
                _value: 'none',
            },
            background: {
                _value: 'none',
            },
            cursor: 'pointer',
        },
        modalBody: {
            display: 'flex',
            margin: {
                _value: '0',
            },
            overflow: {
                y: 'auto',
            },
            flex: {
                grow: '1',
            },
        },
        modalActions: {
            display: 'flex',
            justifyContent: 'flex-end',
            flex: {
                direction: 'row',
            },
            margin: {
                _value: '10px 0 0 0',
            },
        },
        modalActionClose: {
            outline: {
                _value: 'none',
            },
            text: { align: 'center' },
            box: {
                shadow: '2px 2px 0 rgb(0 0 0 / 10%)',
            },
            border: {
                style: 'solid',
                color: '#0588c5',
                radius: '3px',
                width: '1px',
            },
            justifyContent: 'center',
            align: {
                items: 'center',
            },
            background: {
                _value: '#03a9f4',
            },
            min: {
                height: '24px',
            },
            padding: {
                top: '4px',
                bottom: '4px',
                left: '8px',
                right: '8px',
            },
            margin: {
                _value: '0 5px 0 0',
            },
            color: '#ffffff',
            cursor: 'pointer',
            selectors: {
                ':hover': {
                    border: {
                        color: '#05a2eb',
                    },
                    background: {
                        color: '#00aeff',
                    },
                },
            },
        },
        modalAction: {
            outline: {
                _value: 'none',
            },
            text: { align: 'center' },
            box: {
                shadow: '2px 2px 0 rgb(0 0 0 / 10%)',
            },
            border: {
                style: 'solid',
                color: '#dee2e6',
                radius: '3px',
                width: '1px',
            },
            justifyContent: 'center',
            align: {
                items: 'center',
            },
            background: {
                _value: '#f8f9fa',
            },
            min: {
                height: '24px',
            },
            padding: {
                top: '4px',
                bottom: '4px',
                left: '8px',
                right: '8px',
            },
            margin: {
                _value: '0 5px 0 0',
            },
            cursor: 'pointer',
            selectors: {
                ':hover': {
                    border: {
                        color: '#ebeef0',
                    },
                    background: {
                        color: '#fcfcfc',
                    },
                },
            },
        },
    },
};
