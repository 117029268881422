export const steppedBlockChartStyle = {
    children: {
        steps: {
            display: 'flex',
            flex: {
                direction: 'row',
            },
        },
        step: {
            position: 'relative',
            flex: {
                grow: '1',
                basis: '0',
            },
        },
        stepBlockWrapper: {
            position: 'relative',
            height: '200px',
            margin: {
                _value: '0px 2px',
            },
        },
        stepBlock: {
            position: 'absolute',
            bottom: '0',
            left: '0',
            right: '0',
            background: {
                color: 'green',
            },
        },
        stepLabel: {
            text: {
                align: 'center',
            },
            font: {
                size: '10px',
            },
        },
        point: {
            position: 'absolute',
            width: '10px',
            height: '10px',
            border: {
                radius: '50%',
                _value: '1px solid black',
            },
            transform: 'translate(-50%, -50%)',
        },
    },
};
