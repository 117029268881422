export const progressIndicatorStyle = {
    children: {
        wrapper: {
            display: 'flex',
        },
        step: {
            color: 'transparent',
            overflow: {
                _value: 'hidden',
            },
            display: 'flex',
            flex: {
                _value: '1 1',
            },
            height: '8px',
            border: {
                radius: '10px',
            },
            margin: {
                right: '5px',
            },
            cursor: 'pointer',
        },
        completedStep: {
            background: {
                color: '#2779c2!important',
            },
            border: {
                _value: '1px solid #7db1d3',
            },
            box: {
                shadow: '200 0 2px 1px #c4c4c4',
            },
        },
        uncompletedStep: {
            background: {
                color: '#d4d4d4',
            },
            border: {
                _value: '1px solid #cfcfcf',
            },
        },
    },
};
