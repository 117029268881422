export const lineChartStyle = {
    font: {
        family: 'Roboto, Arial',
        size: '11px',
    },
    color: '#000000',
    height: '300px',
    overrides: [
        {
            selector: '[data-selector="charting-chartBase-wrapper"]',
            style: {
                height: '400px ',
            },
        },
        {
            selector: 'div.recharts-wrapper > svg > g.recharts-layer.recharts-cartesian-axis.recharts-yAxis.yAxis > g text',
            style: {
                fill: '#4A4A4A',
                height: '11px',
                width: '35px',
                color: '#4A4A4A',
                font: {
                    size: '10px',
                },
                lineHeight: '11px',
            },
        },
        {
            selector: 'div.recharts-wrapper > svg > g.recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis > g text',
            style: {
                fill: '#000000',
                height: '15px',
                width: '76px',
                font: {
                    size: '13px',
                },
                lineHeight: '15px',
            },
        },
        {
            selector: 'div.recharts-wrapper > div > ul > li.recharts-legend-item > svg',
            style: {
                margin: {
                    right: '10px !important',
                },
                width: '10px',
                height: '10px',
                lineHeight: '15px',
            },
        },
        {
            selector: 'div.recharts-wrapper > div > ul > li.recharts-legend-item > span.recharts-legend-item-text',
            style: {
                text: {
                    transform: 'uppercase',
                },
                color: '#272626',
                height: '15px',
                font: {
                    size: '12px',
                },
                lineHeight: '15px',
            },
        },
        {
            selector: 'div.recharts-wrapper > div.recharts-tooltip-wrapper > div.recharts-default-tooltip',
            style: {
                text: {
                    transform: 'uppercase',
                },
                font: {
                    size: '12px',
                },
                lineHeight: '14px',
                background: {
                    color: 'rgba(251,251,251,0.9) !important',
                },
                border: {
                    _value: '1px solid #BEBDBE !important',
                },
            },
        },
        {
            selector: 'div.recharts-wrapper > .recharts-surface > .recharts-active-dot > circle',
            style: {
                fill: '#FFFFFF',
                stroke: '#c21b17',
            },
        },
    ],
};
