export const chartLegendStyle = {
    overrides: [
        {
            selector: '[data-selector="base-list-firstContent"]',
            style: {
                flex: {
                    _value: 'none',
                },
            },
        },
    ],
    children: {
        totalText: {
            font: {
                weight: 'bold',
            },
        },
        totalValue: {
            font: {
                weight: 'bold',
            },
        },
    },
};
