import * as React from 'react';

import Image from '../../shared/styledComponents/Image.js';
import { InjectedStyledComponent } from '../InjectedStyledComponent.js';

export interface dropdownButtonToggleProps {
	className: string;
	isOpen: boolean;
}

const dropdownButtonToggle = (props: dropdownButtonToggleProps) => {
	const { className, isOpen } = props;
	const src = isOpen ? 'DropdownChevronIconExpanded.svg' : 'DropdownChevronIcon.svg';
	return <Image className={className} src={src} />;
};

export default InjectedStyledComponent(dropdownButtonToggle, 'shared', ['dropdownButtonToggle']);
