import React from 'react';
import { common } from '@kurtosys/ksys-app-template';
import { action, computed, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { ComponentBase } from '../../../shared/ComponentBase.js';
import { InjectedStyledComponent } from '../../../shared/InjectedStyledComponent.js';
import Button from '../Button/Button.js';
import Tooltip from '../Tooltip/Tooltip.js';
import Translate from '../Translate/Translate.js';
import VisuallyHidden from '../VisuallyHidden/VisuallyHidden.js';
import CollapsedContent from './styledComponents/CollapsedContent.js';
import Content from './styledComponents/Content.js';
import HtmlContent from './styledComponents/HtmlContent.js';
import TextContent from './styledComponents/TextContent.js';
import ToggleWrapper from './styledComponents/ToggleWrapper.js';
import Wrapper from './styledComponents/Wrapper.js';
import { TEXT_CATEGORY_KEY, TEXT_COMPONENT_KEY } from './Text.meta.js';
const DEFAULT_TOGGLE_BUTTONS = {
    expand: {
        text: 'Read more',
    },
    collapse: {
        text: 'Read less',
    },
};
const DEFAULT_COLLAPSE_CONFIG = { maxLength: 320, suffix: '...' };
export class Text extends ComponentBase {
    constructor(props) {
        super(props);
        this.categoryKey = TEXT_CATEGORY_KEY;
        this.componentKey = TEXT_COMPONENT_KEY;
        this.handleToggle = () => {
            this.toggleState = this.isExpanded ? 'collapsed' : 'expanded';
        };
        this.removeHTMLTags = (text) => {
            return text
                .replace(/<[^>]*>/g, ' ')
                .replace(/\s{2,}/g, ' ')
                .trim();
        };
        this.renderTooltip = (tooltipProps, placement) => {
            if (tooltipProps) {
                if (placement === tooltipProps.placement) {
                    return React.createElement(Tooltip, { ...tooltipProps });
                }
                if (!tooltipProps.placement && placement === 'left') {
                    // Handles default placement, left, when there is tooltip content defined without the placement specified
                    return React.createElement(Tooltip, { ...tooltipProps });
                }
            }
            return null;
        };
        this.renderExpandedContent = () => {
            const { hasHtmlContent, value } = this.props;
            return hasHtmlContent ? (React.createElement(React.Fragment, null,
                React.createElement(HtmlContent, { "aria-hidden": !this.isExpanded, hidden: !this.isExpanded, dangerouslySetInnerHTML: { __html: value } }),
                React.createElement(VisuallyHidden, null,
                    React.createElement(HtmlContent, { "aria-hidden": this.isExpanded, hidden: this.isExpanded, dangerouslySetInnerHTML: { __html: value } })))) : (React.createElement(React.Fragment, null,
                React.createElement(TextContent, { "aria-hidden": !this.isExpanded, hidden: !this.isExpanded },
                    React.createElement(Translate, null, value)),
                React.createElement(VisuallyHidden, null,
                    React.createElement(TextContent, { "aria-hidden": this.isExpanded, hidden: this.isExpanded },
                        React.createElement(Translate, null, value)))));
        };
        this.renderCollapsedContent = () => {
            return (React.createElement(CollapsedContent, { "aria-hidden": true, hidden: this.isExpanded }, this.textOverflowResponse?.content));
        };
        this.toggleState = props.toggleState || 'expanded';
        makeObservable(this, {
            toggleState: observable,
            isExpanded: computed,
            collapseConfig: computed,
            textOverflowHelper: computed,
            textOverflowResponse: computed,
            toggleButtonsKey: computed,
            toggleButtons: computed,
            allowToggle: computed,
            workingToggleState: computed,
            handleToggle: action,
        });
    }
    get isExpanded() {
        if (this.collapseConfig &&
            this.textOverflowResponse?.counter &&
            this.collapseConfig.maxLength > this.textOverflowResponse.counter.total) {
            return true;
        }
        return this.toggleState === 'expanded';
    }
    get collapseConfig() {
        const { collapse } = this.props;
        if (!collapse) {
            return null;
        }
        const collapseWithDefaults = {
            ...DEFAULT_COLLAPSE_CONFIG,
            ...collapse,
        };
        return collapseWithDefaults;
    }
    get textOverflowHelper() {
        if (this.collapseConfig) {
            const { maxLength: length, suffix } = this.collapseConfig;
            const options = {
                suffix,
                length,
                mustStripStyle: true,
            };
            return new common.helpers.TextOverflowHelper(options);
        }
    }
    get textOverflowResponse() {
        const { value = '', hasHtmlContent } = this.props;
        const text = hasHtmlContent ? this.removeHTMLTags(value) : value;
        return this.textOverflowHelper?.get(text);
    }
    get toggleButtonsKey() {
        return this.toggleState === 'expanded' ? 'collapse' : 'expand';
    }
    get toggleButtons() {
        const { toggleButtons = DEFAULT_TOGGLE_BUTTONS } = this.props;
        return toggleButtons;
    }
    get allowToggle() {
        if (!this.collapseConfig) {
            return false;
        }
        const { maxLength } = this.collapseConfig;
        return !!(maxLength > 0 && this.textOverflowResponse?.counter?.current !== this.textOverflowResponse?.counter?.total);
    }
    get workingToggleState() {
        return this.allowToggle ? this.toggleState : 'expanded';
    }
    render() {
        const { tooltip, className } = this.props;
        return (React.createElement(Wrapper, { className: className },
            React.createElement(Content, { toggleState: this.workingToggleState },
                tooltip && this.renderTooltip(tooltip, 'left'),
                this.allowToggle && this.renderCollapsedContent(),
                this.renderExpandedContent(),
                tooltip && this.renderTooltip(tooltip, 'right'),
                this.allowToggle && (React.createElement(ToggleWrapper, null,
                    React.createElement(Button, { ...this.toggleButtons[this.toggleButtonsKey], "aria-hidden": true, onClick: this.handleToggle }))))));
    }
}
export default InjectedStyledComponent(observer(Text), TEXT_CATEGORY_KEY, TEXT_COMPONENT_KEY);
