import * as React from 'react';

import { InjectedStyledComponent } from '../../shared/InjectedStyledComponent.js';
import Image from '../../shared/styledComponents/Image.js';

const leftAlignStyleProps = {
	margin: '0 5px 0 0',
};

const rightAlignStyleProps = {
	order: 1,
	margin: '0 0 0 auto',
};

const Icon = (props: any) => {
	const { className, src = '', align = 'right' } = props;
	const style = align === 'right' ? rightAlignStyleProps : leftAlignStyleProps;

	return <Image className={className} src={src} style={style} />;
};
export default InjectedStyledComponent(Icon, 'iconDropdown', ['icon']);
