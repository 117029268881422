import { common } from '@kurtosys/ksys-app-template';
import whichCountry from 'which-country';

import { IPosition } from '../models/app/IPosition.js';
import { TGeolocationOnComplete } from '../models/app/TGeolocationOnComplete.js';
import { TGeolocationOnError } from '../models/app/TGeolocationOnError.js';
import { TGeolocationOnSuccess } from '../models/app/TGeolocationOnSuccess.js';

const { isNullOrUndefined } = common.commonUtils;

export function geolocate(onComplete: (code: string) => any, coordinates?: any) {
	const onSuccessFn = (position: any) => {
		onSuccess(position, onComplete);
	};
	const onErrorFn = onError;
	if (!isNullOrUndefined(coordinates)) {
		return onSuccess({ coords: coordinates }, onComplete);
	}
	return getCoordinates(onSuccessFn, onErrorFn);
}

export function getCoordinates(onSuccess: TGeolocationOnSuccess, onError: TGeolocationOnError) {
	try {
		if ('geolocation' in navigator) {
			return navigator.geolocation.getCurrentPosition(onSuccess, onError);
		}
	} catch (ex) {
		return onError(ex);
	}
}

const onSuccess = (position: IPosition, onComplete: TGeolocationOnComplete) => {
	let { latitude, longitude } = position.coords as any;
	let countryCode = whichCountry([longitude, latitude]);

	/**
	 * If which-country does not find a value on the first pass,	truncating the coordinate values
	 * can lead to better results (in the case of coordinates for coastal areas)
	 * The values are being truncated by one digit each pass to increase accurancy
	 * Each value is rounded down and up to test, for example:
	 * 12.345, 12.34, 12.35, 12.3, 12.4
	 */
	if (isNullOrUndefined(countryCode) && latitude && longitude) {
		let precision = 1000000;
		while (precision >= 1 && isNullOrUndefined(countryCode)) {
			/**
			 * Round down the value (12.345 becomes 12.34)
			 */
			longitude = Math.trunc(longitude * precision) / precision;
			latitude = Math.trunc(latitude * precision) / precision;
			countryCode = whichCountry([longitude, latitude]);

			if (!isNullOrUndefined(countryCode)) {
				break;
			}

			/**
			 * Round up the value (12.345 becomes 12.35)
			 */
			longitude = longitude + 1 / precision;
			latitude = latitude + 1 / precision;
			countryCode = whichCountry([longitude, latitude]);
			precision /= 10;
		}
	}
	onComplete(countryCode);
};

const onError: TGeolocationOnError = (error: GeolocationPositionError | string) => {
	console.warn('An error has occurred while retrieving location', error);
};
