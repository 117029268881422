import * as React from 'react';
import { components } from '@kurtosys/ksys-app-components';

import { InjectedStyledComponent } from '../../shared/InjectedStyledComponent.js';

const Translate = components.base.Translate.Translate;

const Subtitle = (props: any) => {
	const { className, text = '' } = props;
	return (
		<h2 className={className}>
			<Translate>{text}</Translate>
		</h2>
	);
};

export default InjectedStyledComponent(Subtitle, 'header', ['subtitle']);
