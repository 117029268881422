import React, { createContext, Fragment } from 'react';
import { observer } from 'mobx-react';
import { ComponentBase } from '../../../shared/ComponentBase.js';
import { InjectedStyledComponent } from '../../../shared/InjectedStyledComponent.js';
import { ACCESSIBILITY_CATEGORY_KEY, ACCESSIBILITY_COMPONENT_KEY } from './Accessibility.meta.js';
// eslint-disable-next-line @typescript-eslint/naming-convention
export const AccessibilityContext = createContext({
    accessibilityText: (key, defaultValue) => key || defaultValue,
});
export class Accessibility extends ComponentBase {
    constructor() {
        super(...arguments);
        this.categoryKey = ACCESSIBILITY_CATEGORY_KEY;
        this.componentKey = ACCESSIBILITY_COMPONENT_KEY;
        this.context = null;
    }
    get accesibilityText() {
        const { children, defaultValue = '' } = this.props;
        if (typeof children !== 'string' || !this.context || !this.context.accessibilityText) {
            return children;
        }
        return this.context.accessibilityText(children, defaultValue);
    }
    render() {
        return React.createElement(Fragment, null, this.accesibilityText);
    }
}
Accessibility.contextType = AccessibilityContext;
export default InjectedStyledComponent(observer(Accessibility), ACCESSIBILITY_CATEGORY_KEY, ACCESSIBILITY_COMPONENT_KEY);
