import { stores } from '@kurtosys/app-start';
import { IGrid } from '@kurtosys/ksys-app-components/dist/components/base/Grid/models/IGrid';
import { action, computed, makeObservable } from 'mobx';

import { IComponentStyles } from '../../../models/app/IComponentStyles.js';
import { IConfiguration } from '../../../models/app/IConfiguration.js';
import { TStoreContext } from '../../../models/app/TStoreContext.js';
import { AppStore } from '../../App/stores/AppStore.js';
import { IHeaderConfiguration } from '../models';
import { IHeaderComponents } from '../models/IHeaderComponents.js';
import Logo from '../styledComponents/Logo.js';
import Subtitle from '../styledComponents/Subtitle.js';
import Summary from '../styledComponents/Summary.js';
import Title from '../styledComponents/Title.js';

type TranslationStore = stores.TranslationStore<IConfiguration, IComponentStyles>;
import { IManifest } from '@kurtosys/types/appsManager/index.js';

export class HeaderStore extends stores.base.StoreBase<IConfiguration, IComponentStyles> {
	static componentKey = 'header' as const;

	constructor(storeContext: TStoreContext, manifest: IManifest) {
		super(storeContext, manifest);
		makeObservable(this);
	}

	@action
	async initialize(): Promise<void> {
		return;
	}

	@computed
	get appStore(): AppStore {
		return this.storeContext.get<AppStore>('appStore');
	}

	@computed
	get translationStore(): TranslationStore {
		return this.storeContext.get<TranslationStore>('translationStore');
	}

	@computed
	get configuration(): IHeaderConfiguration | undefined {
		if (this.appStore) {
			return this.appStore.getComponentConfiguration(HeaderStore.componentKey);
		}
	}

	getHeaderConfiguration = () => {
		return this.appStore.getComponentConfiguration('header');
	};

	@computed
	get title() {
		const headerConfiguration = this.getHeaderConfiguration();
		return headerConfiguration && headerConfiguration.title;
	}

	@computed
	get subtitle() {
		const headerConfiguration = this.getHeaderConfiguration();
		return headerConfiguration && headerConfiguration.subtitle;
	}

	@computed
	get summary() {
		const headerConfiguration = this.getHeaderConfiguration();
		return headerConfiguration && headerConfiguration.summary;
	}

	@computed
	get logo() {
		const headerConfiguration = this.getHeaderConfiguration();
		return headerConfiguration && headerConfiguration.logo;
	}
	@computed
	get components(): IHeaderComponents {
		return {
			title: {
				key: 'title',
				component: Title,
				hide: !this.title,
				props: {
					culture: this.translationStore.culture,
					text: this.title && this.title.text,
				},
			},
			subtitle: {
				key: 'subtitle',
				component: Subtitle,
				hide: !this.subtitle,
				props: {
					culture: this.translationStore.culture,
					text: this.subtitle && this.subtitle.text,
				},
			},
			summary: {
				key: 'summary',
				component: Summary,
				hide: !this.summary,
				props: {
					culture: this.translationStore.culture,
					text: this.summary && this.summary.text,
				},
			},
			logo: {
				key: 'logo',
				component: Logo,
				hide: !this.logo,
				props: {
					culture: this.translationStore.culture,
					src: this.logo && this.logo.src,
					title: this.logo && this.logo.alternativeText,
					alt: this.logo && this.logo.alternativeText,
				},
			},
		};
	}

	@computed
	get grid(): IGrid | undefined {
		if (this.configuration && this.configuration.grid) {
			return this.configuration.grid;
		}
	}
}
