import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { InjectedStyledComponent } from '../shared/InjectedStyledComponent.js';

import Text from './styledComponents/Text.js';
import Wrapper from './styledComponents/Wrapper.js';
import { IFootnoteProps, IFootnoteState } from './models';

class RawFootnote extends Component<IFootnoteProps, IFootnoteState> {
	static configurationKey = 'footnote' as const;
	static styleKey = 'footnote' as const;
	constructor(props: IFootnoteProps) {
		super(props);
	}
	render() {
		const { className, footnoteStore } = this.props;

		if (!footnoteStore) {
			return null;
		}

		const { textProps } = footnoteStore;

		return (
			<Wrapper className={className}>
				<Text {...textProps} />
			</Wrapper>
		);
	}
}

export const Footnote = inject('appStore', 'footnoteStore')(observer(RawFootnote));
export default InjectedStyledComponent(Footnote, 'footnote');
