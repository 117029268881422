import React, { useEffect, useRef } from 'react';

import { IFocusTrapProps } from './models/IFocusTrapProps';

/**
 * A component that traps focus within a modal when it's open.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Whether the modal is open.
 * @param {ReactNode} props.children - The content of the modal.
 * @returns {ReactNode} - The rendered component.
 */
export const FocusTrap: React.FC<IFocusTrapProps> = ({ open, children }) => {
	const modalRef = useRef<HTMLDivElement>(null);
	const focusedElementBeforeModal = useRef<HTMLElement>(null);

	useEffect(() => {
		if (open) {
			// Save the currently focused element before opening the modal
			focusedElementBeforeModal.current = document.activeElement as HTMLElement;

			// Focus the modal when it opens
			modalRef.current.focus();

			// Add a focus event listener to the entire document to trap focus within the modal
			document.addEventListener('focusin', trapFocus);
		} else {
			// Remove the focus event listener and return focus to the element that was focused before the modal opened
			document.removeEventListener('focusin', trapFocus);
			focusedElementBeforeModal.current.focus();
		}

		return () => {
			document.removeEventListener('focusin', trapFocus);
		};
	}, [open]);

	const trapFocus = (event: FocusEvent) => {
		if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
			// If focus moves outside the modal, bring it back to the modal
			modalRef.current.focus();
		}
	};

	if (open) {
		return (
			<div
				ref={modalRef}
				tabIndex={-1}
				role="dialog"
				aria-modal="true"
				aria-labelledby="modal-title"
				aria-describedby="modal-description"
			>
				{children}
			</div>
		);
	}

	return null;
};
export default FocusTrap;
