import React, { Fragment } from 'react';
import { common } from '@kurtosys/ksys-app-template';
import { observer } from 'mobx-react';
import { ComponentBase, InjectedStyledComponent, KEYS } from '../../../shared/index.js';
import { AssetContext } from '../Asset/Asset.js';
import StyledImage from './styledComponents/StyledImage.js';
import { IMAGE_CATEGORY_KEY, IMAGE_COMPONENT_KEY } from './Image.meta.js';
const ACCEPT_KEYS = [KEYS.ENTER, KEYS.SPACE, KEYS.SPACEBAR];
export class Image extends ComponentBase {
    constructor(props) {
        super(props);
        this.categoryKey = IMAGE_CATEGORY_KEY;
        this.componentKey = IMAGE_COMPONENT_KEY;
    }
    assetExists(assetContext) {
        const { asset } = this.props;
        return asset && assetContext.hasAsset(asset);
    }
    showImage(assetContext) {
        const { src } = this.props;
        return this.assetExists(assetContext) || !common.commonUtils.isNullOrEmpty(src);
    }
    prefixBaseUrl(assetContext) {
        const baseUrl = this.baseUrl(assetContext);
        const srcPart = this.srcPart(assetContext);
        if (common.commonUtils.isNullOrEmpty(baseUrl) || common.commonUtils.isBase64File(srcPart)) {
            return false;
        }
        const srcPartLower = srcPart.toLowerCase();
        const prefixes = ['http://', 'https://', 'blob:'];
        if (prefixes.find((prefix) => srcPartLower.startsWith(prefix))) {
            return false;
        }
        return baseUrl.toLowerCase().startsWith('http://') || baseUrl.toLowerCase().startsWith('https://');
    }
    baseUrl(assetContext) {
        const { baseUrl } = this.props;
        const assetBaseUrl = assetContext && assetContext.baseUrl();
        return baseUrl || assetBaseUrl || '';
    }
    srcPart(assetContext) {
        const { asset, src } = this.props;
        let response = '';
        if (!common.commonUtils.isNullOrEmpty(src)) {
            response = src;
        }
        else if (asset) {
            response = assetContext.getAsset(asset);
        }
        return response;
    }
    src(assetContext) {
        let baseUrl = this.baseUrl(assetContext);
        let srcPart = this.srcPart(assetContext);
        if (this.prefixBaseUrl(assetContext)) {
            baseUrl = baseUrl.endsWith('/') ? baseUrl : `${baseUrl}/`;
            srcPart = srcPart.startsWith('/') ? srcPart.substr(1) : srcPart;
            return `${baseUrl}${srcPart}`;
        }
        return srcPart;
    }
    get alt() {
        const { alt = '' } = this.props;
        if (typeof alt !== 'string') {
            return alt;
        }
        return React.createElement("span", { dangerouslySetInnerHTML: { __html: alt } });
    }
    render() {
        const { className, 
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        src, 
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        asset, 
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        baseUrl, onClick, 
        // default the alt to blank to hide the image from screen readers
        alt = '', ...props } = this.props;
        const tabIndex = onClick !== undefined ? 0 : -1;
        return (React.createElement(AssetContext.Consumer, null, (assetContext) => (React.createElement(Fragment, null,
            this.showImage(assetContext) && (React.createElement(StyledImage, { className: className, src: this.src(assetContext), onClick: onClick, onKeyDown: (e) => ACCEPT_KEYS.indexOf(e.key) >= 0 &&
                    onClick &&
                    // We need to transform the event type from keyboard to mouse before passing it to onClick
                    onClick(e), alt: alt, ...props })),
            !this.showImage(assetContext) && this.alt))));
    }
}
Image.contextType = AssetContext;
export default InjectedStyledComponent(observer(Image), IMAGE_CATEGORY_KEY, IMAGE_COMPONENT_KEY);
