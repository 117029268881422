import * as React from 'react';
import { components } from '@kurtosys/ksys-app-components';

import { InjectedStyledComponent } from '../../shared/InjectedStyledComponent';

const cssPrefix = `
	width: 100%;
	text-align: left;
`;

const Translate = components.base.Translate.Translate;

const text = (props: any) => {
	const { className, children = '' } = props;
	return (
		<span className={className}>
			<Translate>{children}</Translate>
		</span>
	);
};

export default InjectedStyledComponent(text, 'iconDropdown', ['text'], ['appStore'], cssPrefix);
