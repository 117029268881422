import { baseStyle } from './Base/styles/Base.style.js';
import { errorMessageStyle } from './ErrorMessage/styles/ErrorMessage.style.js';
import { infoMessageStyle } from './InfoMessage/styles/InfoMessage.style.js';
import { successMessageStyle } from './SuccessMessage/styles/SuccessMessage.style.js';
import { warningMessageStyle } from './WarningMessage/styles/WarningMessage.style.js';
/* [Component: import] */
/**
 * The  Message pillar
 * @title Message
 */
export const messageTheme = {
    base: baseStyle,
    errorMessage: errorMessageStyle,
    infoMessage: infoMessageStyle,
    successMessage: successMessageStyle,
    warningMessage: warningMessageStyle,
    /* [Component: interface] */
};
