export const definitionListStyle = {
    children: {
        wrapper: {
            display: 'flex',
            flex: {
                direction: 'row',
            },
        },
        separator: {
            margin: {
                _value: '0 4px',
            },
        },
        term: {
            font: {
                weight: 'bold',
            },
        },
        definition: {
            margin: {
                left: '4px',
            },
        },
    },
};
