export const savedSearchStyle = {
    children: {
        wrapper: {
            overrides: [
                {
                    selector: '[data-selector="base-savedSearch-saveButton"], [data-selector="base-savedSearch-toggleButton"], [data-selector="base-savedSearch-listButton"]',
                    style: {
                        background: {
                            color: 'white',
                        },
                        border: {
                            radius: 'none',
                            _value: '1px solid #777777',
                        },
                        margin: {
                            _value: '2px',
                        },
                        outline: {
                            _value: 'none',
                        },
                        padding: {
                            _value: '4px 8px',
                        },
                    },
                },
            ],
        },
        input: {
            height: '20px',
            margin: {
                _value: '2px',
            },
            outline: {
                _value: 'none',
            },
        },
        clickLayer: {
            zIndex: '999',
            position: 'fixed',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
            background: {
                color: 'transparent',
            },
        },
        listWrapper: {
            box: {
                shadow: '0px 0px 2px rgba(0, 0, 0, 0.5);',
            },
            background: {
                color: 'white',
            },
            width: 'fit-content',
            position: 'absolute',
            zIndex: '1000',
        },
    },
};
