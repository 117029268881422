export const fundManagersCarouselStyle = {
    overrides: [
        {
            selector: '[data-selector="management-fundManager-wrapper"]',
            style: {
                margin: {
                    _value: '0 auto',
                },
            },
        },
    ],
};
