import { awardStyle } from './Award/styles/Award.style.js';
import { awardsStyle } from './Awards/styles/Awards.style.js';
import { changeIndicatorStyle } from './ChangeIndicator/styles/ChangeIndicator.style.js';
import { commentaryStyle } from './Commentary/styles/Commentary.style.js';
import { disclaimerStyle } from './Disclaimer/styles/Disclaimer.style.js';
import { disclaimersStyle } from './Disclaimers/styles/Disclaimers.style.js';
/* [Component: import] */
/* [ChartComponent: import] */
export const overviewTheme = {
    commentary: commentaryStyle,
    disclaimer: disclaimerStyle,
    disclaimers: disclaimersStyle,
    changeIndicator: changeIndicatorStyle,
    award: awardStyle,
    awards: awardsStyle,
    /* [Component: interface] */
    /* [ChartComponent: interface] */
};
