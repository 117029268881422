export const badgeStyle = {
    children: {
        wrapper: {
            font: {
                size: 'small',
                family: 'body',
                weight: 'normal',
            },
            text: {
                align: 'center',
            },
            padding: {
                top: '0.2em',
                bottom: '0.2em',
                left: '0.5em',
                right: '0.5em',
            },
            min: {
                width: '2em',
            },
            color: 'white',
            background: {
                color: '#bbb',
            },
            border: {
                radius: '3em',
            },
            display: 'inline',
        },
    },
};
