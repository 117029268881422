export const blockRangeChartStyle = {
    children: {
        blocks: {
            display: 'flex',
            flex: {
                direction: 'row',
            },
            background: {
                image: 'linear-gradient(to right, orange, green)',
            },
        },
        block: {
            position: 'relative',
            flex: {
                grow: '1',
            },
            text: {
                align: 'center',
            },
            padding: {
                _value: '10px',
            },
            border: {
                _value: 'solid 2px #ffffff',
            },
            background: {
                color: 'transparent',
            },
        },
        blockValue: {
            color: '#ffffff',
        },
        seriesPoint: {
            position: 'absolute',
            top: '-10px',
            zIndex: '1',
            overrides: [
                {
                    selector: 'img',
                    style: {
                        position: 'absolute',
                        left: '-12.5px',
                    },
                },
            ],
        },
        bottomSeriesPoint: {
            position: 'absolute',
            left: '0',
            right: '0',
            bottom: '0',
        },
        topSeriesPoint: {
            position: 'absolute',
            left: '0',
            right: '0',
            top: '0',
        },
    },
};
