export const chartPeriodSelectionStyle = {
    children: {
        wrapper: {
            display: 'flex',
        },
        chartPeriod: {
            font: {
                size: '14px',
            },
            border: {
                width: '1px',
                style: 'solid',
                color: '#000',
            },
            background: {
                color: '#E9E9E8',
            },
            cursor: 'pointer',
            margin: {
                _value: '2px',
            },
            padding: {
                _value: '4px 6px',
            },
        },
        activeChartPeriod: {
            font: {
                size: '14px',
            },
            border: {
                width: '1px',
                style: 'solid',
                color: '#000',
            },
            background: {
                color: 'dodgerblue',
            },
            color: 'white',
            cursor: 'pointer',
            margin: {
                _value: '2px',
            },
            padding: {
                _value: '4px 6px',
            },
        },
        disabledChartPeriod: {
            font: {
                size: '14px',
            },
            border: {
                width: '1px',
                style: 'solid',
                color: '#000',
            },
            background: {
                color: 'lightgray',
            },
            color: 'gray',
            cursor: 'default',
            margin: {
                _value: '2px',
            },
            padding: {
                _value: '4px 6px',
            },
        },
    },
};
