import * as React from 'react';
import { components } from '@kurtosys/ksys-app-components';

import { InjectedStyledComponent } from '../../shared/InjectedStyledComponent.js';

const Translate = components.base.Translate.Translate;

const Summary = (props: any) => {
	const { className, text = '' } = props;
	return (
		<p className={className}>
			<Translate>{text}</Translate>
		</p>
	);
};

export default InjectedStyledComponent(Summary, 'header', ['summary']);
