import { models, stores } from '@kurtosys/app-start';
import { components } from '@kurtosys/ksys-app-components';
import { common, query } from '@kurtosys/ksys-app-template';
import { helpers } from '@kurtosys/ksys-app-template/dist/common';
import { action, computed, makeObservable, observable } from 'mobx';

import { LIBRARY_COMPONENTS_CONFIGURATION } from '../../../configuration/libraryComponentsConfiguration.js';
import { MockData } from '../../../configuration/mockData/index.js';
import { IAttestation } from '../../../models/app/IAttestation.js';
import { IAttestationValidation } from '../../../models/app/IAttestationValidation.js';
import { IComponentStyles } from '../../../models/app/IComponentStyles.js';
import { IConfiguration } from '../../../models/app/IConfiguration.js';
import { IConfigurationStorage } from '../../../models/app/IConfigurationStorage.js';
import { IInputs } from '../../../models/app/IInputs.js';
import { TStorageType } from '../../../models/app/TStorageType.js';
import { TStoreContext } from '../../../models/app/TStoreContext.js';
import { geolocate } from '../../../utils/geolocate.js';
import { getAlpha3CountryCode } from '../../../utils/getAlpha3CountryCode.js';
import { getSelectionsForRedirect } from '../../../utils/getSelectionsForRedirect.js';
import { deObfuscate, isObfuscated, obfuscate } from '../../../utils/obfuscation.js';
import Acceptance from '../../Acceptance/Acceptance.js';
import { AcceptanceStore } from '../../Acceptance/stores/AcceptanceStore.js';
import { AcceptanceDisclaimerStore } from '../../AcceptanceDisclaimer/stores/AcceptanceDisclaimerStore.js';
import CallToAction from '../../CallToAction/CallToAction.js';
import { CallToActionStore } from '../../CallToAction/stores/CallToActionStore.js';
import Disclaimer from '../../Disclaimer/Disclaimer.js';
import { DisclaimerStore } from '../../Disclaimer/stores/DisclaimerStore.js';
import Footnote from '../../Footnote/Footnote.js';
import Header from '../../Header/Header.js';
import Links from '../../Links/Links.js';
import { ISelectionValues } from '../../Selection/models/ISelectionValues.js';
import Selection from '../../Selection/Selection.js';
import { SelectionStore } from '../../Selection/stores/SelectionStore.js';
import SelectionWizard from '../../SelectionWizard/SelectionWizard.js';
import { SelectionWizardStore } from '../../SelectionWizard/stores/SelectionWizardStore.js';
import { Feature } from '../../shared/Feature.js';
import { IAppComponents } from '../models/IAppComponents.js';
import { IAttestationContext } from '../models/IAttestationContext.js';
import { IStorageOption } from '../models/IStorageOption.js';
import { IStorageOptions } from '../models/IStorageOptions.js';

/* [Component: appStoreComponentImport] */

let devConfig: models.IAppDevelopmentConfig = {
	applicationClientConfigurationIds: null,
	applicationClientConfigurations: null,
	authentication: null,
	configuration: null,
	styles: null,
	libraryComponentsConfiguration: LIBRARY_COMPONENTS_CONFIGURATION,
};

if (process.env.NODE_ENV !== 'production') {
	devConfig = {
		...devConfig,
		applicationClientConfigurationIds: async () => {
			const { APPLICATION_CLIENT_CONFIGURATION_IDS } = await import(
				'../../../configuration/development.applicationClientConfigurationIds.js'
			);
			return APPLICATION_CLIENT_CONFIGURATION_IDS;
		},
		applicationClientConfigurations: async () => {
			const { APPLICATION_CLIENT_CONFIGURATIONS } = await import(
				'../../../configuration/development.applicationClientConfigurations.js'
			);
			return APPLICATION_CLIENT_CONFIGURATIONS;
		},
		authentication: async () => {
			const { AUTHENTICATION } = await import('../../../configuration/development.authentication.js');
			return AUTHENTICATION;
		},
		configuration: async () => {
			const { CONFIGURATION } = await import('../../../configuration/development.config.js');
			return CONFIGURATION;
		},
		styles: async () => {
			const { STYLES } = await import('../../../configuration/development.styles.js');
			return STYLES;
		},
	};
}

type IGrid = components.base.Grid.models.IGrid;
type TranslationStore = stores.TranslationStore<IConfiguration, IComponentStyles>;
type QueryStore = stores.QueryStore<IConfiguration, IComponentStyles>;
import { IManifest } from '@kurtosys/types/appsManager/index.js';

const AppStoreBase = stores.base.AppStoreBase<IConfiguration, IComponentStyles>;
const Checkbox = components.base.Checkbox.Checkbox;
const { jsonTryParse, isNullOrEmpty, isNullOrUndefined, sortByDate, hasDateExpired } = common.commonUtils;

export class AppStore extends AppStoreBase {
	url: URL;
	@observable userCountryCode: string | undefined;
	@observable showRedirectMessage = false;

	@observable.ref
	appRedirectHelper: helpers.RedirectHelper | undefined;

	@observable.ref _ctaValidationText: string | undefined;
	@observable.ref _ctaAriaLabelText: string | undefined;

	constructor(
		element: HTMLElement,
		url: string,
		storeContext: TStoreContext,
		manifest: IManifest,
		mockData?: MockData,
	) {
		super(element, url, storeContext, manifest, Feature, devConfig, mockData);
		this.url = new URL(url);
		makeObservable(this);
	}

	@computed
	get translationStore(): TranslationStore {
		return this.storeContext.get<TranslationStore>('translationStore');
	}

	@computed
	get callToActionStore(): CallToActionStore {
		return this.storeContext.get<CallToActionStore>('callToActionStore');
	}

	@computed
	get disclaimerStore(): DisclaimerStore {
		return this.storeContext.get<DisclaimerStore>('disclaimerStore');
	}

	@computed
	get selectionWizardStore(): SelectionWizardStore {
		return this.storeContext.get<SelectionWizardStore>('selectionWizardStore');
	}

	@computed
	get acceptanceDisclaimerStore(): AcceptanceDisclaimerStore {
		return this.storeContext.get<AcceptanceDisclaimerStore>('acceptanceDisclaimerStore');
	}

	@computed
	get selectionStore(): SelectionStore {
		return this.storeContext.get<SelectionStore>('selectionStore');
	}

	@computed
	get acceptanceStore(): AcceptanceStore {
		return this.storeContext.get<AcceptanceStore>('acceptanceStore');
	}

	@computed
	get redirectHelper(): helpers.RedirectHelper {
		if (!this.appRedirectHelper) {
			const translationHelper = this.translationStore && this.translationStore.translationHelper;
			this.appRedirectHelper = new helpers.RedirectHelper(
				{ transformClass: query.transform.Transform, queryClass: query.Query },
				window.location.toString(),
				undefined,
				{
					translationHelper,
					shouldCompareUserURL: true,
				},
			);
		}
		return this.appRedirectHelper;
	}

	async customInitializeBefore(): Promise<void> {
		await this.selectionStore.initialize();
		await this.disclaimerStore.initialize();
	}

	async customInitializeAfter() {
		/*
			The window.__ksysUserCountry__ is updated by the KsysRequest class
			when the fetchUserCountry flag has been provided,
			the flag was supplied to GetApplicationAppConfig in the KurtosysApiStore.
		*/
		this.userCountryCode = (window as any)[common.constants.GLOBAL_USER_COUNTRY_KEY];

		// Initial API call is handled by disclaimerStore
		await this.disclaimerStore.loadDisclaimers();
		await this.acceptanceDisclaimerStore.loadDisclaimers();
		await this.selectionWizardStore.initialize();
		let isRedirecting = false;
		if (this.isAttestationActive && this.redirectByPreviousAttestation) {
			isRedirecting = this.redirectToPreviousAttestation();
		}
		this.checkAutoAttestation();
		this.setSelections();
		this.setIsInitialized(isRedirecting);
	}

	@action
	setIsInitialized(isRedirecting) {
		this.isInitialized = !isRedirecting;
	}

	@computed
	get countryCode(): string | undefined {
		if (this.userCountryCode) {
			return getAlpha3CountryCode(this.userCountryCode);
		}
	}

	@computed
	get prelaunchModal() {
		if (this.appParamsHelper.values && this.isAttestationActive) {
			// Check that the "mustPreload" input is set
			const { inputs } = this.appParamsHelper.values;
			if (!inputs || inputs.mustpreload !== 'true') {
				return false;
			}

			// Check that the auto attestation is not enabled
			const rootConfiguration = this.configuration;
			if (rootConfiguration) {
				const { autoAttest } = rootConfiguration;
				if (autoAttest && this.url.searchParams.has(autoAttest.parameter)) {
					return false;
				}
			}

			// Check that the user has not already attested
			const previousAttestations = this.getStoredAttestations();
			const hasAttested = previousAttestations.some((previous) =>
				this.previousAttestationMatchesInputs(previous),
			);
			if (hasAttested) {
				return false;
			}

			return true;
		}
		return false;
	}

	getStoredAttestations() {
		return [...this.getStoredAttestationsForType('SESSION'), ...this.getStoredAttestationsForType('LOCAL')];
	}

	getStoredAttestationsForType(storageType: TStorageType): IAttestation[] {
		const options = this.getStorageOptions(storageType);
		let attestationString =
			options && options.target && options.target.storage && options.target.storage.getItem(options.target.key);
		if (attestationString) {
			if (isObfuscated(attestationString)) {
				attestationString = deObfuscate(attestationString);
			}
			const attestationConversion = jsonTryParse(attestationString);
			if (attestationConversion.isValid && attestationConversion.value) {
				return Array.isArray(attestationConversion.value)
					? attestationConversion.value
					: [attestationConversion.value];
			}
		}
		return [];
	}

	previousAttestationMatchesInputs(previousAttestation: IAttestation) {
		if (this.appParamsHelper.values) {
			const defaultInputs: any = {};
			const { inputs = defaultInputs } = this.appParamsHelper.values;
			const { values } = previousAttestation;
			const keys = Object.keys(values);
			return !keys.some((key) => !isNullOrEmpty(inputs[key]) && values[key] !== inputs[key]);
		}
	}

	@computed
	get isAttestationBootstrapped() {
		if (
			!this.isInitialized ||
			!this.isBootstrapped ||
			!this.configuration ||
			!this.styles ||
			!this.components ||
			!this.grid
		) {
			return false;
		}

		return true;
	}

	@computed
	get defaultAttestationVersion(): string {
		return `v${this.manifest.version}`;
	}

	@computed
	get attestationVersion(): string {
		return (this.configuration && this.configuration.version) || this.defaultAttestationVersion;
	}

	@computed
	get hasData(): boolean {
		// TODO: Each Application should put custom show logic here: "return this.storeContext[component store].hasData;"
		return true;
	}

	@action
	contextsDidUpdateAfter = async () => {
		// TODO: Each Application should put custom logic here to handle changes to the data context
	};

	@computed
	get components(): IAppComponents {
		return {
			acceptance: {
				key: 'acceptance',
				component: Acceptance,
			},
			callToAction: {
				key: 'callToAction',
				component: CallToAction,
			},
			disclaimer: {
				key: 'disclaimer',
				component: Disclaimer,
			},
			header: {
				key: 'header',
				component: Header,
			},
			selection: {
				key: 'selection',
				component: Selection,
			},
			checkbox: {
				key: 'checkbox',
				component: Checkbox,
			},
			selectionWizard: {
				key: 'selectionWizard',
				component: SelectionWizard,
			},
			footnote: {
				key: 'footnote',
				component: Footnote,
			},
			links: {
				key: 'links',
				component: Links,
			},
			/* [Component: appStoreComponent] */
		};
	}

	@computed
	get hasSelectionWizard(): boolean {
		return this.componentConfiguration && !isNullOrUndefined(this.componentConfiguration.selectionWizard);
	}

	@computed
	get grid(): IGrid | undefined {
		if (this.appComponentConfiguration && this.appComponentConfiguration.grid) {
			return this.appComponentConfiguration.grid;
		}
	}

	@computed
	get hasSelectionsAndDisclaimers(): boolean {
		return (
			this.storeContext &&
			this.selectionStore &&
			this.selectionStore.hasAllSelections &&
			this.disclaimerStore &&
			this.disclaimerStore.hasDisclaimers
		);
	}

	@action setAttestationAndRedirect = () => {
		const { configuration: callToActionConfig } = this.callToActionStore;
		const redirectTimeout = callToActionConfig?.redirectTimeout || 350;

		this.setCtaValidationText();

		if (this.setAttestation()) {
			setTimeout(() => {
				this.redirect();
			}, redirectTimeout);
		}
	};

	@action rejectAndRedirect = () => {
		this.redirect(true);
	};

	isCmsEditMode = (): boolean => {
		const rootConfiguration = this.configuration;
		if (rootConfiguration) {
			const { cmsEditMode: { selector = 'body.elementor-editor-active' } = {} } = rootConfiguration;
			if (selector) {
				return window.parent.document.querySelectorAll(selector).length > 0;
			}
			return false;
		}
		return false;
	};

	@computed
	get isAttestationDisabled() {
		const rootConfiguration = this.configuration;
		if (rootConfiguration) {
			const { disableAttest } = rootConfiguration;

			// We have to hard code the isSnapshot query param as this is required for wordpress snapshots.
			const disableParameters = ['isSnapshot'];
			if (disableAttest && disableAttest.parameter) {
				disableParameters.push(disableAttest.parameter);
			}

			if (Array.isArray(disableParameters) && disableParameters.length > 0) {
				for (let i = 0; i <= disableParameters.length; i++) {
					if (
						this.url.searchParams.has(disableParameters[i]) &&
						this.url.searchParams.get(disableParameters[i]) === 'true'
					) {
						return true;
					}
				}
			}

			if (disableAttest && disableAttest.conditional) {
				const conditionalHelper = new helpers.ConditionalHelper(
					{ queryClass: query.Query },
					disableAttest.conditional,
				);
				const conditionMatch = conditionalHelper.matchesWithOptions({
					executionOptions: this.queryStore.executionOptions,
				});
				if (conditionMatch) {
					return true;
				}
			}
		}
		return false;
	}

	checkAutoAttestation = () => {
		const rootConfiguration = this.configuration;
		if (rootConfiguration) {
			const { autoAttest } = rootConfiguration;
			if (autoAttest && this.url.searchParams.has(autoAttest.parameter)) {
				const { performRedirect = false } = autoAttest;
				this.setAttestationFromQueryString(autoAttest.parameter, performRedirect);
			}
		}
	};

	@computed
	get attestationValidation(): IAttestationValidation | undefined {
		return this.configuration && this.configuration.attestationValidation;
	}

	getPreviousAttestation = (storageType?: TStorageType) => {
		let response: IAttestation[] = [];
		const acceptanceStorage = this.acceptanceStore.rawStorage;
		const acceptanceResponse = this.getAttestationFromStorage(acceptanceStorage, storageType);
		if (acceptanceResponse) {
			response = [...(Array.isArray(acceptanceResponse) ? acceptanceResponse : [acceptanceResponse])];
		}

		const rootConfigurationStorage = this.configuration && this.configuration.storage;
		const rootConfigurationResponse = this.getAttestationFromStorage(rootConfigurationStorage, storageType);
		if (rootConfigurationResponse) {
			response = [
				...response,
				...(Array.isArray(rootConfigurationResponse) ? rootConfigurationResponse : [rootConfigurationResponse]),
			];
		}

		if (isNullOrEmpty(response)) {
			return undefined;
		}
		return (response = response.filter(
			(attestation) => (attestation.version || this.defaultAttestationVersion) === this.attestationVersion,
		));
	};

	getAttestationFromStorage(
		storage?: IConfigurationStorage,
		targetStorageType?: TStorageType,
	): IAttestation | IAttestation[] | undefined {
		if (storage) {
			const { type } = storage;
			if (type === (targetStorageType || type)) {
				switch (type) {
					case 'SESSION':
						return this.getAttestationSessionStorage(storage);
					case 'LOCAL':
					default:
						return this.getAttestationLocalStorage(storage);
				}
			}
		}
	}

	setAttestation = () => {
		let isSuccess = false;
		const values = this.selectionStore.rawValues;
		const rootConfiguration = this.configuration;
		if (rootConfiguration) {
			const { accepted, storage: acceptanceStorage } = this.acceptanceStore;
			const storage = (accepted && acceptanceStorage) || rootConfiguration.storage;

			if (storage) {
				const { type } = storage;
				switch (type) {
					case 'SESSION':
						isSuccess = this.setAttestationSessionStorage(values);
						break;
					case 'LOCAL':
					default:
						isSuccess = this.setAttestationLocalStorage(values);
						break;
				}
			}
		}
		return isSuccess;
	};
	getStorageOptions = (storageType: TStorageType, storageKey?: string) => {
		let options: IStorageOptions | undefined;
		const localStorageOption: IStorageOption = {
			key: storageKey || this.localStorageKey,
			storage: localStorage,
		};
		const sessionStorageOption: IStorageOption = {
			key: storageKey || this.sessionStorageKey,
			storage: sessionStorage,
		};
		switch (storageType) {
			case 'LOCAL':
				options = {
					target: localStorageOption,
					clear: sessionStorageOption,
				};
				break;
			case 'SESSION':
				options = {
					target: sessionStorageOption,
					clear: localStorageOption,
				};
				break;
		}
		return options;
	};
	getStorageValues = (storage: IConfigurationStorage, storageType: TStorageType) => {
		const options = this.getStorageOptions(storageType);
		const { expiry } = storage;
		let attestationString =
			options && options.target && options.target.storage && options.target.storage.getItem(options.target.key);
		if (attestationString) {
			if (isObfuscated(attestationString)) {
				attestationString = deObfuscate(attestationString);
			}

			const attestationConversion = jsonTryParse(attestationString);
			if (attestationConversion.isValid) {
				if (attestationConversion.value) {
					let attestations = attestationConversion.value as IAttestation | IAttestation[];
					if (!Array.isArray(attestations)) {
						attestations = [attestations];
					}
					if (Array.isArray(attestations)) {
						const validAttestations = sortByDate(
							attestations.filter((attestation) => {
								const { time } = attestation;
								// Check the expiration
								const timeAsDate = new Date(Date.parse(time));
								const hasExpired = !expiry ? false : hasDateExpired(timeAsDate, expiry);
								return !hasExpired;
							}),
							(value) => value.time,
							'DESC',
						);
						if (validAttestations.length > 0) {
							if (validAttestations.length !== attestations.length) {
								this.resetStorageValues(storageType, validAttestations);
							}
							return validAttestations;
						}
					}
				}
				options &&
					options.target &&
					options.target.storage &&
					options.target.storage.removeItem(options.target.key);
			}
		}
		return;
	};
	setStorageValues = (storageType: TStorageType, values: any) => {
		const options = this.getStorageOptions(storageType);
		if (options) {
			const attestation: IAttestation = {
				values,
				time: new Date().toUTCString(),
				version: this.attestationVersion,
			};
			if (this.configuration && this.configuration.allowMultipleAttestations) {
				const newCollection = [attestation];
				const previousAttestation = this.getPreviousAttestation(storageType);
				if (previousAttestation) {
					if (Array.isArray(previousAttestation)) {
						newCollection.push(...previousAttestation);
					} else {
						newCollection.push(previousAttestation);
					}
				}

				// Remove older duplicates
				const cleanCollection: IAttestation[] = [];
				const { fields, getSelectedFieldOption } = this.selectionStore;
				if (fields) {
					const mappingObject: any = {};
					for (const attestation of newCollection) {
						const { values } = attestation;
						const keyPieces = fields.map((field) => {
							const { key } = field;
							const fieldResponse = getSelectedFieldOption(field, undefined, values);
							return `${key}:${fieldResponse.value}`;
						});
						const key = keyPieces.join(';');
						if (!mappingObject[key]) {
							mappingObject[key] = attestation;
							cleanCollection.push(attestation);
						}
					}
				}
				options.target.storage.setItem(options.target.key, this.getValueForStorage(cleanCollection));
			} else {
				options.clear.storage.removeItem(options.clear.key);
				options.target.storage.setItem(options.target.key, this.getValueForStorage(attestation));
			}
		}
		return true;
	};
	resetStorageValues = (storageType: TStorageType, values: any) => {
		const options = this.getStorageOptions(storageType);
		if (options && options.target && options.target.storage) {
			options.target.storage.setItem(options.target.key, this.getValueForStorage(values));
		}
	};
	localStorageKey = 'ksys-attestation';
	setAttestationLocalStorage = (values: any) => {
		return this.setStorageValues('LOCAL', values);
	};

	getAttestationLocalStorage = (storage: IConfigurationStorage): IAttestation | IAttestation[] | undefined => {
		return this.getStorageValues(storage, 'LOCAL');
	};

	sessionStorageKey = 'ksys-attestation';
	setAttestationSessionStorage = (values: any) => {
		return this.setStorageValues('SESSION', values);
	};

	getAttestationSessionStorage = (storage: IConfigurationStorage): IAttestation | IAttestation[] | undefined => {
		return this.getStorageValues(storage, 'SESSION');
	};

	setAttestationFromQueryString = (autoAttestParameter: string, performRedirect: boolean) => {
		const selectionFieldKeys = this.selectionStore.fields.map((field) => field.key);
		this.url.searchParams.forEach((value, key) => {
			const selectionField = this.selectionStore.fields.find((field) => field.key === key);
			if (selectionField) {
				this.selectionStore.setValue(selectionField, value);
				// If using the wizard, refresh the active fields after setting each value as the conditional matches may have changed
				if (this.selectionStore.mode === 'wizard') {
					this.selectionWizardStore.loadActiveFields();
				}
			}
		});

		this.cleanQueryStringParameters([autoAttestParameter, ...selectionFieldKeys]);
		if (performRedirect) {
			this.setAttestationAndRedirect();
		} else {
			this.setAttestation();
		}
	};

	setSelections = () => {
		const { selection } = this.componentConfiguration;
		if (selection) {
			const { initialValues } = selection;
			initialValues === 'EMBEDDED_INPUT'
				? this.setSelectionValuesFromInputs()
				: this.setSelectionValuesFromStorage();
			this.setSelectionValuesByGeolocation();
		}
	};

	setSelectionValuesFromInputs = () => {
		if (!this.appParamsHelper.values) {
			return false;
		}

		const { inputs } = this.appParamsHelper.values;

		if (!inputs) {
			return false;
		}

		this.setSelectionValues(inputs);
	};

	setSelectionValuesFromStorage = () => {
		let previousAttestation = this.getPreviousAttestation('SESSION');
		if (!previousAttestation) {
			previousAttestation = this.getPreviousAttestation();
		}
		if (!isNullOrEmpty(previousAttestation)) {
			this.setSelectionValues(previousAttestation[0].values);
		}
	};

	setSelectionValues = (values: { [key: string]: any }) => {
		const keys = Object.keys(values);
		const selectionFields = this.selectionStore.fields.filter((field) => keys.includes(field.key));
		selectionFields.forEach((selectionField) => {
			this.selectionStore.setValue(selectionField, values[selectionField.key]);
			// If using the wizard, refresh the active fields after setting each value as the conditional matches may have changed
			if (this.selectionStore.mode === 'wizard') {
				this.selectionWizardStore.loadActiveFields();
			}
		});
	};

	cleanQueryStringParameters = (parameters: string[] = []) => {
		parameters.forEach((param) => {
			this.url.searchParams.delete(param);
		});

		window.history.replaceState(null, '', this.url.toString());
	};

	redirect = (isRejection = false, values?: ISelectionValues) => {
		const rootConfiguration = this.configuration;
		if (rootConfiguration) {
			const { redirect, rejectRedirect } = rootConfiguration;
			const redirectToUse = isRejection ? rejectRedirect : redirect;

			if (redirectToUse) {
				const selections = getSelectionsForRedirect(
					this.appParamsHelper,
					this.selectionStore.rawValues,
					values,
				);
				this.redirectHelper.go(redirectToUse, selections);
				this.updated = Date.now();
			}
		}
	};

	@computed
	get isAttestationActive(): boolean {
		if (!this.appParamsHelper.values) {
			return false;
		}

		const { inputs } = this.appParamsHelper.values;

		if (!inputs) {
			return false;
		}

		return inputs['mode'] === 'active';
	}

	@observable.ref
	updated = Date.now();

	@computed
	get isAttestationRequired(): boolean {
		let hasValidAttestation = false;

		if (this.updated) {
			if (!this.appParamsHelper.values || this.isCmsEditMode()) {
				return false;
			}

			const { inputs } = this.appParamsHelper.values;
			const previousAttestation = this.getPreviousAttestation();

			if (!previousAttestation || !inputs) {
				return true;
			}
			if (Array.isArray(previousAttestation) && previousAttestation.length === 0) {
				return true;
			}

			const attestations = !Array.isArray(previousAttestation) ? [previousAttestation] : previousAttestation;

			const fields = this.selectionStore.fields;

			// We can exclude certain fields from the check, an example would be when we need the language
			// but it does not change the users attestation
			const attestationFields = (fields || []).filter((field) => !field.excludeFromActiveAttestationCheck);

			for (const attestation of attestations) {
				const { values } = attestation;

				if (
					this.attestationValidation &&
					this.attestationValidation.mode === 'conditionals' &&
					this.attestationValidation.conditional
				) {
					// Using validation config, validate against previous attestations
					const conditionalHelper = new helpers.ConditionalHelper(
						{ queryClass: query.Query },
						this.attestationValidation.conditional,
					);
					hasValidAttestation = conditionalHelper.matchesWithOptions({
						instance: values,
						executionOptions: this.queryStore.executionOptions,
					});
				} else {
					// using field config, validate previous attestations against inputs provided
					const nonMatchingAttestationValue = attestationFields.find((field) => {
						const selectedValue = values[field.key];
						const inputValue = inputs[field.key];

						if (selectedValue === inputValue) {
							return false;
						}
						// Options have an optional category, if the category of the attestation option matches the category
						// of the input option then we don't need to attest again.
						// FCE-1263 - BNY Attestation | Allowing for Multiple Investor Types in dropdown to behave as one Investor Type
						// https://kurtosys-prod-eng.atlassian.net/browse/FCE-1263
						const selectedOption = (field.options || []).find((option) => option.value === selectedValue);
						const inputOption = (field.options || []).find((option) => option.value === inputValue);

						const selectedCategory = selectedOption && selectedOption.category;
						const inputCategory = inputOption && inputOption.category;

						// If either category is undefined or null then we need to attest again
						return !selectedCategory || !inputCategory || selectedCategory !== inputCategory;
					});

					if (!nonMatchingAttestationValue) {
						hasValidAttestation = true;
					}
				}

				if (hasValidAttestation) {
					break;
				}
			}
		}

		// If any value is false, an attestation is required
		return !hasValidAttestation;
	}

	@computed
	get selectedAttestationContext(): IAttestationContext {
		const values = getSelectionsForRedirect(this.appParamsHelper, this.selectionStore.rawValues);
		return {
			event: 'Attestation_Accept_and_Proceed',
			region: values.region,
			country: values.country,
			investorType: values.investorType,
			language: values.language,
			acceptedTandC: this.acceptanceStore.acceptedKeys,
		};
	}

	getValueForStorage = (value: IAttestation | IAttestation[]): string => {
		let obfuscated = false;
		const rootConfiguration = this.configuration;

		if (rootConfiguration) {
			const { storage } = rootConfiguration;
			if (storage) {
				({ obfuscated = true } = storage);
			}
		}

		if (obfuscated) {
			return obfuscate(JSON.stringify(value));
		}

		return JSON.stringify(value);
	};

	setSelectionValuesByGeolocation() {
		const geolocationEnabled = this.selectionStore && this.selectionStore.geolocationConfig.enabled;
		const geolocationType = this.selectionStore && this.selectionStore.geolocationConfig.type;
		if (this.isDebug) {
			this.debugLog('geolocation enabled?', geolocationEnabled);
			this.debugLog('geolocation type', geolocationType);
		}
		if (geolocationEnabled) {
			switch (geolocationType) {
				case 'cloudflare':
					if (this.isDebug) {
						this.debugLog('alpha2 country code (cloudflare):', this.userCountryCode);
						this.debugLog('alpha3 country code (cloudflare):', this.countryCode);
					}
					this.updateSelectionFromCountryCode(this.countryCode);
					break;
				case 'co-ordinates':
				default:
					geolocate((code: string) => {
						if (this.isDebug) {
							this.debugLog('alpha3 country code (co-ordinates):', code);
						}
						this.updateSelectionFromCountryCode(code);
					}, this.selectionStore.geolocationConfig.coordinates);
					break;
			}
		}
	}

	updateSelectionFromCountryCode(countryCode?: string) {
		const geolocationEnabled = this.selectionStore && this.selectionStore.geolocationConfig.enabled;

		if (geolocationEnabled && countryCode) {
			const values = this.getGeolocationValues(countryCode);
			if (isNullOrEmpty(values.country)) {
				return;
			}
			this.setSelectionValues(values);

			if (this.selectionWizardStore && !isNullOrEmpty(this.selectionWizardStore.fields)) {
				for (const field of this.selectionWizardStore.fields) {
					if (values[field.key] === undefined) {
						this.selectionWizardStore.handleNavigatorItemSelect(field);
						break;
					}
				}
			}
		}
	}

	getGeolocationValues(code: string) {
		const { geolocationConfig: geolocation } = this.selectionStore;
		const values: { [key: string]: string } = { country: this.getCountryValue(code) };
		if (geolocation && !isNullOrEmpty(geolocation.cascadingFieldValues)) {
			for (const field of geolocation.cascadingFieldValues) {
				const { key, value, conditional } = field;
				if (conditional) {
					const conditionalHelper = new helpers.ConditionalHelper({ queryClass: query.Query }, conditional);
					if (
						conditionalHelper.matchesWithOptions({
							instance: values,
							executionOptions: this.queryStore.executionOptions,
						})
					) {
						values[key] = value;
					}
				} else {
					values[key] = value;
				}
			}
		}
		return values;
	}

	getCountryValue(code: string) {
		const fields = this.selectionStore.fields || this.selectionWizardStore.fields;

		const options = fields
			.filter((field) => field.key === 'country' && field.options)
			.map((field) => field.options || [])
			.reduce((total, options) => {
				return [...total, ...options];
			});
		for (const option of options) {
			if (option.countryCode === code || option.value === code) {
				return option.value;
			}
		}
		for (const option of options) {
			if (option.countryCode === 'default') {
				return option.value;
			}
		}
		return '';
	}

	// Used to track if attestation has redirected previously for the session when using redirectByPreviousAttestation === once
	redirectStatusKey = 'ksys-attestation-previously-redirected';

	@computed
	get redirectByPreviousAttestation(): Boolean {
		const value = this.getInput('redirectByPreviousAttestation') as 'true' | 'false' | 'once' | undefined;
		let allowRedirect = false;
		if (value === 'once') {
			allowRedirect = !this.hasPreviouslyRedirected;
		} else {
			allowRedirect = value === 'true';
		}
		return allowRedirect;
	}

	@computed
	get hasPreviouslyRedirected() {
		const options = this.getStorageOptions('SESSION', this.redirectStatusKey);
		if (options) {
			return options.target.storage.getItem(options.target.key) === 'true';
		}
	}

	redirectToPreviousAttestation() {
		const previousAttestation: IAttestation[] | undefined = this.getPreviousAttestation();
		if (previousAttestation && previousAttestation.length > 0) {
			const options = this.getStorageOptions('SESSION', this.redirectStatusKey);
			options.target.storage.setItem(options.target.key, 'true');
			// getStorageValues() sorts by time DESC. Take latest attestation
			const attestationValues: ISelectionValues = previousAttestation[0].values;
			this.showRedirectMessage = !isNullOrEmpty(this.redirectMessage);
			this.redirect(false, attestationValues);
			// Return true to prevent the rest of the attestation from loading
			return true;
		}
		return false;
	}

	debugLog(message?: any, ...optionalParams: any[]) {
		if (this.isDebug) {
			console.debug(message, ...optionalParams);
		}
	}

	@computed
	get redirectMessage(): string | undefined {
		const message = this.configuration && this.configuration.redirectMessage;
		if (message) {
			const translate = this.getTranslateFunction();
			const translatedMessage = translate(message);
			return translatedMessage;
		}
	}

	getInput(inputKey: keyof IInputs) {
		return this.appParamsHelper.inputs && this.appParamsHelper.inputs[inputKey];
	}

	// CTA A11y
	// We add them here because the App.tsx needs access to these values
	// We initially had them in the callToActionStore, but things broke horribly.
	@computed
	get ctaValidationText(): string | undefined {
		return this._ctaValidationText;
	}

	@action
	setCtaValidationText(): void {
		const { configuration: callToActionConfig } = this.callToActionStore;
		const defaultText = 'You will now be directed based on your selected inputs';

		this._ctaValidationText = callToActionConfig?.validationText || defaultText;
	}

	@computed
	get ctaAriaLabelText(): string | undefined {
		return this._ctaAriaLabelText;
	}

	@action
	setCtaAriaLabelText(): void {
		const { configuration: callToActionConfig } = this.callToActionStore;
		const { language, country, investorType } = this.selectionStore.rawValues;

		const defaultText = 'Redirect to {language}-{country} site for {investorType} investors';
		const text = callToActionConfig?.ariaLabelText || defaultText;

		this._ctaAriaLabelText = this.translationStore.translate(text, {
			language,
			country: common.commonUtils.upperCase(country),
			investorType,
		});
	}
}
