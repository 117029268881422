export const toastStyle = {
    children: {
        base: {
            border: {
                style: 'solid',
                width: '1px',
                color: 'transparent',
                radius: '5px',
            },
            padding: {
                _value: '10px',
            },
            position: 'relative',
        },
        close: {
            position: 'absolute',
            right: '0',
            top: '0',
        },
        error: {
            background: {
                color: '#f8d7da',
            },
            border: {
                color: '#f5c6cb',
            },
            color: '#721c24',
        },
        info: {
            background: {
                color: '#d1ecf1',
            },
            border: {
                color: '#bee5eb',
            },
            color: '#0c5460',
        },
        success: {
            background: {
                color: '#d4edda',
            },
            border: {
                color: '#c3e6cb',
            },
            color: '#155724',
        },
        warning: {
            background: {
                color: '#fff3cd',
            },
            border: {
                color: '#ffeeba',
            },
            color: '#856404',
        },
    },
};
