import React from 'react';
import { observer } from 'mobx-react';
import { ComponentBase } from '../../../shared/ComponentBase.js';
import { InjectedStyledComponent } from '../../../shared/InjectedStyledComponent.js';
import { CLICK_LAYER_CATEGORY_KEY, CLICK_LAYER_COMPONENT_KEY } from './ClickLayer.meta.js';
const rawCssPrefix = `
position: fixed;
top: 0;
bottom: 0;
left: 0;
right: 0;
`;
export class ClickLayer extends ComponentBase {
    constructor(props) {
        super(props);
        this.categoryKey = CLICK_LAYER_CATEGORY_KEY;
        this.componentKey = CLICK_LAYER_COMPONENT_KEY;
    }
    render() {
        const { className, onClick } = this.props;
        return (React.createElement("div", { className: className, onClick: (e) => {
                e.stopPropagation();
                if (onClick) {
                    onClick(e);
                }
            } }));
    }
}
export default InjectedStyledComponent(observer(ClickLayer), CLICK_LAYER_CATEGORY_KEY, CLICK_LAYER_COMPONENT_KEY, undefined, rawCssPrefix);
