export const dateDropdownStyle = {
    children: {
        wrapper: {
            width: '250px',
            font: {
                size: '14px',
            },
            lineHeight: '18px',
        },
        header: {
            border: {
                _value: '1px solid #E0E3ED',
            },
            position: 'relative',
            display: 'inline-block',
            width: '250px',
            padding: {
                _value: '10px',
            },
        },
        headerError: {
            border: {
                _value: '1px solid #FF5723',
            },
        },
        headerDisabled: {
            cursor: 'not-allowed',
            color: '#cccccc',
        },
        headerTitle: {
            float: 'left',
        },
        headerIcon: {
            float: 'left',
            margin: {
                right: '10px',
            },
        },
        placeholder: {
            color: '#B0B8CB',
        },
        listItems: {
            position: 'absolute',
            background: {
                color: '#FFF',
            },
            zIndex: '1000',
            listStyle: {
                type: 'none',
            },
            display: 'inline-block',
            width: '250px',
            margin: {
                _value: '0',
            },
            padding: {
                _value: '0',
            },
            border: {
                _value: '1px solid #E0E3ED',
            },
        },
        listItem: {
            display: 'flex',
            flex: {
                direction: 'row',
            },
            justifyContent: 'space-between',
            padding: {
                _value: '5px 5px 0px 5px',
            },
            selectors: {
                ':hover': {
                    background: {
                        color: '#E0E3ED',
                    },
                },
            },
            text: {
                align: 'right',
            },
        },
        listItemLabel: {
            height: '25px',
        },
        clickLayer: {
            zIndex: '999',
            position: 'fixed',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
            background: {
                color: 'transparent',
            },
        },
    },
};
