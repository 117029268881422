export const calendarInputStyle = {
    children: {
        wrapper: {
            font: {
                size: '12px',
            },
            color: 'rgb(58, 64, 81)',
        },
        header: {},
        headerNav: {
            display: 'flex',
            flex: {
                direction: 'row',
            },
            font: {
                size: '14px',
                weight: '500',
            },
            lineHeight: '30px',
        },
        monthButtonNext: {
            border: {
                _value: 'none',
            },
            width: '30px',
            background: {
                _value: 'none',
            },
            outline: {
                _value: 'none',
            },
        },
        monthButtonPrevious: {
            border: {
                _value: 'none',
            },
            width: '30px',
            background: {
                _value: 'none',
            },
            outline: {
                _value: 'none',
            },
        },
        month: {
            padding: {
                right: '3px',
            },
            flex: {
                _value: '1',
            },
            text: {
                align: 'right',
            },
        },
        year: {
            flex: {
                _value: '1',
            },
        },
        yearInput: {
            border: {
                _value: 'none',
            },
            color: 'rgb(58,64,81)',
            width: '80px',
        },
        body: {},
        calendar: {
            box: {
                shadow: '0px 0px 1px rgba(0, 0, 0, 0.5);',
            },
            background: {
                color: 'white',
            },
            width: 'fit-content',
            position: 'relative',
            zIndex: '1000',
        },
        row: {},
        dayOfWeek: {
            lineHeight: '34px',
            width: '34px',
            height: '34px',
            display: 'inline-block',
            opacity: '0.5',
            text: {
                align: 'center',
            },
        },
        datePickerDay: {
            lineHeight: '34px',
            width: '34px',
            height: '34px',
            display: 'inline-block',
            text: {
                align: 'center',
            },
            cursor: 'pointer',
            selectors: {
                ':hover': {
                    background: {
                        color: 'rgb(34, 114, 243)',
                    },
                    opacity: '0.5',
                    color: 'white',
                    border: {
                        radius: '50%',
                    },
                },
            },
        },
        datePickerDayPlaceholder: {
            selectors: {
                ':hover': {
                    background: {
                        color: 'unset',
                    },
                },
            },
            cursor: 'default',
        },
        datePickerDaySelected: {
            background: {
                color: 'rgb(34, 114, 243)',
            },
            color: 'white',
            border: {
                radius: '50%',
            },
            selectors: {
                ':hover': {
                    opacity: '1',
                },
            },
        },
        datePickerDayDisabled: {
            cursor: 'not-allowed',
            color: '#cccccc',
            selectors: {
                ':hover': {
                    color: 'unset',
                    background: {
                        color: 'unset',
                    },
                },
            },
        },
        datePickerDayToday: {
            color: 'rgb(26, 128, 255)',
        },
        clickLayer: {
            zIndex: '999',
            position: 'fixed',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
            background: {
                color: 'transparent',
            },
        },
    },
};
